import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import "./index.css";
import "../node_modules/react-grid-layout/css/styles.css";
import Main from "./Main";
import LoginPage from "./views/LoginPage/LoginPage.js";
import CitizenDashboard from "./views/UserDashboard/CitizenDashboard";
import Dashboard from "./views/UserDashboard/Dashboard/Dashboard-2";
import AdminDashboard from "./views/UserDashboard/Dashboard/Dashboard-2-admin";
import CourtStatusList from "./views/UserDashboard/Dashboard/CourtStatusList";
import Courtstatus from "../src/views/CourtFieldStatus";
import FieldMap from "../src/views/FieldStatus";
import { GATracingCode } from "./config";
import GolfDemo from "./views/UserDashboard/Dashboard/GolfDemo.js";
import GolfLoginPage from "./views/LoginPage/GolfLoginPage.js";

const theme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#0076bb",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#009444",
    },
    error: {
      main: "#ff0000",
    },
    success: {
      main: "#4caf50",
    },
  },
});
function App(props) {
  React.useEffect(() => {
    ReactGA.initialize(GATracingCode);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            {/* <Route exact path="/" component={CitizenDashboard} /> */}
            <Route exact path="/csp_status" component={CourtStatusList} />

            <Route path="/login" component={LoginPage} />
            <Route path="/courts" component={Courtstatus} />
            <Route path="/fields" component={FieldMap} />
            <Route path="/ctcc" component={GolfDemo} />
            <Route path="/golf-login" component={GolfLoginPage} />
            <Route path="/:a([A-Za-z0-9]+)">
              <Main props={props} />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
