import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { GenerateEncryptedToken } from "../Common.js";
import { login_ } from "../../reducers/login.js";
import MaskedInput from "react-maskedinput";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import calendar from "../../assets/img/calendar-icon.png";
import smartParkService from "../../axios/index.js";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import alllog from "../../assets/img/alllog.png";
import update from "../../assets/img/update.png";
import create from "../../assets/img/create.png";
import enable from "../../assets/img/enable.png";
import disable from "../../assets/img/disable.png";
import deleteicon from "../../assets/img/delete.png";
import plusicontwo from "../../assets/img/whiteplus.png";
import Button from "../../components/CustomButtons/Button.js";
import { MdSettings } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import { IoMdCreate } from "react-icons/io";
import { RiDeleteBinFill } from "react-icons/ri";
import { BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";
import { MdUpdate } from "react-icons/md";
import Slide from "@material-ui/core/Slide";
import Tablepaginate from "./Tablepaginate.js";
import { TiArrowBack } from "react-icons/ti";
import { initialState } from "../../reducers/rules.js";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));
// Multiple select ================
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
//  emaillist section ====================
let cloneRulesInitialState = _.cloneDeep(initialState.data);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});

export default function AuditLogpage() {
    const classes = useStyles();
    const textRef = useRef(null);
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();
    // datainput ------=======================================================
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [color, setColor] = React.useState("#ffffff");
    const [getparks, setparks] = React.useState([]);
    const [startDate, setStartDate] = React.useState(new Date(moment().subtract(7, 'days')));
    const [endDate, setEndDate] = React.useState(new Date(moment()));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showEndDatePicker, setshowEndDatePicker] = useState(false);
    const [getapiRuleID, setapiRuleID] = React.useState("");
    const [getLogTriggeredtype, setLogTriggeredtype] = React.useState("");
    const [getlogemailname, setlogemailname] = React.useState("");
    const [getloguname, setloguname] = React.useState("");
    const [getListdata, setListdata] = React.useState([]);
    const theme = useTheme();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [pending, setPending] = React.useState(true);
    const [getDeleterefresh, setDeleterefresh] = React.useState(false);
    const [rows, setRowsdata] = React.useState([]);
    const [getdname, setdname] = React.useState("");
    const [getRuleName, setRuleName] = React.useState("");
    const [getRuleDescription, setRuleDescription] = React.useState("");
    const [getLogAuditID, setLogAuditID] = React.useState("");
    const [getLogRuleID, setLogRuleID] = React.useState("");
    const [getruleDesText, setruleDesText] = React.useState([]);
    const [getpriorityNumberText, setpriorityNumberText] = React.useState([]);
    const [getenabledisablemenutext, setenabledisablemenutext] = React.useState(
        []
    );
    const [getShouldUpdatePage, setShouldUpdatePage] = React.useState(false);
    const [getTotalrules, setTotalrules] = React.useState("");
    const [getTotalRowselect, setTotalRowselect] = React.useState("");
    const [getcheckrow, setcheckrow] = React.useState(false);
    const [permission, setpermission] = React.useState({});
    const [totalRows, setTotalRows] = React.useState(10);
    const [getShowHeading, setShowHeading] = React.useState(false);
    const [filtershow, setfilterShow] = React.useState(false);
    const [getMobileRuleList, setMobileRuleList] = React.useState([]);
    const [getBulkActions, setBulkActions] = React.useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const handlefilterClose = () => setfilterShow(false);
    const handlefilterShow = () => setfilterShow(true);
    const [refresh, setRefresh] = useState(false);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    var stateDetail;
    if (location?.state !== undefined) {
        try {
            stateDetail = location?.state?.detail;
        } catch (error) {
            console.log("Location state error " + error);
        }
    }
    useEffect(() => {
        console.log(stateDetail);
        if (stateDetail !== "" && stateDetail !== undefined) {
            let url = `api/park/ruleengine/viewrule/${stateDetail}`;
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        if (Object.keys(res.data).length == 0) {
                            setRefresh(!refresh);
                        }
                        if (
                            Object.keys(res.data.data).length == 0 &&
                            Object.keys(res.data.data) == undefined
                        ) {
                            setRefresh(!refresh);
                        }
                        if (res?.data?.status == 200) {
                            // seteditdata(res.data.data);
                            setRuleName(res?.data?.data?.RuleName);
                            setRuleDescription(res?.data?.data?.RuleDescription);
                        }
                    }
                })
                .catch((error) => {
                    console.error("View Rule list error: ", error);
                });
        }
    }, [stateDetail]);

    useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    function handleLogType(e) {
        let logtypevalue = e.target.value;
        setLogTriggeredtype(logtypevalue);
        setShouldUpdatePage(true);
    }

    useEffect(() => {
        funcSearchoptions();
    }, []);

    function funcSearchoptions() {
        try {
            let url = "api/park/ruleengine/searchoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res.data.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    const handleImgClick = () => {
        setShowDatePicker(true);
        setshowEndDatePicker(false);
    };
    const handleDatePickerBlur = () => {
        setShowDatePicker(false);
    };
    const handleEndImgClick = () => {
        setshowEndDatePicker(true);
        setShowDatePicker(false);
    };
    const handleEndDatePickerBlur = () => {
        setshowEndDatePicker(false);
    };

    function funcStartDate(e) {
        console.log(e);
        if (e !== null && e !== undefined) {
            const startDateTime = new Date(e);
            startDateTime.setHours(0, 0, 0);
            setStartDate(startDateTime);
            setShowDatePicker(false);
        } else {
            setStartDate("");
            setShowDatePicker(false);
        }
        setShouldUpdatePage(true);
    }
    function funcEndDate(e) {
        console.log(e);
        if (e !== null && e !== undefined) {
            const endDateTime = new Date(e);
            endDateTime.setHours(23, 59, 59);
            setEndDate(endDateTime);
            setshowEndDatePicker(false);
        } else {
            setEndDate("");
            setshowEndDatePicker(false);
        }
        setShouldUpdatePage(true);
    }
    const handleRuleId = (elem) => {
        setapiRuleID(elem.target.value);
        setShouldUpdatePage(true);
    };
    const HandleUserName = (elem) => {
        setloguname(elem.target.value);
        setShouldUpdatePage(true);
    };
    const HandleEmailName = (elem) => {
        setlogemailname(elem.target.value);
        setShouldUpdatePage(true);
    };

    useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);

    function simpledate(date) {
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    const htmlFormatter = ({ value }) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    );

    useEffect(() => {
        if (getRuleName !== "" && getRuleDescription !== "") {
            refreshData();
        }
    }, [currentPage, itemsPerPage, refresh, getRuleName, getRuleDescription]);

    const refreshData = () => {
        if (getShouldUpdatePage) {
            setCurrentPage(1);
            setItemsPerPage(10);
        }
        const updatedPage = getShouldUpdatePage ? 1 : currentPage; // Update this with the new page number
        const updatedItemsPerPage = getShouldUpdatePage ? 10 : itemsPerPage; // Update this with the new items per page value
        let estTimeZone = "America/New_York"; // Eastern Standard Time (EST)
        // Convert startDate to EST and format
        let estStartTimestamp = "";
        if (startDate !== "" && startDate !== undefined) {
            estStartTimestamp = moment(startDate)
                .tz(estTimeZone)
                .endOf("day")
                .format("L h:mm:ss A");
        } else {
            estStartTimestamp = "";
        }
        // Convert endDate to EST and format
        let estEndTimestamp = "";
        if (endDate !== "" && endDate !== undefined) {
            estEndTimestamp = moment(endDate)
                .tz(estTimeZone)
                .set({ hour: 23, minute: 59, second: 59 })
                .format("L h:mm:ss A");
        } else {
            estEndTimestamp = "";
        }
        // Convert EST formatted timestamps to UTC
        let unixStartTimestamp =
            estStartTimestamp !== ""
                ? moment(estStartTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";
        let unixEndTimestamp =
            estEndTimestamp !== ""
                ? moment(estEndTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";

        try {
            var finaldata = [];
            let url = "api/park/ruleengine/listruleauditlogs";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: updatedPage,
                Limit: updatedItemsPerPage,
                RuleID: stateDetail,
                // RuleName: getRuleName,
                StartDate: unixStartTimestamp,
                EndDate: unixEndTimestamp,
                LogType: getLogTriggeredtype, // Create, Update, Enable, Disable, Delete
                Name: getloguname,
                Email: getlogemailname,
            };
            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    setShouldUpdatePage(false);
                    if (res) {
                        // console.log(res);
                        if (res.data.data?.length < 1) {
                            setPending(false);
                            setLoading(false);
                        }
                        if (res.data.status === "200") {
                            setTotalrules(res.data.metadata.total);
                            setTotalRows(res.data.metadata.pages);
                            setShowHeading(true);
                        } else {
                            refreshData();
                        }
                        if (res.data.data?.length > 0) {
                            setMobileRuleList(res.data.data);
                        }

                        res.data.data?.forEach((data, idx) => {
                            setLogRuleID(data.RuleID);
                            setLogAuditID(data.AuditID);
                            const Logseverity = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize logtableicons">
                                            {data.OperationType == "Update" ? (
                                                <img src={update} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Disable" ? (
                                                <img src={disable} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Create" ? (
                                                <img src={create} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Delete" ? (
                                                <img src={deleteicon} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Enable" ? (
                                                <img src={enable} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            };
                            const logOperationType = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{data.OperationType}</div>
                                    </div>
                                );
                            };
                            const logdate = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {simpledate(data.InsertDate)}
                                        </div>
                                    </div>
                                );
                            };
                            const ruledes = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{getRuleName}</div>
                                        <hr className="my-1 mb-2" />
                                        <div className="commonsize">{getRuleDescription}</div>
                                    </div>
                                );
                            };
                            const rulePriority = () => {
                                return (
                                    <>
                                        {data.AuditID !== "" ? (
                                            <div className="w-100">
                                                <div className="commonsize">
                                                    {data.ModifiedBy.FullName}
                                                </div>
                                                <hr className="my-2" />
                                                <div className="triger commonsize mobilepy">
                                                    {data.ModifiedBy.Email}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-100">
                                                <div className="commonsize">NA</div>
                                                <hr className="my-2" />
                                                <div className="triger commonsize mobilepy">NA</div>
                                            </div>
                                        )}
                                    </>
                                );
                            };
                            const enabledisablesmenu = () => {
                                return (
                                    <div className="menubtn">
                                        <button
                                            className="btn logviewbtn"
                                            onClick={() => viewlogpage(data._id)}
                                        >
                                            <span className="actionbutton">View Log</span>
                                        </button>
                                    </div>
                                );
                            };
                            finaldata.push({
                                Logseverity: Logseverity(),
                                LogOperationType: logOperationType(),
                                RuleDes: ruledes(),
                                Prioritynumber: rulePriority(),
                                logdate: logdate(),
                                enabledisablemenu: enabledisablesmenu(),
                            });
                        });
                        setListdata(finaldata);
                    } else {
                        console.log("Rule log list error response: ");
                    }
                })
                .catch((error) => {
                    console.error("Rule log List error: ", error);
                });
        } catch {
            console.log("Log List error ");
        }
    };

    const columnsdata = [
        {
            field: "Severity",
            name: (
                <div className="w-100">
                    <div className="tableheading">Severity </div>
                </div>
            ),
            minWidth: "5%",
            width: "5%",
            cell: (row) => row.Logseverity,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "OperationType",
            name: (
                <div className="w-100">
                    <div className="tableheading">OperationType </div>
                </div>
            ),
            minWidth: "5%",
            width: "15%",
            cell: (row) => row.LogOperationType,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "RuleNameDes",
            name: (
                <div className="w-100">
                    <div className="tableheading">Rule Name </div>
                    <hr className="my-2" />
                    <div className="tableheading">Rule Description</div>
                </div>
            ),
            minWidth: "20%",
            width: "30%",
            cell: (row) => row.RuleDes,
            editable: true,
            renderCell: htmlFormatter,
        },

        {
            field: "Logdate",
            name: (
                <div className="w-100">
                    <div className="tableheading">Date</div>
                </div>
            ),
            minWidth: "10%",
            width: "15%",
            cell: (row) => row.logdate,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Prioritynumber",
            name: (
                <div className="w-100">
                    <div className="tableheading">Modified By</div>
                </div>
            ),
            minWidth: "15%",
            width: "25%",
            cell: (row) => row.Prioritynumber,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Settings",
            name: <div className="tableheading">Settings</div>,
            minWidth: "2%",
            // width: "5%",
            editable: true,
            cell: (row) => row.enabledisablemenu,
            renderCell: htmlFormatter,
        },
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "100px",
                // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                minHeight: "80px",
                // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                // override the row height
            },
        },
    };

    const functablerow = (itemvalue) => {
        setItemsPerPage(parseInt(itemvalue));
    };

    const funcCurrentpage = (itemvalue) => {
        setCurrentPage(itemvalue);
        setItemsPerPage(itemsPerPage);
    };

    const cancelAllFilter = () => {
        setloguname("");
        setlogemailname("");
        setLogTriggeredtype("");
        setapiRuleID("");
        setEndDate("");
        setStartDate("");
        setdname("");
        setparks([]);
        setPending(true);
        setLoading(true);
        funcSearchoptions();
        setRefresh(!refresh);
        setCurrentPage(1);
        setItemsPerPage(10);
    };

    const backtorulepage = () => {
        let path = "/Rules";
        history.push(path);
    };
    const audittorulepage = () => {
        let path = "RuleLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${stateDetail}` },
        });
        window.scrollTo(0, 0);
    };

    const routeAllRulepage = () => {
        if (permission.create == 1) {
            let path = "allrulelogspage";
            history.push({
                pathname: `${path}`,
            });
        } else {
            alert("You do not have permission to All Rule Logs");
        }
    };

    function ViewRule(ruledataid) {
        // if (permission.create == 1) {
        let path = "rulelistview";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }

    function viewlogpage(logid) {
        // if (permission.create == 1) {
        let path = "ViewLogDetails";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${logid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // } else {
        //   alert("You do not have permission to create rule");
        // }
    }

    return (
        <div className="demoevent">
            <section className="content">
                <div className="logheading">
                    <h5 className="mb-0">Audit Logs </h5>
                    <div className="rightbtn logrightbtn">
                        <button
                            className="btn-sm btn badge-pill backicon btn-color"
                            onClick={backtorulepage}
                        >
                            <TiArrowBack className="mr-1" />
                            Back To Rule List Page
                        </button>
                        <button
                            className="btn-sm btn  backicon btn-logbtn viewaudittorulebtn"
                            onClick={audittorulepage}
                        >
                            <img src={alllog} alt="printerimg" className="img-fluid" />
                            Rule Logs
                        </button>
                    </div>
                </div>
                <div className="searchlogging">
                    <div>
                        <div className="row">
                            <div className="col-12 logmobilepadd">
                                <Card border="info" className="my-2 searchcard">
                                    <Card.Header>Custom Search</Card.Header>
                                    <Card.Body>
                                        <div className="allelment">
                                            <div className="allcontainer allbordersame">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Rule ID : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Rule ID"
                                                                value={
                                                                    stateDetail !== "" ? stateDetail : "Rule ID"
                                                                }
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Audit ID : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Audit ID"
                                                                value={getLogAuditID}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Rule Name : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Rule name"
                                                                value={getRuleName}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>Start Date : </label>
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={startDate}
                                                                    selectsStart
                                                                    startDate={startDate}
                                                                    onChange={(e) => funcStartDate(e)}
                                                                    maxDate={new Date()}
                                                                    className="form-control"
                                                                    placeholderText="mm/day/year"
                                                                    onFocus={handleImgClick}
                                                                    onBlur={handleDatePickerBlur}
                                                                    open={showDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>End Date : </label>
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={endDate}
                                                                    selectsStart
                                                                    placeholderText="mm/day/year"
                                                                    endDate={endDate}
                                                                    minDate={startDate}
                                                                    maxDate={new Date()}
                                                                    onChange={(e) => funcEndDate(e)}
                                                                    className="form-control"
                                                                    onFocus={handleEndImgClick}
                                                                    onBlur={handleEndDatePickerBlur}
                                                                    open={showEndDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleEndImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 spacebet">
                                                        <Form>
                                                            <Form.Group
                                                                controlId="exampleForm.ControlSelect1"
                                                                className="formdiv"
                                                            >
                                                                <Form.Label className="logsearch">
                                                                    Audit Log Search :
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handleLogType(e)}
                                                                    value={getLogTriggeredtype}
                                                                >
                                                                    <option value="">
                                                                        Search By OperationType
                                                                    </option>
                                                                    <option value="Update">Update</option>
                                                                    <option value="Create">Create</option>
                                                                    <option value="Enable">Enable</option>
                                                                    <option value="Disable">Disable</option>
                                                                    <option value="Delete">Delete</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Form>
                                                    </div>

                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>User Name : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={getloguname}
                                                                placeholder="Search By User Name"
                                                                onChange={(e) => HandleUserName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>User Email : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={getlogemailname}
                                                                placeholder="Search By User Email"
                                                                onChange={(e) => HandleEmailName(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div className="col-12 col-md-12 spacebet logbtnleftright">
                                                        <button
                                                            type="button"
                                                            className="btn  mr-2 logSearchbtn"
                                                            onClick={() => refreshData()}
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn logcancelbtn"
                                                            onClick={cancelAllFilter}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                {pending === false ? (
                    <div className="showtablehead logbordercolor">
                        <div className="leftheading"></div>
                        <div className="rightheading">
                            <div className="createaddrulebtn logcreatebtn">
                                {/* {permission.create == 1 ? ( */}
                                <Button
                                    className="addrule my-0 "
                                    size="md"
                                    variant="contained"
                                    onClick={() => ViewRule(stateDetail)}
                                    disabled={getcheckrow}
                                >
                                    <img
                                        src={alllog}
                                        alt="printerimg"
                                        className="img-fluid mr-2"
                                    />{" "}
                                    <span className="createrule"> VIEW RULE DETAILS </span>
                                </Button>
                                {/* ) : (
                                        ""
                                    )} */}
                                {/* {permission.create == 1 ? ( */}
                                <Button
                                    className="addrule my-0 viewgeneralbtn"
                                    size="md"
                                    variant="contained"
                                    onClick={routeAllRulepage}
                                    disabled={getcheckrow}
                                >
                                    <img
                                        src={alllog}
                                        alt="printerimg"
                                        className="img-fluid mr-2 "
                                    />{" "}
                                    <span className="createrule"> Rule Execution Logs </span>
                                </Button>
                                {/* ) : (
                                    ""
                                )} */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className="allogs desktopruleview mediumlogview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        <DataTable
                            columns={columnsdata}
                            data={getListdata}
                            striped
                            responsive
                            className="custom-table"
                            progressPending={pending}
                            progressComponent={
                                <ClipLoader
                                    color={color}
                                    loading={loading}
                                    css={override}
                                    size={60}
                                />
                            }
                            customStyles={customStyles}
                        />
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="allogs mobileruleview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        {getMobileRuleList?.length > 0 && pending === false ? (
                            getMobileRuleList &&
                            getMobileRuleList?.map((ruledata, idx) => {
                                return (
                                    <div
                                        className={`row mobilerulerow  ${idx == 0 ? "" : "mt-2"} `}
                                        key={idx}
                                    >
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Severity</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div className="commonsize logtableicons">
                                                    {ruledata.OperationType == "Update" ? (
                                                        <img src={update} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Disable" ? (
                                                        <img src={disable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Create" ? (
                                                        <img src={create} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Delete" ? (
                                                        <img src={deleteicon} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Enable" ? (
                                                        <img src={enable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>OperationType</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{ruledata.OperationType}</div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Rule Name</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{getRuleName ?? getRuleName}</div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Description</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{getRuleDescription ?? getRuleDescription}</div>
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Date</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 pl-1">
                                            <div className="validity ruleContent">
                                                <div className="rulealways">
                                                    {simpledate(ruledata.InsertDate)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>ModifiedBy</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 pl-1">
                                            <div className="validity ruleContent">
                                                {ruledata.AuditID !== "" ? (
                                                    <div className="w-100">
                                                        <div className="commonsize">
                                                            {ruledata.ModifiedBy.FullName}
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="triger commonsize mobilepy">
                                                            {ruledata.ModifiedBy.Email}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="w-100">
                                                        <div className="commonsize">NA</div>
                                                        <hr className="my-2" />
                                                        <div className="triger commonsize mobilepy">NA</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Settings</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="settings ruleContent">
                                                <div className="menubtn mobilemenubtn">
                                                    <button
                                                        className="btn logviewbtn"
                                                        onClick={() => viewlogpage(ruledata._id)}
                                                    >
                                                        <span className="actionbutton">View Log</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : loading ? (
                            <ClipLoader
                                color={color}
                                loading={loading}
                                css={override}
                                size={60}
                            />
                        ) : (
                            <div className="norecord">No Record Found</div>
                        )}
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </section>
        </div>
    );
}
