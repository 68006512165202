import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { GenerateEncryptedToken } from "../Common.js";
import { login_ } from "../../reducers/login.js";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import MaskedInput from "react-maskedinput";
import calendar from "../../assets/img/calendar-icon.png";
import update from "../../assets/img/update.png";
import create from "../../assets/img/create.png";
import enable from "../../assets/img/enable.png";
import disable from "../../assets/img/disable.png";
import deleteicon from "../../assets/img/delete.png";
import noaction from "../../assets/img/noaction.png";
import notsupport from "../../assets/img/notsupport.png";
import success from "../../assets/img/success.png";
import failed from "../../assets/img/failed.png";
import ignored from "../../assets/img/ignored.png";
import overruled from "../../assets/img/overruled.png";
import blocked from "../../assets/img/blocked.png";
import smartParkService from "../../axios/index.js";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import { MdSettings } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import { IoMdCreate } from "react-icons/io";
import { RiDeleteBinFill } from "react-icons/ri";
import { BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";
import { MdUpdate } from "react-icons/md";
import Slide from "@material-ui/core/Slide";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Table from "react-bootstrap/Table";
import Tablepaginate from "./Tablepaginate.js";
import { TiArrowBack } from "react-icons/ti";
import { initialState } from "../../reducers/rules.js";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
}));
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));
// Multiple select ================
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
//  emaillist section ====================
let cloneRulesInitialState = _.cloneDeep(initialState.data);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" position="top" ref={ref} {...props} />;
});

export default function AllRuleLogpage() {
    const classes = useStyles();
    const textRef = useRef(null);
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();
    // datainput ------=======================================================
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [color, setColor] = React.useState("#ffffff");
    const [getparks, setparks] = React.useState([]);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showEndDatePicker, setshowEndDatePicker] = useState(false);
    const [getapiRuleID, setapiRuleID] = React.useState("");
    const [getRuleName, setRuleName] = React.useState("");
    const [getLogTriggeredtype, setLogTriggeredtype] = React.useState("");
    const [getlogemailname, setlogemailname] = React.useState("");
    const [getloguname, setloguname] = React.useState("");
    const [getListdata, setListdata] = React.useState([]);
    const theme = useTheme();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [pending, setPending] = React.useState(true);
    const [getShouldUpdatePage, setShouldUpdatePage] = React.useState(false);
    // const [getDeleterefresh, setDeleterefresh] = React.useState(false);
    const [rows, setRowsdata] = React.useState([]);
    const [getdname, setdname] = React.useState("");
    const [getableRuleid, setableRuleid] = React.useState([]);
    const [getTotalrules, setTotalrules] = React.useState("");
    const [getTotalRowselect, setTotalRowselect] = React.useState("");
    const [getcheckrow, setcheckrow] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(10);
    const [getShowHeading, setShowHeading] = React.useState(false);
    const [filtershow, setfilterShow] = React.useState(false);
    const [getMobileRuleList, setMobileRuleList] = React.useState([]);
    const [getSearchpark, setSearchpark] = React.useState("");
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [getfinaldevice, setfinaldevice] = React.useState([]);
    const [getfinaldevicetype, setfinaldevicetype] = React.useState([]);
    const [gethandleParkDevice, sethandleParkDevice] = React.useState("");
    const [gethandleParkDeviceType, sethandleParkDeviceType] = React.useState("");
    const [getapiRuleName, setapiRuleName] = React.useState("");
    const [getSearchruleparkname, setSearchruleparkname] = React.useState("");
    const [getSearchruleDevicetype, setSearchruleDevicetype] = React.useState("");
    const [getSearchruleDevice, setSearchruleDevice] = React.useState("");
    const [getLogRuleID, setLogRuleID] = React.useState("");
    const [getopentriggered, setopentriggered] = React.useState(false);
    const [getopencreate, setopencreate] = React.useState(false);
    const [permission, setpermission] = React.useState({});
    const [refresh, setRefresh] = useState(false);
    const [daterefresh, setDateRefresh] = useState(false);
    const numRows = getableRuleid.length;
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    useEffect(() => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 1);
        sevenDaysAgo.setHours(0);
        sevenDaysAgo.setMinutes(0);
        sevenDaysAgo.setSeconds(0);
        sevenDaysAgo.setMilliseconds(0);
        const formattedStartDate = sevenDaysAgo;
        const formattedEndDate = today;
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setDateRefresh(true);
    }, [daterefresh]);

    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    function handleLogType(e) {
        let logtypevalue = e.target.value;
        setopencreate(false);
        setopentriggered(false);

        if (
            logtypevalue == "SUCCESS" ||
            logtypevalue == "FAILED" ||
            logtypevalue == "NO_ACTION" ||
            logtypevalue == "IGNORED" ||
            logtypevalue == "OVERRULED" ||
            logtypevalue == "BLOCKED" ||
            logtypevalue == "NOT_SUPPORTED" ||
            logtypevalue == "TRIGGERED"
        ) {
            setopencreate(false);
            setopentriggered(true);
        }

        if (
            logtypevalue == "Create" ||
            logtypevalue == "Update" ||
            logtypevalue == "Enable" ||
            logtypevalue == "Disable" ||
            logtypevalue == "Delete"
        ) {
            setopentriggered(false);
            setopencreate(true);
        }
        setLogTriggeredtype(logtypevalue);
        setShouldUpdatePage(true);
    }

    function trivalue(data, TriggerCriteria, Devicetype) {
        let symbol;
        if (TriggerCriteria === "exactly" || TriggerCriteria === "Exactly") {
            symbol = "==";
        }
        if (TriggerCriteria === "above" || TriggerCriteria === "Above") {
            symbol = ">";
        }
        if (TriggerCriteria === "below" || TriggerCriteria === "Below") {
            symbol = "<";
        }

        if (
            data === "opened" ||
            data === "Opened" ||
            data === "closed" ||
            data === "Closed"
        ) {
            return data;
        } else {
            return `${symbol} ${Devicetype && Devicetype.toLowerCase() === "court"
                    ? data.toLowerCase() === "open"
                        ? "Unoccupied"
                        : "Occupied"
                    : data
                }`;
        }
    }

    useEffect(() => {
        funcSearchoptions();
    }, []);

    function funcSearchoptions() {
        try {
            let url = "api/park/ruleengine/searchoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res.data.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    const handleImgClick = () => {
        setShowDatePicker(true);
        setshowEndDatePicker(false);
    };
    const handleDatePickerBlur = () => {
        setShowDatePicker(false);
    };
    const handleEndImgClick = () => {
        setshowEndDatePicker(true);
        setShowDatePicker(false);
    };
    const handleEndDatePickerBlur = () => {
        setshowEndDatePicker(false);
    };

    const handleParkId = (elem) => {
        const selectedOption = elem.target.options[elem.target.selectedIndex];
        // Access the data-name attribute value
        const dataName = selectedOption.getAttribute("data-name");
        setSearchpark(dataName);
        if (elem.target.value !== "All Parks") {
            sethandleParkId(elem.target.value);
            const parkid = elem.target.value;
            var alldevices = getSearchfilter;
            var finaldevice = alldevices[parkid];
            setfinaldevice(
                Object.keys(finaldevice).filter(
                    (category) => category === "Park" || finaldevice[category].length > 0
                )
            );
        } else {
            setfinaldevice([]);
            setfinaldevicetype([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            setSearchpark("");
            setSearchruleparkname("");
            setSearchruleDevicetype("");
            setSearchruleDevice("");
            setdname("");
            setapiRuleName("");
            setapiRuleID("");
            sethandleParkId("");
        }
        setShouldUpdatePage(true);
    };
    const handledeviceType = (event) => {
        setfinaldevicetype([]);
        sethandleParkDevice("");
        if (event.target.value !== "Device type") {
            const seldevice = event.target.value;
            sethandleParkDeviceType(event.target.value);
            const parkid = gethandleParkId;
            const park = getparks;
            const parkdevice = getfinaldevice;
            var parkobj = [];
            park?.forEach((data, idx) => {
                if (data.id == parkid) {
                    parkobj = data;
                }
            });
            var devices = parkobj["device"] ?? [];
            var devicetype = devices[seldevice] ?? [];
            if (!Array.isArray(devicetype)) {
                let devicetypeone = [devicetype];
                setfinaldevicetype(devicetypeone);
            } else {
                setfinaldevicetype(devicetype);
            }
        } else {
            sethandleParkDeviceType("");
            sethandleParkDevice("");
        }
        setShouldUpdatePage(true);
    };

    const handledevice = (elem) => {
        if (elem.target.value !== "Device") {
            sethandleParkDevice(elem.target.value);
            const handledevicename = elem.target.options[elem.target.selectedIndex];
            const dname = handledevicename.getAttribute("data-device");
            setdname(dname);
        } else {
            sethandleParkDevice("");
        }
        setShouldUpdatePage(true);
    };

    const funcStartDate = (e) => {
        setShowDatePicker(false);
        if (e !== null && e !== undefined) {
            const startDateTime = new Date(e);
            startDateTime.setHours(0, 0, 0);
            setStartDate(startDateTime);
        } else {
            setStartDate("");
        }
        setShouldUpdatePage(true);
    };

    function funcEndDate(e) {
        setEndDate("");
        if (e !== null && e !== undefined) {
            const endDateTime = new Date(e);
            endDateTime.setHours(23, 59, 59);
            setEndDate(endDateTime);
        } else {
            setEndDate("");
        }
        setshowEndDatePicker(false);
        setShouldUpdatePage(true);
    }
    const handleRuleId = (elem) => {
        setapiRuleID(elem.target.value);
        setShouldUpdatePage(true);
    };
    const handleRulename = (elem) => {
        setRuleName(elem.target.value);
        setShouldUpdatePage(true);
    };
    const HandleUserName = (elem) => {
        setloguname(elem.target.value);
        setShouldUpdatePage(true);
    };
    const HandleEmailName = (elem) => {
        setlogemailname(elem.target.value);
        setShouldUpdatePage(true);
    };
    useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);

    function simpledate(date) {
        try {
            const otherZone = moment
                .tz(date, "America/New_York")
                .format("L h:mm:ss A");
            return otherZone;
        } catch {
            return date;
        }
    }

    function Viewlog(logruleid) {
        let path = "RuleLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${logruleid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
    }
    function Auditlog(auditruleid) {
        let path = "AuditLogpage";
        history.push({
            pathname: `${path}`,
            state: { detail: `${auditruleid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
        // history.push("/AuditLogpage");
    }
    function viewrule(ruledataid) {
        let path = "rulelistview";
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${ruledataid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
    }

    function viewlogpage(logid) {
        // if (permission.create == 1) {
        let path = "ViewLogDetails";
        // setnewrule(true);
        history.push({
            pathname: `${path}`,
            search: "", // Adding query parameters to the URL
            state: { detail: `${logid}` }, // Adding state data
        });
        window.scrollTo(0, 0);
    }

    const htmlFormatter = ({ value }) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    );

    useEffect(() => {
        if (daterefresh === true) {
            refreshData();
        }
    }, [currentPage, itemsPerPage, refresh, daterefresh]);

    const refreshData = async () => {
        if (getShouldUpdatePage) {
            setCurrentPage(1);
        }
        const updatedPage = getShouldUpdatePage ? 1 : currentPage; // Update this with the new page number
        const updatedItemsPerPage = itemsPerPage; // Update this with the new items per page value
        console.log(startDate);
        console.log(endDate);
        let estTimeZone = "America/New_York"; // Eastern Standard Time (EST)
        // Convert startDate to EST and format
        let estStartTimestamp = "";
        if (startDate !== "" && startDate !== undefined) {
            estStartTimestamp = moment
                .tz(startDate, estTimeZone) // Explicitly set the timezone
                .endOf("day") // Set time to 00:00:00
                .format("L h:mm:ss A");
        } else {
            estStartTimestamp = "";
        }
        // Convert endDate to EST and format
        let estEndTimestamp = "";
        if (endDate !== "" && endDate !== undefined) {
            estEndTimestamp = moment(endDate)
                .tz(estTimeZone)
                .set({ hour: 23, minute: 59, second: 59 })
                .format("L h:mm:ss A");
        } else {
            estEndTimestamp = "";
        }
        console.log(estStartTimestamp);
        console.log(estEndTimestamp);
        // Convert EST formatted timestamps to UTC
        let unixStartTimestamp =
            estStartTimestamp !== ""
                ? moment(estStartTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";
        let unixEndTimestamp =
            estEndTimestamp !== ""
                ? moment(estEndTimestamp, "L h:mm:ss A").utc().valueOf()
                : "";

        try {
            var finaldata = [];
            let url = "api/park/ruleengine/listallrulelogs";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: updatedPage,
                Limit: updatedItemsPerPage,
                RuleID: getapiRuleID,
                RuleName: getRuleName,
                StartDate: unixStartTimestamp,
                EndDate: unixEndTimestamp,
                LogType: getLogTriggeredtype, // Create, Update, Enable, Disable, Delete
                ParkID: gethandleParkId, // TRIGGERED TYPES ONLY
                Devicetype: gethandleParkDeviceType, // TRIGGERED TYPES ONLY
                DeviceID: gethandleParkDevice, // TRIGGERED TYPES ONLY
                Name: getloguname,
                Email: getlogemailname,
            };
            console.log(requestBody);
            await smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    console.log(" all log list ===>  ", res);
                    setShouldUpdatePage(false);
                    if (res) {
                        if (res.data.data?.length < 1) {
                            setPending(false);
                            setLoading(false);
                            // return false;
                        }
                        if (res.data.status === "200") {
                            setTotalrules(res.data.metadata.total);
                            setTotalRows(res.data.metadata.pages);
                            setShowHeading(true);
                        } else {
                            refreshData();
                        }
                        if (res.data.data?.length > 0) {
                            setMobileRuleList(res.data.data);
                        }
                        res.data.data?.forEach((data, idx) => {
                            let datavalue;
                            datavalue = data.Actions.map((item) => item.Status).filter(
                                (action) => action
                            );
                            const datasame = datavalue.some((item) => item === item);
                            if (
                                datavalue.length > 0 &&
                                datavalue.every((status) => status === datavalue[0])
                            ) {
                                datavalue = datavalue[0];
                            } else {
                                console.log("Values in the array are different.");
                            }
                            // console.log(datavalue);
                            const Logseverity = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize logtableicons">
                                            {data.OperationType == "Update" ? (
                                                <img src={update} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Disable" ? (
                                                <img src={disable} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Create" ? (
                                                <img src={create} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Delete" ? (
                                                <img src={deleteicon} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {data.OperationType == "Enable" ? (
                                                <img src={enable} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "IGNORED" ? (
                                                <img src={ignored} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "NOT_SUPPORTED" ? (
                                                <img src={notsupport} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "BLOCKED" ? (
                                                <img src={blocked} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "OVERRULED" ? (
                                                <img src={overruled} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "NO_ACTION" ? (
                                                <img src={noaction} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "FAILED" ? (
                                                <img src={failed} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                            {datavalue == "SUCCESS" ? (
                                                <img src={success} className="img-fluid" />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            };
                            const logOperationType = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{data.OperationType}</div>
                                    </div>
                                );
                            };
                            const logdate = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">
                                            {simpledate(data.InsertDate)}
                                        </div>
                                    </div>
                                );
                            };
                            const ruledes = () => {
                                return (
                                    <div className="w-100">
                                        {/* <div className="commonsize">{data.RuleID}</div>
                    <hr className="my-1 mb-2" /> */}
                                        <div className="commonsize">
                                            {data.AuditID !== "" ? data.AuditID : "NA"}
                                        </div>
                                    </div>
                                );
                            };
                            const rulenamedes = () => {
                                return (
                                    <div className="w-100">
                                        <div className="commonsize">{data.RuleName}</div>
                                        <hr className="my-1 mb-2" />
                                        <div className="commonsize">{data.RuleDescription}</div>
                                    </div>
                                );
                            };

                            const CombineTriggerConditions = () => {
                                return (
                                    <div className="text-center w-100 ">
                                        {data.OperationType == "Triggered" ? (
                                            <>
                                                {data.Conditions?.length > 0
                                                    ? data.Conditions?.map((condition, idx) => {
                                                        const parkNameDisplay =
                                                            condition.ParkName === "Morrisville Community Park"
                                                                ? "MCP"
                                                                : condition.ParkName ===
                                                                    "Morrisville Aquatic and Fitness Center"
                                                                    ? "MAFC"
                                                                    : condition.ParkName ===
                                                                        "Cedar Fork District Park"
                                                                        ? "CFDP" : condition.ParkName === "Shiloh Park" ? 'SP'
                                                                            : condition.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                : condition.ParkName === "Church Street Park" ? 'CSP'
                                                                                    : "NA";
                                                        return (
                                                            <Accordion
                                                                className={`viewaccordion trigeracco ${(condition.Status && condition.Status == "true") || (condition.ObservedState && condition.ObservedState.wasConditionMet == true)
                                                                        ? "logtrue"
                                                                        : (condition.Status && condition.Status == "false") || (condition.ObservedState && condition.ObservedState.wasConditionMet == false)
                                                                            ? "logfalse"
                                                                            : ""
                                                                    }`}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<KeyboardArrowRightIcon />}
                                                                    aria-controls={`panel${idx + 1}a-content`}
                                                                    id={`panel${idx + 1}a-header`}
                                                                    className="accorhead"
                                                                >
                                                                    <Typography
                                                                        className={`w-100  ${classes.heading}`}
                                                                    >
                                                                        <span className="headname commonsize">
                                                                            {parkNameDisplay} ,{" "}
                                                                            {condition.DeviceName} ,{" "}
                                                                            {trivalue(
                                                                                condition.TriggerValue,
                                                                                condition.TriggerCriteria,
                                                                                condition.Devicetype
                                                                            )}{" "}
                                                                        </span>{" "}
                                                                        <span
                                                                            className={`allstatus ${(condition.Status && condition.Status == "true") || (condition.ObservedState && condition.ObservedState.wasConditionMet == true)
                                                                                    ? "truecolor"
                                                                                    : (condition.Status && condition.Status == "false") || (condition.ObservedState && condition.ObservedState.wasConditionMet == false)
                                                                                        ? "falsecolor"
                                                                                        : ""
                                                                                }`}
                                                                        >
                                                                            Status: {`${(condition.ObservedState && condition.ObservedState ? condition.ObservedState.wasConditionMet : condition.Status)}`}
                                                                        </span>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="d-block">
                                                                    <Table
                                                                        size="sm"
                                                                        className="text-left"
                                                                        key={idx}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Park Name: {parkNameDisplay}</td>
                                                                                <td>
                                                                                    Device Type: {condition.Devicetype}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Device Name: {condition.DeviceName}
                                                                                </td>
                                                                                <td>
                                                                                    Trigger Value:{" "}
                                                                                    {condition.Devicetype.toLowerCase() ===
                                                                                        "court"
                                                                                        ? condition.TriggerValue.toLowerCase() ===
                                                                                            "open"
                                                                                            ? "Unoccupied"
                                                                                            : "Occupied"
                                                                                        : condition.TriggerValue}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Trigger Criteria:{" "}
                                                                                    {condition.TriggerCriteria}
                                                                                </td>
                                                                                <td>
                                                                                    Duration: {condition.Tolerance}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    {condition.ObservedState ? "ObservedMessage" : "ObservedValue"} :
                                                                                    {condition.Devicetype &&
                                                                                        condition.Devicetype.toLowerCase() ===
                                                                                        "court"
                                                                                        ? condition.ObservedValue && condition.ObservedValue.toLowerCase() === "open" ? "Unoccupied" : condition.ObservedValue && condition.ObservedValue.toLowerCase() === "close" ? "Occupied" : condition.ObservedState.ObservedMessage
                                                                                        : condition.ObservedState && condition.ObservedState ? condition.ObservedState.ObservedMessage : condition.ObservedValue}
                                                                                </td>
                                                                                <td>Status: {`${(condition.ObservedState && condition.ObservedState ? condition.ObservedState.wasConditionMet : condition.Status)}`}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    })
                                                    : "TIME BASED RULE"}
                                            </>
                                        ) : (
                                            "NA"
                                        )}
                                        <hr className="my-1 mb-2" />
                                        {data.Actions?.length > 0 ? (
                                            <>
                                                {data.Actions?.map((data, idx) => {
                                                    const parkNameDisplay =
                                                        data.ParkName === "Morrisville Community Park"
                                                            ? "MCP"
                                                            : data.ParkName ===
                                                                "Morrisville Aquatic and Fitness Center"
                                                                ? "MAFC"
                                                                : data.ParkName ===
                                                                    "Cedar Fork District Park"
                                                                    ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                        : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                            : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                : "NA";
                                                    return (
                                                        <Accordion
                                                            className={`viewaccordion trigeracco ${data.Status == "IGNORED"
                                                                    ? "logignore"
                                                                    : data.Status == "SUCCESS"
                                                                        ? "logsuccess"
                                                                        : data.Status == "FAILED"
                                                                            ? "logfailed"
                                                                            : data.Status == "NO_ACTION"
                                                                                ? "lognoaction"
                                                                                : data.Status == "OVERRULED"
                                                                                    ? "logoveruled"
                                                                                    : data.Status == "BLOCKED"
                                                                                        ? "logblocked"
                                                                                        : data.Status == "NOT_SUPPORTED"
                                                                                            ? "lognotsupport"
                                                                                            : data.Status == "TRIGGERED"
                                                                                                ? "logtriggered"
                                                                                                : ""
                                                                }`}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<KeyboardArrowRightIcon />}
                                                                aria-controls={`panel${idx + 1}a-content`}
                                                                id={`panel${idx + 1}a-header`}
                                                                className="accorhead"
                                                            >
                                                                <Typography
                                                                    className={`w-100  ${classes.heading}`}
                                                                >
                                                                    <span className="headname commonsize">
                                                                        {parkNameDisplay},{data.DeviceName},
                                                                        {data.Action}{" "}
                                                                    </span>{" "}
                                                                    <span
                                                                        className={`actionlogstatus ${data.Status == "IGNORED"
                                                                                ? "ignoreColor"
                                                                                : ""
                                                                            }`}
                                                                    >
                                                                        Status : {data.Status}
                                                                    </span>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="d-block">
                                                                <Table
                                                                    striped
                                                                    bordered
                                                                    hover
                                                                    size="sm"
                                                                    className="text-left"
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Park Name : {parkNameDisplay}</td>
                                                                            <td>Device Type : {data.Devicetype}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Device : {data.DeviceName}</td>
                                                                            <td>Action : {data.Action}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Reason : {data.Reason}</td>
                                                                            <td>Status : {data.Status}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            "NA"
                                        )}
                                    </div>
                                );
                            };
                            const rulePriority = () => {
                                return (
                                    <>
                                        {data.AuditID !== "" ? (
                                            <div className="w-100">
                                                <div className="commonsize">
                                                    {data.ModifiedBy.FullName}
                                                </div>
                                                <hr className="my-2" />
                                                <div className="triger commonsize mobilepy">
                                                    {data.ModifiedBy.Email}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-100">
                                                <div className="commonsize">NA</div>
                                                <hr className="my-2" />
                                                <div className="triger commonsize mobilepy">NA</div>
                                            </div>
                                        )}
                                    </>
                                );
                            };

                            const enabledisablesmenu = () => {
                                return (
                                    <div className="menubtn">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                className="settingdropdown"
                                            >
                                                <MdSettings />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="actionicons">
                                                <Dropdown.Item
                                                    href="#"
                                                    onClick={() => Viewlog(data.RuleID)}
                                                >
                                                    <span className="actionbutton">Rule Logs</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    href="#"
                                                    onClick={() => Auditlog(data.RuleID)}
                                                >
                                                    <span className="actionbutton">Audit Logs</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    href="#"
                                                    onClick={() => viewrule(data.RuleID)}
                                                >
                                                    <span className="actionbutton"> View Rule</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    href="#"
                                                    onClick={() => viewlogpage(data._id)}
                                                >
                                                    <span className="actionbutton"> View Log</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                );
                            };
                            finaldata.push({
                                // id: data.RuleID,
                                Logseverity: Logseverity(),
                                LogOperationType: logOperationType(),
                                RuleDes: ruledes(),
                                RuleNameDes: rulenamedes(),
                                Prioritynumber: rulePriority(),
                                logdate: logdate(),
                                Conditionalsetting: CombineTriggerConditions(),
                                enabledisablemenu: enabledisablesmenu(),
                            });
                        });
                        setListdata(finaldata);
                    } else {
                        console.log("Rule log list error response: ");
                    }
                })
                .catch((error) => {
                    console.error("Rule log List error: ", error);
                });
        } catch {
            console.log("Log List error ");
        }
    };

    const columnsdata = [
        {
            field: "Severity",
            name: (
                <div className="w-100">
                    <div className="tableheading">Severity </div>
                </div>
            ),
            minWidth: "2%",
            width: "5%",
            cell: (row) => row.Logseverity,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "OperationType",
            name: (
                <div className="w-100">
                    <div className="tableheading">OperationType </div>
                </div>
            ),
            minWidth: "8% ",
            width: "8%",
            cell: (row) => row.LogOperationType,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "RuleDes",
            name: (
                <div className="w-100">
                    {/* <div className="tableheading">Rule ID </div>
          <hr className="my-2" /> */}
                    <div className="tableheading">Audit ID </div>
                </div>
            ),
            minWidth: "10%",
            width: "13%",
            cell: (row) => row.RuleDes,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "RuleNameDes",
            name: (
                <div className="w-100">
                    <div className="tableheading">Rule Name </div>
                    <hr className="my-2" />
                    <div className="tableheading">Rule Description </div>
                </div>
            ),
            minWidth: "10%",
            width: "12%",
            cell: (row) => row.RuleNameDes,
            editable: true,
            renderCell: htmlFormatter,
        },

        {
            field: "Logdate",
            name: (
                <div className="w-100">
                    <div className="tableheading">Date</div>
                </div>
            ),
            minWidth: "5%",
            width: "7%",
            cell: (row) => row.logdate,
            editable: true,
            renderCell: htmlFormatter,
        },

        {
            field: "Conditionalsetting",
            name: (
                <div className="w-100">
                    <div className="tableheading">Conditions</div>
                    <hr className="my-2" />
                    <div className="tableheading">Actions</div>
                </div>
            ),
            minWidth: "20%",
            width: "35%",
            editable: true,
            cell: (row) => row.Conditionalsetting,
            renderCell: htmlFormatter,
        },
        {
            field: "Prioritynumber",
            name: (
                <div className="w-100">
                    <div className="tableheading">Modified By</div>
                </div>
            ),
            minWidth: "5%",
            width: "15%",
            cell: (row) => row.Prioritynumber,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "Settings",
            name: <div className="tableheading">Settings</div>,
            minWidth: "2%",
            // width: "5%",
            editable: true,
            cell: (row) => row.enabledisablemenu,
            renderCell: htmlFormatter,
        },
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "100px",
                // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                minHeight: "80px",

                // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",

                // override the row height
            },
        },
    };

    const functablerow = (itemvalue) => {
        setItemsPerPage(parseInt(itemvalue));
    };

    const funcCurrentpage = (itemvalue) => {
        setCurrentPage(itemvalue);
        setItemsPerPage(itemsPerPage);
    };

    const cancelAllFilter = () => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 1);
        sevenDaysAgo.setHours(0);
        sevenDaysAgo.setMinutes(0);
        sevenDaysAgo.setSeconds(0);
        sevenDaysAgo.setMilliseconds(0);
        const formattedStartDate = sevenDaysAgo;
        const formattedEndDate = today;
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setloguname("");
        setRuleName("");
        setlogemailname("");
        setLogTriggeredtype("");
        setapiRuleID("");
        setdname("");
        setparks([]);
        setPending(true);
        setLoading(true);
        setopencreate(false);
        setopentriggered(false);
        funcSearchoptions();
        setRefresh(!refresh);
        setCurrentPage(1);
        setItemsPerPage(10);
    };

    const backtorulepage = () => {
        let path = "/Rules";
        history.push(path);
    };
    const audittorulepage = () => {
        let path = "RuleLogpage";
        history.push({
            pathname: `${path}`,
            // state:{detail:`${stateDetail}`},
        });
        window.scrollTo(0, 0);
    };

    return (
        <div className="demoevent">
            <section className="content">
                <div className="logheading generallog">
                    <h5 className="mb-0">Rule Execution Logs </h5>
                    <div className="rightbtn logrightbtn">
                        <button
                            className="btn-sm btn badge-pill backicon btn-color"
                            onClick={backtorulepage}
                        >
                            <TiArrowBack className="mr-1" />
                            Back To Rule List Page
                        </button>
                    </div>
                </div>
                <div className="searchlogging">
                    <div>
                        <div className="row">
                            <div className="col-12 logmobilepadd">
                                <Card border="info" className="my-2 searchcard">
                                    <Card.Header>Custom Search</Card.Header>
                                    <Card.Body>
                                        <div className="allelment">
                                            <div className="allcontainer allbordersame">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Rule ID :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={getapiRuleID}
                                                                placeholder="Search By Rule ID"
                                                                onChange={(e) => handleRuleId(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch">
                                                            <label>Rule Name : </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Rule Name"
                                                                value={getRuleName}
                                                                onChange={(e) => handleRulename(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>Start Date : </label>
                                                            {/* <input type='date' className='form-control' onChange={(e) => handleLogStartDate(e)} /> */}
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={startDate}
                                                                    selectsStart
                                                                    startDate={startDate}
                                                                    onChange={(e) => funcStartDate(e)}
                                                                    maxDate={new Date()}
                                                                    className="form-control"
                                                                    placeholderText="mm/day/year"
                                                                    onFocus={handleImgClick}
                                                                    onBlur={handleDatePickerBlur}
                                                                    open={showDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <div className="timesearch removelogborder">
                                                            <label>End Date : </label>
                                                            <div
                                                                className={classes.startdatecss}
                                                                style={{
                                                                    position: "relative",
                                                                    border: "1px solid #5b6065",
                                                                    width: "100%",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <DatePicker
                                                                    // showTimeSelect
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={endDate}
                                                                    selectsStart
                                                                    placeholderText="mm/day/year"
                                                                    endDate={endDate}
                                                                    minDate={startDate}
                                                                    maxDate={(startDate) => {
                                                                        const maxDate = new Date(startDate);
                                                                        maxDate.setDate(maxDate.getDate() + 6);
                                                                        return maxDate;
                                                                    }}
                                                                    onChange={(e) => funcEndDate(e)}
                                                                    className="form-control"
                                                                    onFocus={handleEndImgClick}
                                                                    onBlur={handleEndDatePickerBlur}
                                                                    open={showEndDatePicker}
                                                                    customInput={
                                                                        <MaskedInput mask="11/11/1111" />
                                                                    }
                                                                />
                                                                <img
                                                                    src={calendar}
                                                                    className="img-fluid calenderimg"
                                                                    alt="calender image"
                                                                    onClick={handleEndImgClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 spacebet">
                                                        <Form>
                                                            <Form.Group
                                                                controlId="exampleForm.ControlSelect1"
                                                                className="formdiv"
                                                            >
                                                                <Form.Label className="logsearch">
                                                                    Audit Log Search :
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handleLogType(e)}
                                                                    value={getLogTriggeredtype}
                                                                >
                                                                    <option value="">
                                                                        Search By OperationType
                                                                    </option>
                                                                    <option value="Update">UPDATE</option>
                                                                    <option value="Create">CREATE</option>
                                                                    <option value="Enable">ENABLE</option>
                                                                    <option value="Disable">DISABLE</option>
                                                                    <option value="Delete">DELETE</option>
                                                                    <option value="SUCCESS">SUCCESS</option>
                                                                    <option value="FAILED">FAILED</option>
                                                                    <option value="NO_ACTION">NO_ACTION</option>
                                                                    <option value="IGNORED">IGNORED</option>
                                                                    <option value="OVERRULED">OVERRULED</option>
                                                                    <option value="BLOCKED">BLOCKED</option>
                                                                    <option value="NOT_SUPPORTED">
                                                                        NOT_SUPPORTED
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                                {getopentriggered ? (
                                                    <div className="row triggertype">
                                                        <div className="col-12 col-md-6 spacebet">
                                                            <div className="timesearch">
                                                                <label>Park Name : </label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handleParkId(e)}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <option>All Parks</option>
                                                                    {getparks?.map((data, idx) => (
                                                                        <option
                                                                            key={idx}
                                                                            value={data.id}
                                                                            data-idx={idx}
                                                                            data-name={data.name}
                                                                        >
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 spacebet">
                                                            <div className="timesearch">
                                                                <label>Device Type : </label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handledeviceType(e)}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <option>Device type</option>
                                                                    {gethandleParkId !== "" &&
                                                                        getfinaldevice?.map((data, idx) => (
                                                                            <option
                                                                                key={idx}
                                                                                value={data}
                                                                                data-idx={idx}
                                                                            >
                                                                                {data}
                                                                            </option>
                                                                        ))}
                                                                </Form.Control>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 spacebet">
                                                            <div className="timesearch">
                                                                <label>Device Name : </label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={(e) => handledevice(e)}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <option>Device</option>
                                                                    {(gethandleParkId !== "" ||
                                                                        gethandleParkDeviceType !== "") &&
                                                                        getfinaldevicetype?.map((data, idx) => (
                                                                            <option
                                                                                key={idx}
                                                                                data-device={
                                                                                    data.Name
                                                                                        ? data.Name
                                                                                        : data.FieldName
                                                                                            ? data.FieldName
                                                                                            : data.SensorName
                                                                                }
                                                                                value={
                                                                                    data.ID
                                                                                        ? data.ID
                                                                                        : data.FieldID
                                                                                            ? data.FieldID
                                                                                            : data.SensorID
                                                                                }
                                                                                data-idx={idx}
                                                                            >
                                                                                {data.Name
                                                                                    ? data.Name
                                                                                    : data.FieldName
                                                                                        ? data.FieldName
                                                                                        : data.SensorName}
                                                                            </option>
                                                                        ))}
                                                                </Form.Control>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {getopencreate ? (
                                                    <div className="row createtype">
                                                        <div className="col-12 col-md-6 spacebet">
                                                            <div className="timesearch">
                                                                <label>User Name : </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={getloguname}
                                                                    placeholder="Search By User Name"
                                                                    onChange={(e) => HandleUserName(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 spacebet">
                                                            <div className="timesearch">
                                                                <label>User Email : </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={getlogemailname}
                                                                    placeholder="Search By User Email"
                                                                    onChange={(e) => HandleEmailName(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                <div className="row mt-1">
                                                    <div className="col-12 col-md-12 spacebet logbtnleftright">
                                                        <button
                                                            type="button"
                                                            className="btn  mr-2 logSearchbtn"
                                                            onClick={() => refreshData()}
                                                        >
                                                            Search
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn logcancelbtn"
                                                            onClick={cancelAllFilter}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="allogs desktopruleview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        <DataTable
                            columns={columnsdata}
                            data={getListdata}
                            striped
                            responsive
                            className="custom-table"
                            progressPending={pending}
                            progressComponent={
                                <ClipLoader
                                    color={color}
                                    loading={loading}
                                    css={override}
                                    size={60}
                                />
                            }
                            customStyles={customStyles}
                        />
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>

                <div className="allogs mobileruleview">
                    <div className="content-middle rules-middle mobilewidth mb-2">
                        {getMobileRuleList?.length > 0 && pending === false ? (
                            getMobileRuleList &&
                            getMobileRuleList?.map((ruledata, idx) => {
                                let datavalue;
                                datavalue = ruledata.Actions.map((item) => item.Status).filter(
                                    (action) => action
                                );
                                const datasame = datavalue.some((item) => item === item);
                                if (
                                    datavalue.length > 0 &&
                                    datavalue.every((status) => status === datavalue[0])
                                ) {
                                    datavalue = datavalue[0];
                                } else {
                                    console.log("Values in the array are different.");
                                }
                                return (
                                    <div
                                        className={`row mobilerulerow  ${idx == 0 ? "" : "mt-2"} `}
                                        key={idx}
                                    >
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Severity</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div className="commonsize logtableicons">
                                                    {ruledata.OperationType == "Update" ? (
                                                        <img src={update} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Disable" ? (
                                                        <img src={disable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Create" ? (
                                                        <img src={create} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Delete" ? (
                                                        <img src={deleteicon} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ruledata.OperationType == "Enable" ? (
                                                        <img src={enable} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "IGNORED" ? (
                                                        <img src={ignored} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "NOT_SUPPORTED" ? (
                                                        <img src={notsupport} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "BLOCKED" ? (
                                                        <img src={blocked} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "OVERRULED" ? (
                                                        <img src={overruled} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "NO_ACTION" ? (
                                                        <img src={noaction} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "FAILED" ? (
                                                        <img src={failed} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {datavalue == "SUCCESS" ? (
                                                        <img src={success} className="img-fluid" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>OperationType</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{ruledata.OperationType}</div>
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Audit ID</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>
                                                    {ruledata.AuditID !== "" ? ruledata.AuditID : "NA"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Rule Name</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{ruledata.RuleName}</div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Rule Description</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="ruleContent">
                                                <div>{ruledata.RuleDescription}</div>
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Date</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 pl-1">
                                            <div className="validity ruleContent">
                                                <div className="rulealways">
                                                    {simpledate(ruledata.InsertDate)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Conditions</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="conditional ruleContent">
                                                {ruledata.OperationType == "Triggered" ? (
                                                    <>
                                                        {ruledata.Conditions?.length > 0
                                                            ? ruledata.Conditions?.map((condition, idx) => {
                                                                const parkNameDisplay =
                                                                    condition.ParkName === "Morrisville Community Park"
                                                                        ? "MCP"
                                                                        : condition.ParkName ===
                                                                            "Morrisville Aquatic and Fitness Center"
                                                                            ? "MAFC"
                                                                            : condition.ParkName ===
                                                                                "Cedar Fork District Park"
                                                                                ? "CFDP" : condition.ParkName === "Shiloh Park" ? 'SP'
                                                                                    : condition.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                        : condition.ParkName === "Church Street Park" ? 'CSP'
                                                                                            : "NA";
                                                                return (
                                                                    <Accordion
                                                                        className={`viewaccordion trigeracco ${(condition.Status && condition.Status == "true") || (condition.ObservedState && condition.ObservedState.wasConditionMet == true)
                                                                                ? "logtrue"
                                                                                : (condition.Status && condition.Status == "false") || (condition.ObservedState && condition.ObservedState.wasConditionMet == false)
                                                                                    ? "logfalse"
                                                                                    : ""
                                                                            }`}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<KeyboardArrowRightIcon />}
                                                                            aria-controls={`panel${idx + 1
                                                                                }a-content`}
                                                                            id={`panel${idx + 1}a-header`}
                                                                            className="accorhead"
                                                                        >
                                                                            <Typography
                                                                                className={`w-100  ${classes.heading}`}
                                                                            >
                                                                                <span className="headname commonsize">
                                                                                    {parkNameDisplay} ,{" "}
                                                                                    {condition.DeviceName} ,{" "}
                                                                                    {trivalue(
                                                                                        condition.TriggerValue,
                                                                                        condition.TriggerCriteria,
                                                                                        condition.Devicetype
                                                                                    )}{" "}
                                                                                </span>{" "}
                                                                                <span
                                                                                    className={`allstatus ${(condition.Status && condition.Status == "true") || (condition.ObservedState && condition.ObservedState.wasConditionMet == true)
                                                                                            ? "truecolor"
                                                                                            : (condition.Status && condition.Status == "false") || (condition.ObservedState && condition.ObservedState.wasConditionMet == false)
                                                                                                ? "falsecolor"
                                                                                                : ""
                                                                                        }`}
                                                                                >
                                                                                    Status: {`${(condition.ObservedState && condition.ObservedState ? condition.ObservedState.wasConditionMet : condition.Status)}`}
                                                                                </span>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails className="d-block">
                                                                            <Table
                                                                                size="sm"
                                                                                className="text-left"
                                                                                key={idx}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Park Name: {parkNameDisplay}
                                                                                        </td>
                                                                                        <td>
                                                                                            Device Type:{" "}
                                                                                            {condition.Devicetype}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Device Name:{" "}
                                                                                            {condition.DeviceName}
                                                                                        </td>
                                                                                        <td>
                                                                                            Trigger Value:{" "}
                                                                                            {condition.Devicetype.toLowerCase() ===
                                                                                                "court"
                                                                                                ? condition.TriggerValue.toLowerCase() ===
                                                                                                    "open"
                                                                                                    ? "Unoccupied"
                                                                                                    : "Occupied"
                                                                                                : condition.TriggerValue}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Trigger Criteria:{" "}
                                                                                            {condition.TriggerCriteria}
                                                                                        </td>
                                                                                        <td>
                                                                                            Duration: {condition.Tolerance}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {condition.ObservedState ? "ObservedMessage" : "ObservedValue"} :
                                                                                            {condition.Devicetype &&
                                                                                                condition.Devicetype.toLowerCase() ===
                                                                                                "court"
                                                                                                ? condition.ObservedValue && condition.ObservedValue.toLowerCase() === "open" ? "Unoccupied" : condition.ObservedValue && condition.ObservedValue.toLowerCase() === "close" ? "Occupied" : condition.ObservedState.ObservedMessage
                                                                                                : condition.ObservedState && condition.ObservedState ? condition.ObservedState.ObservedMessage : condition.ObservedValue}
                                                                                        </td>
                                                                                        <td>Status: {`${(condition.ObservedState && condition.ObservedState ? condition.ObservedState.wasConditionMet : condition.Status)}`}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                );
                                                            })
                                                            : "TIME BASED RULE"}
                                                    </>
                                                ) : (
                                                    "NA"
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Actions</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="actions ruleContent">
                                                {ruledata.Actions?.length > 0 ? (
                                                    <>
                                                        {ruledata.Actions?.map((data, idx) => {
                                                            const parkNameDisplay =
                                                                data.ParkName === "Morrisville Community Park"
                                                                    ? "MCP"
                                                                    : data.ParkName ===
                                                                        "Morrisville Aquatic and Fitness Center"
                                                                        ? "MAFC"
                                                                        : data.ParkName ===
                                                                            "Cedar Fork District Park"
                                                                            ? "CFDP" : data.ParkName === "Shiloh Park" ? 'SP'
                                                                                : data.ParkName === "Crabtree Creek Nature Park" ? 'CCNP'
                                                                                    : data.ParkName === "Church Street Park" ? 'CSP'
                                                                                        : "NA";
                                                            return (
                                                                <Accordion
                                                                    className={`viewaccordion trigeracco ${data.Status == "IGNORED"
                                                                            ? "logignore"
                                                                            : data.Status == "SUCCESS"
                                                                                ? "logsuccess"
                                                                                : data.Status == "FAILED"
                                                                                    ? "logfailed"
                                                                                    : data.Status == "NO_ACTION"
                                                                                        ? "lognoaction"
                                                                                        : data.Status == "OVERRULED"
                                                                                            ? "logoveruled"
                                                                                            : data.Status == "BLOCKED"
                                                                                                ? "logblocked"
                                                                                                : data.Status == "NOT_SUPPORTED"
                                                                                                    ? "lognotsupport"
                                                                                                    : data.Status == "TRIGGERED"
                                                                                                        ? "logtriggered"
                                                                                                        : ""
                                                                        }`}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<KeyboardArrowRightIcon />}
                                                                        aria-controls={`panel${idx + 1}a-content`}
                                                                        id={`panel${idx + 1}a-header`}
                                                                        className="accorhead"
                                                                    >
                                                                        <Typography
                                                                            className={`w-100  ${classes.heading}`}
                                                                        >
                                                                            <span className="headname commonsize">
                                                                                {parkNameDisplay},{data.DeviceName},
                                                                                {data.Action}{" "}
                                                                            </span>{" "}
                                                                            <span
                                                                                className={`actionlogstatus ${data.Status == "IGNORED"
                                                                                        ? "ignoreColor"
                                                                                        : ""
                                                                                    }`}
                                                                            >
                                                                                Status : {data.Status}
                                                                            </span>
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className="d-block">
                                                                        <Table
                                                                            striped
                                                                            bordered
                                                                            hover
                                                                            size="sm"
                                                                            className="text-left"
                                                                        >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Park Name : {parkNameDisplay}</td>
                                                                                    <td>
                                                                                        Device Type : {data.Devicetype}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Device : {data.DeviceName}</td>
                                                                                    <td>Action : {data.Action}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Reason : {data.Reason}</td>
                                                                                    <td>Status : {data.Status}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    "NA"
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>ModifiedBy</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 pl-1">
                                            <div className="validity ruleContent">
                                                {ruledata.AuditID !== "" ? (
                                                    <div className="w-100">
                                                        <div className="commonsize">
                                                            {ruledata.ModifiedBy.FullName}
                                                        </div>
                                                        <hr className="my-2" />
                                                        <div className="triger commonsize mobilepy">
                                                            {ruledata.ModifiedBy.Email}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="w-100">
                                                        <div className="commonsize">NA</div>
                                                        <hr className="my-2" />
                                                        <div className="triger commonsize mobilepy">NA</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-4 col-sm-4 px-1">
                                            <div className="ruleheading">
                                                <div>Settings</div>
                                            </div>
                                        </div>
                                        <div className="col-8 col-sm-8 px-1">
                                            <div className="settings ruleContent">
                                                <div className="menubtn mobilemenubtn">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="success"
                                                            className="settingdropdown"
                                                        >
                                                            <MdSettings />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="actionicons">
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => Viewlog(ruledata.RuleID)}
                                                            >
                                                                <span className="actionbutton">Rule Logs</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => Auditlog(ruledata.RuleID)}
                                                            >
                                                                <span className="actionbutton">Audit Logs</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => viewrule(ruledata.RuleID)}
                                                            >
                                                                <span className="actionbutton"> View Rule</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => viewlogpage(ruledata._id)}
                                                            >
                                                                <span className="actionbutton"> View Log</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : loading ? (
                            <ClipLoader
                                color={color}
                                loading={loading}
                                css={override}
                                size={60}
                            />
                        ) : (
                            <div className="norecord">No Record Found</div>
                        )}
                    </div>
                    {getListdata?.length > 0 && pending === false ? (
                        <Tablepaginate
                            totalpage={totalRows}
                            tablerowvalue={functablerow}
                            currentPagevalue={currentPage}
                            rulecurrentpage={funcCurrentpage}
                            totalpagenum={getTotalrules}
                            pageleft="pageleft"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </section>
        </div>
    );
}
