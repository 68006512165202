import React, { useEffect, useRef, useState } from "react";
import Link from "@material-ui/core/Link";
// nodejs library that concatenates classes
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import { cloneDeep } from "lodash";
import Grid from "@material-ui/core/Grid";
import RichTextEditor from "react-rte";
import Paper from "@material-ui/core/Paper";
import { BiSolidXCircle } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { BiSolidCheckCircle } from "react-icons/bi";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import InputMask from "react-input-mask";
import notifyMe from "./styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GenerateEncryptedToken } from "../../views/Common";
import {
  notifyMe_,
  dataByEmailAddress_,
  dataByMobilePhone_,
  unmountnotifyMe,
  saveResponse_,
  notifyMeServiceThunk,
  notifyMeServicePostThunk,
  notifyMeByEmailServiceThunk,
  notifyMeMobilePhoneServiceThunk,
  notifyMeGetCourtsThunk,
  notifyCourts_,
} from "../../reducers/notifyMe";
import {
  sensors_,
  sensorsHasLoaded_,
  sensorServiceThunk,
} from "../../reducers/sensors";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import { css } from "@emotion/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import smartParkService from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const useStyles = makeStyles(styles);
const usenotifyMe = makeStyles(notifyMe);
const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));

const ModalDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      padding: "1px",
      maxHeight: "90vh",
    },
  },
}))(Dialog);
const _mockData = [
  {
    type: "CFDP",
    fields: [],
    fieldNames: [],
  },
  {
    type: "MAFC",
    fields: [],
    fieldNames: [],
  },
  {
    type: "CSPTC",
    fields: [],
    fieldNames: [],
  },
  {
    type: "MCP",
    fields: [],
    fieldNames: [],
  },
  {
    type: "MCPC",
    fields: [],
    fieldNames: [],
  },
  {
    type: "CCNP",
    fields: [],
    fieldNames: [],
  },
  {
    type: "SP",
    fields: [],
    fieldNames: [],
  },
];
export default function NotifyMe(props) {
  const classes = useStyles();
  const notify = usenotifyMe();
  const inputRef = useRef(null);
  const helperTestClasses = helperTextStyles();
  const dispatch = useDispatch();
  const [mockData, setMockData] = useState(cloneDeep(_mockData));
  const [fieldData, setFieldData] = useState(cloneDeep(mockData));
  const [mobilePhone, setMobilePhone] = useState();
  const [getNotifyUserID, setNotifyUserID] = useState();
  const [getMobileprev, setMobileprev] = useState(null);
  const [getEmailprev, setEmailprev] = useState(null);
  const [emailAddress, setEmailAddress] = useState();
  const [selectedType, setSelectedType] = useState(cloneDeep(mockData)[0].type);
  const [erremailaddress, seterremailaddress] = React.useState("");
  const [errphonenumber, seterrphonenumber] = React.useState("");
  const [errOTP, setErrOTP] = React.useState("");
  const [sendOTPStatus, setSendOTPStatus] = useState(false);
  const [emailUpdateotp, setEmailUpdateotp] = useState(false);
  const [sendMobileOTP, setsendMobileOTP] = useState(false);
  const [getResetField, setResetField] = useState(false);
  const [sendMobileOTPCheck, setsendMobileOTPCheck] = useState(false);
  const [sendMobileOTPCheckerror, setsendMobileOTPCheckerror] = useState(false);
  const [fieldsValidated, setFieldsValidated] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [emptyFieldError, setemptyFieldError] = useState("");
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [NotifyPopUp, setNotifyPopUp] = React.useState(false);
  const [emailOTP, setemailOTP] = React.useState("");
  const [existingUser, setExistingUser] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [emailverifiedDB, setEmailVerifiedDB] = React.useState(true);
  const [mobileverifiedDB, setMobileVerifiedDB] = React.useState(true);
  const [smsOTP, setSmsOTP] = React.useState("");
  const [changeSmscount, setChangeSmscount] = useState(0);
  const [changeEmailcount, setChangeEmailcount] = useState(0);
  const [updatesmsOTP, setUpdateSmsOTP] = React.useState("");
  const [getUpdateSMSEmailvalue, setUpdateSMSEmailvalue] = React.useState(null);
  const [getUpdateFieldChangeOtp, setUpdateFieldChangeOtp] =
    React.useState(null);
  const [updatesendEmailOTPStatus, setUpdateSendEmailOTPStatus] =
    useState(false);
  const [updatesendSMSOTPStatus, setUpdateSendSMSOTPStatus] = useState(false);
  const [emailOTPVerified, setEmailOTPVerified] = React.useState(false);
  const [smsOTPVerified, setSmsOTPVerified] = React.useState(false);
  const [cstCourts, setCstCourts] = React.useState(false);
  const [getVerify, setVerify] = React.useState(true);
  const [sendUpdateOtp, setSendUpdateOtp] = useState(false);
  const [checkUpdateOtp, setCheckUpdateOtp] = useState(false);
  const [checkSubscribe, setCheckSubscribe] = useState(false);
  const [MobileEmpty, setMobileEmpty] = useState(false);
  const [consentBox, setConsentBox] = useState(false);
  const consentHandler = (event) => {
    setsendMobileOTPCheckerror(false);
    setConsentBox(event.target.checked);
  };

  smartParkService.defaults.headers.common["x-access-token"] =
    GenerateEncryptedToken();
  const [openPopup, setOpenPopup] = React.useState(false);
  const checklengthoffieldssubscribed = (data) => {
    let result = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].fields && data[i].fieldNames) {
        result = true;
      }
    }
    setFieldsValidated(result);
    // let localCopyFieldData = data;
    // var result = false;
    // for (var i = 0; i < localCopyFieldData.length; i++) {
    //   if (
    //     localCopyFieldData[i].fields.length > 0 &&
    //     localCopyFieldData[i].fieldNames.length > 0
    //   ) {
    //     result = true;
    //   }
    // }
    // setFieldsValidated(result);
  };

  const saveUpdatenewnum = () => {
    setsendMobileOTP(false);
    setSendOTPStatus(false);
    setEmailUpdateotp(false);
  };

  const onchangeSMSOtp = async (e) => {
    if (e.target.value.trim().length <= 6) {
      setSmsOTP(e.target.value.trim());
    }
    if (e.target.value.trim().length == 6) {
      setSmsOTP(e.target.value.trim());
      await smartParkService
        .get(
          "api/park/verifysmsotp?mobile=" +
          mobilePhone.replace(/-/g, "") +
          "&otp=" +
          e.target.value.trim()
        )
        .then(async (res, err) => {
          if (res.data.status == 200) {
            const mes = (
              <div className="toastsuccess">
                <div className="toasticon">
                  <BiSolidCheckCircle />
                </div>
                <div className="toastmessage">
                  <b>Success</b>
                  <div>{res.data.message.toUpperCase()}</div>
                </div>
              </div>
            );
            toast.success(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "toastcontainer",
            });
            if (existingUser) {
              saveUpdatenewnum();
            } else {
              await saveNotifyuser();
            }
          } else {
            setErrOTP("Incorrect activation code. Please try again");
            setemailOTP("");
          }
        });
    }
  };

  const onchangeemailOtp = async (e) => {
    if (e.target.value.trim().length <= 6) {
      setemailOTP(e.target.value.trim());
    }
    if (e.target.value.trim().length == 6) {
      setemailOTP(e.target.value.trim());
      await smartParkService
        .get(
          "api/park/verifyemailotp?email=" +
          emailAddress +
          "&otp=" +
          e.target.value.trim()
        )
        .then(async (res, err) => {
          if (res.data.status == 200) {
            setSendOTPStatus(false);
            if (!sendMobileOTP && !existingUser) {
              await saveNotifyuser();
            }
            if (existingUser) {
              saveUpdatenewnum();
            }
          } else {
            setErrOTP("Incorrect activation code. Please try again");
            setemailOTP("");
          }
        });
    }
  };

  const handleParentDelete = (type) => {
    let localCopyFieldData = cloneDeep(fieldData);
    const _fieldData = localCopyFieldData.map((e) => {
      if (e.type === type) {
        e.fields = [];
        e.fieldNames = [];
      }
      return e;
    });
    setFieldData(_fieldData);
  };

  const handleDelete = (name, ids, type) => {
    let localCopyFieldData = cloneDeep(fieldData);
    const _fieldData =
      localCopyFieldData &&
      localCopyFieldData.map((e) => {
        if (e.type == type) {
          const _index = e.fields.indexOf(ids);
          if (_index >= 0) {
            e.fields.splice(_index, 1);
          }
          const _nameindex = e.fieldNames.indexOf(name);
          if (_nameindex >= 0) {
            e.fieldNames.splice(_nameindex, 1);
          }
        }
        return e;
      });
    setFieldData(_fieldData);
  };

  const handleCloseEvent = () => {
    setNotifyPopUp(!NotifyMe);
  };

  const handleFieldChange = (event) => {
    setFieldsValidated(false);
    const _value = event?.target?.value;
    const _name = event?.target?.name;
    // Check if "CSPTC" type exists in fieldData, if not, add it
    const syntaxCorrect = fieldData.find((obj) => obj.type === "CSPTC");
    if (!syntaxCorrect) {
      fieldData.push({
        type: "CSPTC",
        fields: [],
        fieldNames: [],
      });
    }
    let localCopyFieldData = cloneDeep(fieldData);
    const _fieldData = localCopyFieldData.map((e) => {
      if (selectedType === e.type) {
        const _index = e.fields.indexOf(_value.trim());
        const _nameindex = e.fieldNames.indexOf(_name.trim());
        if (_index >= 0 && _nameindex >= 0) {
          e.fields.splice(_index, 1);
          e.fieldNames.splice(_nameindex, 1);
          if (existingUser) {
            if (getUpdateSMSEmailvalue === null) {
              setResetField(true);
              setUpdateSMSEmailvalue(0);
              setUpdateFieldChangeOtp(1);
            } else {
              setResetField(false);
            }
          } else {
            setUpdateSMSEmailvalue(null);
            setUpdateFieldChangeOtp(null);
          }
        } else {
          e.fields.push(_value.trim());
          e.fieldNames.push(_name.trim());
          if (existingUser) {
            if (getUpdateSMSEmailvalue === null) {
              setResetField(true);
              setUpdateSMSEmailvalue(0);
              setUpdateFieldChangeOtp(1);
            } else {
              setResetField(false);
            }
          } else {
            setUpdateSMSEmailvalue(null);
            setUpdateFieldChangeOtp(null);
          }
        }
      }
      return e;
    });
    checklengthoffieldssubscribed(_fieldData);
    setFieldData(_fieldData);
    let noFieldSelected = true;
    _fieldData.forEach((data) => {
      if (data.fields.length >= 1) {
        noFieldSelected = false;
      }
    });
    if (!noFieldSelected && existingUser) {
      setFieldsValidated(true);
    }
    if (noFieldSelected && !existingUser) {
      setFieldsValidated(false);
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const isChecked = (_value) => {
    return (
      fieldData &&
      fieldData.find((e) => e.type == selectedType)?.fields?.indexOf(_value) >=
      0
    );
  };

  const saveUpdateNotify = (item) => {
    if (item === 0 && !getResetField) {
      // console.log("+++++++++++++sdfdsf==");
      if (mobilePhone != "" && mobilePhone.replace(/-/g, "").length == 10) {
        setLoader(true);
        smartParkService
          .get("api/park/verifyusermobile/" + mobilePhone.replace(/-/g, ""))
          .then(async (res, err) => {
            setsendMobileOTP(true);
            setLoader(false);
          });
      }
    }
    if (item === 1) {
      if (emailAddress != "" && validateEmailRegx()) {
        setLoader(true);
        smartParkService
          .get("api/park/verifyuseremail/" + emailAddress)
          .then(async (res, err) => {
            setSendOTPStatus(true);
            setEmailUpdateotp(true);
            setLoader(false);
          });
      } else {
        setEmailAddress("");
      }
    }
  };

  const saveNotify = async () => {
    if (validateonSave()) {
      if (!sendOTPStatus) {
        if (emailAddress != "" && validateEmailRegx()) {
          setLoader(true);
          await smartParkService
            .get("api/park/verifyuseremail/" + emailAddress)
            .then(async (res, err) => {
              await setSendOTPStatus(true);
              setLoader(false);
            });
        } else {
          await setEmailAddress("");
        }
        if (consentBox && mobilePhone !== "") {
          if (mobilePhone != "" && mobilePhone.replace(/-/g, "").length == 10) {
            setLoader(true);
            await smartParkService
              .get("api/park/verifyusermobile/" + mobilePhone.replace(/-/g, ""))
              .then(async (res, err) => {
                await setsendMobileOTP(true);
                setLoader(false);
              });
          }
        } else {
          setsendMobileOTPCheckerror(true);
        }
      }
      if (existingUser) {
        if (emailverifiedDB && mobileverifiedDB) {
          saveNotifyuser();
        } else if (
          !emailverifiedDB &&
          emailAddress != "" &&
          validateEmailRegx()
        ) {
          setLoader(true);
          await smartParkService
            .get("api/park/verifyuseremail/" + emailAddress)
            .then(async (res, err) => {
              await setSendOTPStatus(true);
              setLoader(false);
            });
        } else if (
          !mobileverifiedDB &&
          mobilePhone != "" &&
          mobilePhone.replace(/-/g, "").length == 10
        ) {
          setLoader(true);
          await smartParkService
            .get("api/park/verifyusermobile/" + mobilePhone.replace(/-/g, ""))
            .then(async (res, err) => {
              await setsendMobileOTP(true);
              setLoader(false);
            });
        } else {
          await saveNotifyuser();
        }
      }
    }
  };
  const validateonSave = () => {
    seterremailaddress("");
    seterrphonenumber("");
    setemptyFieldError("");

    if (!emailAddress && !mobilePhone) {
      setemptyFieldError(
        "Mobile Number/Email Address required for notifications."
      );
      return false;
    }

    if (mobilePhone && mobilePhone.replace(/-/g, "").trim().length !== 10) {
      seterrphonenumber("Invalid Mobile Number!");
      return false;
    }

    if (
      (!emailAddress.trim() && !mobilePhone) ||
      (emailAddress && !validateEmailRegx())
    ) {
      seterremailaddress("Invalid Email Address!");
      setEmailAddress("");
      return false;
    }

    return true;

    // if (emailAddress == "") {
    //   seterremailaddress("Please Enter Email Address");
    //   return false;
    // }
    // if (
    //   emailAddress != "" &&
    //   mobilePhone != "" &&
    //   mobilePhone.replace(/-/g, "").trim().length != 10
    // ) {
    //   seterrphonenumber("please enter 10 digit mobile number");
    //   return false;
    // }
    // if (mobilePhone == "" && sendMobileOTPCheck) {
    //   seterrphonenumber("please enter 10 digit mobile number");
    //   return false;
    // }
    // if (
    //   !sendMobileOTPCheck &&
    //   mobilePhone != "" &&
    //   getMobileprev !== mobilePhone
    // ) {
    //   setsendMobileOTPCheckerror(true);
    //   return false;
    // }
    // if (
    //   (emailAddress != "" && validateEmailRegx()) ||
    //   (mobilePhone != "" &&
    //     mobilePhone.replace(/-/g, "").trim().length == 10 &&
    //     sendMobileOTPCheck)
    // ) {
    //   return true;
    // } else {
    //   if (emailAddress != "" && !validateEmailRegx()) {
    //     seterremailaddress("please Enter Valid email Address");
    //   }
    //   return false;
    // }
  };

  const validateEmailRegx = () => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (regexEmail.test(emailAddress)) {
      return true;
    } else {
      return false;
    }
  };
  const saveNotifyuser = () => {
    const _saveObj = {};
    var usermail = validateEmailRegx() ? emailAddress : "";
    var userphone =
      mobilePhone.replace(/-/g, "").trim().length == 10 ? mobilePhone : "";
    _saveObj.Email = usermail;
    _saveObj.MobilePhone = userphone;
    var mcpfieldvar = fieldData;
    const deepClone = (e) => JSON.parse(JSON.stringify(e));
    const mcpData = deepClone(mcpfieldvar[3]);
    const mcpcData = deepClone(mcpfieldvar[3]);
    const MCPCindex = [],
      MCPIndex = [];
    const fields = mcpData.fields,
      fieldNames = mcpData.fieldNames;
    mcpData.fieldNames.forEach((e, i) => {
      if (e.toLowerCase().includes("field")) {
        MCPIndex.push(i);
      } else {
        MCPCindex.push(i);
      }
    });
    mcpData.fieldNames = MCPIndex.map((i) => fieldNames[i]);
    mcpData.fields = MCPIndex.map((i) => fields[i]);
    mcpcData.fieldNames = MCPCindex.map((i) => fieldNames[i]);
    mcpcData.fields = MCPCindex.map((i) => fields[i]);
    mcpcData.type = "MCPC";
    mcpfieldvar[3] = mcpData;
    mcpfieldvar[4] = mcpcData;
    _saveObj.Fieldssubscribed = mcpfieldvar;

    dispatch(notifyMeServicePostThunk(_saveObj));
    setsendMobileOTPCheck(false);
    setsendMobileOTPCheckerror(false);
    cancelNotify();
  };

  const cancelNotify = () => {
    props.handleCloseEvent();
    setOpenPopup(!openPopup);
    setemptyFieldError();
    setMobilePhone("");
    setsendMobileOTPCheck(false);
    setsendMobileOTPCheckerror(false);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendSMSOTPStatus(false);
    setUpdateSMSEmailvalue(null);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendSMSOTPStatus(false);
    setsendMobileOTPCheckerror(false);
    setUpdateFieldChangeOtp(null);
    setUpdateSmsOTP("");
    setChangeEmailcount(0);
    setChangeSmscount(0);
    setVerify(true);
    setErrOTP("");
    setNotifyUserID("");
    setMobileprev(null);
  };

  const cancelUpdateNotify = () => {
    setMobilePhone("");
    setEmailAddress("");
    setFieldData([]);
    setsendMobileOTPCheck(false);
    setsendMobileOTPCheckerror(false);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendSMSOTPStatus(false);
    setUpdateSMSEmailvalue(null);
    setUpdateSendEmailOTPStatus(false);
    setUpdateSendSMSOTPStatus(false);
    setsendMobileOTPCheckerror(false);
    setFieldsValidated(false);
    setCheckUpdateOtp(false);
    setResetField(false);
    setUpdateFieldChangeOtp(null);
    setUpdateSmsOTP("");
    setSendUpdateOtp(false);
    setVerify(true);
    setChangeEmailcount(0);
    setChangeSmscount(0);
    setErrOTP("");
    setNotifyUserID("");
    setMobileprev(null);
  };

  const getNotifyMeByMobilePhone = (value) => {
    if (emailAddress) {
      return;
    }
    // console.log('getnotifymebymobilephone',mobilePhone,emailAddress)
    setsendMobileOTPCheckerror(false);
    setResetField(false);
    setUpdateSMSEmailvalue(null);
    setUpdateFieldChangeOtp(null);
    if (value && value.replace(/-/g, "").length == 10) {
      if (existingUser) {
        setSendUpdateOtp(true);
      } else {
        setSendUpdateOtp(false);
      }
      smartParkService
        .get("api/park/notifyusersmobile?phone=" + value)
        .then(async (res, err) => {
          if (
            res.data.status === 200 &&
            res.data.data &&
            res.data.data.Fieldssubscribed &&
            res.data.data.Fieldssubscribed.length
          ) {
            setEmailVerifiedDB(
              res.data.data.Email && res.data.data.Email !== "" ? true : false
            );
            setMobileVerifiedDB(
              res.data.data.MobilePhone && res.data.data.MobilePhone !== ""
                ? true
                : false
            );
            setExistingUser(true);
            setSendOTPStatus(true);
            setCheckSubscribe(res.data.data.IsActive);
            setNotifyUserID(res.data.data.UserNotifyID);
            setMobileprev(res.data.data.MobilePhone);
            setMobilePhone(res.data.data.MobilePhone);
            setEmailAddress(res.data.data.Email || emailAddress);
            setFieldData(res.data.data.Fieldssubscribed);
            setFieldsValidated(true);
            setChangeSmscount(0);
            setUpdateSMSEmailvalue(1);
          } else {
            setChangeSmscount(1);
            setUpdateSMSEmailvalue(0);
          }
        });
    } else {
      setUpdateSMSEmailvalue(0);
    }
  };

  const getNotifyMeByEmailAddress = (value) => {
    if (mobilePhone) {
      return;
    }
    setResetField(false);
    setUpdateSMSEmailvalue(null);
    setUpdateFieldChangeOtp(null);
    if (value) {
      if (existingUser) {
        setSendUpdateOtp(true);
      } else {
        setSendUpdateOtp(false);
      }
      smartParkService
        .get("api/park/notifyusersemail?email=" + value)
        .then((res, err) => {
          if (
            res.data.status === 200 &&
            res.data.data &&
            res.data.data.Fieldssubscribed &&
            res.data.data.Fieldssubscribed.length
          ) {
            setEmailVerifiedDB(
              res.data.data.Email && res.data.data.Email != "" ? true : false
            );
            setMobileVerifiedDB(
              res.data.data.MobilePhone && res.data.data.MobilePhone != ""
                ? true
                : false
            );
            setExistingUser(true);
            setSendOTPStatus(true);
            setCheckSubscribe(res.data.data.IsActive);
            setNotifyUserID(res.data.data.UserNotifyID);
            setMobileprev(res.data.data.MobilePhone);
            setEmailprev(res.data.data.MobilePhone);
            setMobilePhone(res.data.data.MobilePhone || mobilePhone);
            setEmailAddress(res.data.data.Email);
            setFieldData(res.data.data.Fieldssubscribed);
            setFieldsValidated(true);
            setChangeEmailcount(0);
            setUpdateSMSEmailvalue(null);
          } else {
            setChangeEmailcount(1);
            setUpdateSMSEmailvalue(1);
          }
        });
    } else {
      setUpdateSMSEmailvalue(1);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => { };
  const handleClose = () => {
    setOpen(false);
  };
  const [sensordata, setsensordata] = useState([]);
  const [gatedata, setgatedata] = useState([]);
  const [getmcpdata, setmcpdata] = useState([]);
  const [getmcpcourtdata, setmcpcourtdata] = useState([]);
  const [fieldResData, setfieldResData] = useState([]);
  const [shilohFieldsData, setshilohFieldsData] = useState([]);
  const [crabtreeFieldsData, setcrabtreeFieldsData] = useState([]);
  const [courtData, setCourtData] = useState([]);
  let mafcCourts = [];

  useEffect(() => {
    // Set the initial value and cursor position at the beginning after the component mounts
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, 0);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(async () => {
    setsendMobileOTP(false);
    setSmsOTP("");
    setMobilePhone("");
    setEmailAddress("");
    setFieldData(mockData);
    setSendOTPStatus(false);
    setemailOTP("");
    setErrOTP("");
    seterremailaddress("");
    seterrphonenumber("");
    setFieldsValidated(false);
    setExistingUser(false);
    smartParkService.get("/api/citizen/sensordata").then(async (res, err) => {
      if (res.data.status == 200) {
        await setsensordata(res.data.data);
      }
    });
    // smartParkService.get("/api/citizen/device").then(async (res, err) => {
    //   if (res.data.status == 200) {
    //     await setgatedata(res.data.data);
    //   }
    // });
    smartParkService.get("/api/citizen/fielddata2").then(async (res, err) => {
      if (res.data.status == 200) {
        await setmcpdata(res.data.data);
      }
    });
    smartParkService.get("api/mcpcourts").then(async (res, err) => {
      if (res.data.status == 200) {
        await setmcpcourtdata(res.data.data);
      }
    });
    smartParkService.get("/api/citizen/fielddata3").then(async (res, err) => {
      if (res.data.status == 200) {
        await setfieldResData(res.data.data);
      }
    });
    smartParkService.get("/api/citizen/fielddata4").then(async (res, err) => {
      if (res.data.status == 200) {
        await setshilohFieldsData(res.data.data);
      }
    });
    smartParkService.get("/api/citizen/fielddata5").then(async (res, err) => {
      if (res.data.status == 200) {
        await setcrabtreeFieldsData(res.data.data);
      }
    });

    await smartParkService
      .get("/api/courts")
      .then(async (res, err) => {
        if (res.data.status == 200) {
          await res.data.data.forEach((data) => {
            mafcCourts.push(data);
          });
        }
      })
      .then(
        await smartParkService
          .get("api/courts/list/91474e36-76c5-4d59-a8da-2998ab14a4bf")
          .then(async (res, err) => {
            if (res.data.status == 200) {
              await res.data.data.forEach((court) => {
                mafcCourts.push({
                  FieldGroup: court.FieldGroup,
                  court_api_id: court.courtid,
                  _id: court.courtid,
                  court_name: court.courtname + `(${court.FieldGroup})`,
                });
              });
            }
          })
      )
      .finally(setCourtData(mafcCourts));

    smartParkService.get("/api/churchstreetcourts").then(async (res, err) => {
      if (res.data.status == 200) {
        await setCstCourts(res.data.data);
      }
    });
  }, [openPopup]);

  // const checkmobile = (elem) => {
  //       let mobilecheck = elem.target.checked;
  //       console.log('mobile',mobilecheck)
  //   if (mobilecheck === true) {
  //     setsendMobileOTPCheckerror(false);
  //   }
  //   setsendMobileOTPCheck(mobilecheck);
  // };

  const groupedCourts = {};
  // Group the courts based on the "FieldGroup" property
  getmcpcourtdata?.forEach((court) => {
    const fieldGroup = court.FieldGroup;
    if (!groupedCourts[fieldGroup]) {
      groupedCourts[fieldGroup] = [];
    }
    groupedCourts[fieldGroup].push(court);
  });

  function funcUnsubscribe(subscribeemail) {
    let email = subscribeemail;
    smartParkService
      .get(`/api/park/unsubscribe/notification/${email}`)
      .then(async (res, err) => {
        try {
          if (res.status == 200) {
            const mes = (
              <div className="toastsuccess">
                <div className="toasticon">
                  <BiSolidCheckCircle />
                </div>
                <div className="toastmessage">
                  <b>Success</b>
                  <div>{res.message}</div>
                </div>
              </div>
            );
            toast.success(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "toastcontainer",
            });
          } else {
            const mes = (
              <div className="toastsuccess">
                <div className="erroricon">
                  <BiSolidXCircle />
                </div>
                <div className="toastmessage">
                  <b>Error</b>
                  <div>{res.message}</div>
                </div>
              </div>
            );
            toast.error(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "errorcontainer",
            });
          }
          cancelNotify();
        } catch (err) {
          console.log("Unsubscribe Error" + err);
        }
      });
  }

  const onchangeUpdateSMSOtp = (e) => {
    if (e.target.value !== null && e.target.value !== "") {
      setUpdateSmsOTP(e.target.value.trim());
    } else {
      setUpdateSmsOTP("");
    }
  };

  const validateUpdateEmailRegx = (email) => {
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (regexEmail.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  function isValidEmail(email) {
    // Define a regular expression pattern for a basic email format
    const pattern = /^\S+@\S+\.\S+$/;
    // Use the test method to check if the email matches the pattern
    return pattern.test(email);
  }

  const updatevalidation = (mobile, email) => {
    seterremailaddress("");
    seterrphonenumber("");
    if (!email && !mobile) {
      setemptyFieldError("Mobile Number/Email Address required to update.");
      return false;
    }

    if (
      !sendMobileOTPCheck &&
      mobile != "" &&
      getMobileprev !== mobile &&
      getEmailprev !== emailAddress
    ) {
      setsendMobileOTPCheckerror(true);
      return false;
    }
    return true;
    // if (email == "") {
    //   seterremailaddress("Please Enter Email Address");
    //   return false;
    // }
    // if (
    //   email != "" &&
    //   mobile != "" &&
    //   mobile.replace(/-/g, "").trim().length != 10
    // ) {
    //   seterrphonenumber("please enter 10 digit mobile number");
    //   return false;
    // }

    // if (!sendMobileOTPCheck && mobile != "" && getMobileprev !== mobile) {
    //   setsendMobileOTPCheckerror(true);
    //   return false;
    // }
    // if (
    //   (email != "" && isValidEmail(email)) ||
    //   (mobile != "" &&
    //     mobile.replace(/-/g, "").trim().length == 10 &&
    //     sendMobileOTPCheck)
    // ) {
    //   return true;
    // } else {
    //   if (email != "" && !isValidEmail(email)) {
    //     seterremailaddress("please Enter Valid email Address");
    //   }
    //   return false;
    // }
    // return true;
  };
  // send update otp code ============
  const UpdateCodeNotify = async () => {
    console.log("update code notify", consentBox, sendMobileOTPCheckerror);

    setCheckUpdateOtp(false);
    // if (getMobileprev === "" && mobilePhone) {
    //   setsendMobileOTPCheck(true)
    // }
    if (mobilePhone) {
      setsendMobileOTPCheck(true);
    }
    if (!consentBox && !mobileverifiedDB) {
      setsendMobileOTPCheckerror(true);
      return;
    }
    if (updatevalidation(mobilePhone, emailAddress)) {
      var restoken = GenerateEncryptedToken();
      let updatetype;
      if (getUpdateSMSEmailvalue !== null) {
        updatetype = getUpdateSMSEmailvalue;
        var Dataobject = {
          type: updatetype, // 0 SMS 1 Email
          mobile: updatetype === 0 ? null : mobilePhone, // mobile number otherwise null
          email: updatetype === 1 ? null : emailAddress, // email address otherwise null
        };
        if (
          (mobilePhone !== "" && updatetype === 0) ||
          (mobilePhone === "" && updatetype === 0) ||
          (getUpdateFieldChangeOtp !== null && getUpdateFieldChangeOtp > 0)
        ) {
          setsendMobileOTPCheckerror(false);
          smartParkService
            .post("api/park/update/confirmation", Dataobject, {
              headers: {
                "x-access-token": restoken,
              },
            })
            .then((res) => {
              if (res.data.status === 200) {
                if (updatetype === 0) {
                  // console.log("sgsdgd====");
                  saveUpdateNotify(updatetype);
                  setUpdateSendEmailOTPStatus(true);
                  setCheckUpdateOtp(true);
                }
              }
            });
        } else {
          setsendMobileOTPCheckerror(true);
        }
        if (mobilePhone !== "" && emailAddress !== null && updatetype === 1) {
          smartParkService
            .post("api/park/update/confirmation", Dataobject, {
              headers: {
                "x-access-token": restoken,
              },
            })
            .then((res) => {
              if (res.data.status === 200) {
                if (updatetype === 1) {
                  // console.log("dsfdsfd==0" + updatetype);
                  saveUpdateNotify(updatetype);
                  setUpdateSendSMSOTPStatus(true);
                  setCheckUpdateOtp(true);
                }
              }
            });
        }
      }
    }
  };
  // send update otp code ============

  // verify update otp code ================
  const UpdateverifyCode = () => {
    var restoken = GenerateEncryptedToken();
    let updatetype;
    if (getUpdateSMSEmailvalue !== null) {
      updatetype = getUpdateSMSEmailvalue;
      var Dataobject = {
        type: updatetype === 0 ? 1 : 0, // 0 SMS 1 Email
        mobile: updatetype === 0 ? null : mobilePhone, // mobile number otherwise null
        email: updatetype === 1 ? null : emailAddress, // email address otherwise null
        otp: updatesmsOTP !== null ? updatesmsOTP : "",
      };
      smartParkService
        .post("api/park/update/verify", Dataobject, {
          headers: {
            "x-access-token": restoken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setUpdateSendEmailOTPStatus(false);
            setUpdateSendEmailOTPStatus(false);
            setUpdateSendSMSOTPStatus(false);
            setUpdateSMSEmailvalue(null);
            setCheckUpdateOtp(false);
            setSendUpdateOtp(false);
            setVerify(false);
          } else {
            setErrOTP(
              "Confirmation code could not be verified. Please try again."
            );
          }
        });
    }
  };
  // verify update otp code ================

  // Update the data for subscribe user
  const UpdateNotify = () => {
    if (updatevalidation(mobilePhone, emailAddress)) {
      var restoken = GenerateEncryptedToken();
      var Dataobject = {
        MobilePhone: mobilePhone,
        Email: emailAddress,
        Fieldssubscribed: fieldData,
        UserNotifyID: getNotifyUserID,
      };
      smartParkService
        .post("api/park/update/notification", Dataobject, {
          headers: {
            "x-access-token": restoken,
          },
        })
        .then((res) => {
          if (res.data.status == 500) {
            cancelUpdateNotify();
            const mes = (
              <div className="toastsuccess">
                <div className="erroricon">
                  <BiSolidXCircle />
                </div>
                <div className="toastmessage">
                  <b>Error</b>
                  <div>{res.data.message}</div>
                </div>
              </div>
            );
            toast.error(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "errorcontainer",
            });
          } else if (res.data.status == 200) {
            cancelNotify();
            cancelUpdateNotify();
            const mes = (
              <div className="toastsuccess">
                <div className="toasticon">
                  <BiSolidCheckCircle />
                </div>
                <div className="toastmessage">
                  <b>Success</b>
                  <div>{res.data.message}</div>
                </div>
              </div>
            );
            toast.success(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "toastcontainer",
            });
          } else {
            const mes = (
              <div className="toastsuccess">
                <div className="erroricon">
                  <BiSolidXCircle />
                </div>
                <div className="toastmessage">
                  <b>Error</b>
                  <div>{res.data.message}</div>
                </div>
              </div>
            );
            toast.error(mes, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "errorcontainer",
            });
            setErrOTP(res.data.message);
          }
        });
    }
  };
  // Update the data for subscribe user

  return (
    <div>
      <ToastContainer />
      <ModalDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.NotifyPopUp}
        aria-labelledby="field-model"
      >
        <DialogTitle id="field-model-title">
          <strong> Notify Me</strong>
          <div style={{ color: "red" }}>{emptyFieldError}</div>
        </DialogTitle>
        <DialogContent className={classes.modalHeight}>
          <DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {/* <TextField
                  variant="outlined"
                  size="small"
                  id="mobilenumber"
                  name="mobilenumber"
                  label="Mobile Number"
                  fullWidth
                  value={mobilePhone}
                  onBlur={(e) => getNotifyMeByMobilePhone(e.target.value)}
                  onChange={(e) => setMobilePhone(e.target.value)}
                /> */}
                <InputMask
                  mask="999-999-9999"
                  fullWidth
                  value={mobilePhone}
                  onBlur={(e) => getNotifyMeByMobilePhone(e.target.value)}
                  onChange={(e) => {
                    setChangeSmscount(2);
                    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-digit characters
                    if (existingUser) {
                      if (e.target.value === getMobileprev) {
                        setUpdateSMSEmailvalue(null);
                      } else {
                        setUpdateSMSEmailvalue(0);
                      }
                    }
                    setMobilePhone(inputValue ? inputValue : "");
                    if (inputValue !== "" && inputValue !== null) {
                      setMobileEmpty(true);
                    }
                  }}
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="phone"
                      label="Mobile Number ( Optional )"
                      inputRef={inputRef}
                    />
                  )}
                </InputMask>
                <div style={{ color: "red" }}>{errphonenumber}</div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="emailadresss"
                  name="emailadresss"
                  label="Email Adresss"
                  fullWidth
                  value={emailAddress}
                  onBlur={(e) => getNotifyMeByEmailAddress(e.target.value)}
                  onChange={(e) => {
                    setChangeEmailcount(2);
                    if (existingUser) {
                      if (e.target.value === getEmailprev) {
                        setUpdateSMSEmailvalue(null);
                      } else {
                        setUpdateSMSEmailvalue(1);
                      }
                    }
                    setEmailAddress(e.target.value);
                    if (e.target.value !== "" && e.target.value !== null) {
                      setMobileEmpty(true);
                    }
                  }}
                />
                <div style={{ color: "red" }}>{erremailaddress}</div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.w100p}
                >
                  <InputLabel id="parkSelect">{selectedType}</InputLabel>
                  <Select
                    labelId="parkSelect"
                    fullWidth
                    value={selectedType}
                    label="Facility Name"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value="CFDP">Cedar Fork District Park </MenuItem>
                    <MenuItem value="MAFC">MAFC Tennis Courts </MenuItem>
                    <MenuItem value="CSPTC">
                      Church Street Park Tennis Court(s)
                    </MenuItem>
                    <MenuItem value="MCP">Morrisville Community Park</MenuItem>
                    <MenuItem value="SP">Shiloh Park</MenuItem>
                    <MenuItem value="CCNP">Crabtree Creek Nature Park</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel>
                    <strong>Choose fields/Courts</strong>
                  </FormLabel>
                  <FormGroup row>
                    {selectedType == "CFDP"
                      ? fieldResData?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.id)}
                              value={e.id}
                              onChange={handleFieldChange}
                              name={e.FieldName}
                            />
                          }
                          label={e.FieldName}
                        />
                      ))
                      : ""}
                    {selectedType == "SP"
                      ? shilohFieldsData?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.id)}
                              value={e.id}
                              onChange={handleFieldChange}
                              name={e.FieldName}
                            />
                          }
                          label={e.FieldName}
                        />
                      ))
                      : ""}
                    {selectedType == "CCNP"
                      ? crabtreeFieldsData?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.id)}
                              value={e.id}
                              onChange={handleFieldChange}
                              name={e.FieldName}
                            />
                          }
                          label={e.FieldName}
                        />
                      ))
                      : ""}

                    {selectedType == "MCP"
                      ? getmcpdata?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.id)}
                              value={e.id}
                              onChange={handleFieldChange}
                              name={e.FieldName}
                            />
                          }
                          label={e.FieldName}
                        />
                      ))
                      : ""}

                    {/* {selectedType == "MCP"
                      ? getmcpcourtdata?.map((e, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                color="primary"
                                checked={isChecked(e.court_api_id.trim())}
                                value={e.court_api_id.trim()}
                                onChange={handleFieldChange}
                                name={e.court_name.trim()}
                              />
                            }

                            label={e.court_name}
                          />

                        ))
                      : ""} */}
                    {selectedType === "MCP" && (
                      <>
                        {Object.keys(groupedCourts)?.map((fieldGroup) => (
                          <div key={fieldGroup}>
                            {/* <h2>{fieldGroup}</h2> */}
                            {groupedCourts[fieldGroup].map((court, index) => (
                              <FormControlLabel
                                key={court.court_api_id}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isChecked(
                                      court.court_api_id.trim()
                                    )}
                                    value={court.court_api_id.trim()}
                                    onChange={handleFieldChange}
                                    name={court.court_name.trim()}
                                  />
                                }
                                label={court.court_name}
                              />
                            ))}
                          </div>
                        ))}
                      </>
                    )}
                    {/* {selectedType == "CFDP"
                      ? gatedata?.map((e, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                color="primary"
                                checked={isChecked(e.DeviceCollectionID)}
                                value={e.DeviceCollectionID}
                                onChange={handleFieldChange}
                                name={e.devicename}
                              />
                            }
                            label={e.devicename}
                          />
                        ))
                      : ""} */}

                    {selectedType == "MAFC"
                      ? courtData?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.court_api_id.trim())}
                              value={e.court_api_id.trim()}
                              onChange={handleFieldChange}
                              name={e.court_name.trim()}
                            />
                          }
                          label={e.court_name}
                        />
                      ))
                      : ""}
                    {selectedType == "CSPTC"
                      ? cstCourts?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isChecked(e.court_api_id.trim())}
                              value={e.court_api_id.trim()}
                              onChange={handleFieldChange}
                              name={e.court_name.trim()}
                            />
                          }
                          label={e.court_name}
                        />
                      ))
                      : ""}
                    <Grid></Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
              {fieldsValidated ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <p className={notify.subcribed}>
                      <strong>Subscribed Summary</strong>
                    </p>
                    <ul
                      className={notify.subcribedol}
                      style={{ listStyle: "none" }}
                    >
                      {fieldData &&
                        fieldData.map((e) => (
                          <>
                            {e.fields.length &&
                              (e.type === "CFDP" ||
                                e.type === "MAFC" ||
                                e.type === "MCP" ||
                                e.type === "CSPTC" ||
                                e.type === "CCNP" ||
                                e.type === "SP") ? (
                              <li className={notify.subcribedli}>
                                <Chip
                                  label={
                                    e.type == "CFDP"
                                      ? "Cedar Fork District Park"
                                      : e.type === "MAFC"
                                        ? "MAFC Tennis Courts"
                                        : e.type === "CSPTC"
                                          ? "Church Street Tennis Courts"
                                          : e.type === "MCP"
                                            ? "Morrisville Community Park"
                                            : e.type === "CCNP"
                                              ? "Crabtree Creek Natural Park"
                                              : e.type === "SP"
                                                ? "Shiloh Park"
                                                : ""
                                  }
                                  onDelete={() => handleParentDelete(e.type)}
                                />
                                <ul>
                                  {e.fieldNames.map((item, idx) => (
                                    <li key={item}>
                                      <Chip
                                        label={item}
                                        id={item}
                                        key={item}
                                        onDelete={() =>
                                          handleDelete(
                                            item,
                                            e.fields[idx],
                                            e.type
                                          )
                                        }
                                      />
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : null}{" "}
                          </>
                        ))}
                    </ul>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </DialogContentText>
          {loader ? (
            <CircularProgress className={classes.circularLoaderAtCenter} />
          ) : (
            ""
          )}
          {(existingUser &&
            getMobileprev !== "" &&
            getMobileprev !== null &&
            getMobileprev !== mobilePhone &&
            mobilePhone !== "") ||
            (mobilePhone &&
              getMobileprev !== mobilePhone &&
              mobilePhone.replace(/-/g, "").trim().length === 10) ? (
            <Grid item xs={12} sm={12}>
              <div className="checknotifynumber">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    className={`${sendMobileOTPCheck ? "checkcolor" : "uncheckcolor"
                      } `}
                    type="checkbox"
                    label="I consent to receive a verification code and notifications on my mobile number."
                    onChange={consentHandler}
                  />
                </Form.Group>
                {sendMobileOTPCheckerror ? (
                  <div style={{ color: "red" }}>
                    Consent is required for notifications.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          ) : (
            ""
          )}
          {/* {existingUser && checkSubscribe ? (
            <Grid item xs={12} sm={12}>
              <div className="checknotifynumber checkunsubscribe">
                <span>
                  {getMobileprev !== "" &&
                    getMobileprev !== null &&
                    getMobileprev === mobilePhone
                    ? "You have already provided consent to receive SMS notifications."
                    : "To unsubscribe, click on the following link : "}
                </span>
                {getMobileprev !== null && getMobileprev === mobilePhone && !mobilePhone ? (
                  <button
                    className="btn px-1"
                    onClick={() => funcUnsubscribe(emailAddress)}
                  >
                    Unsubscribe
                  </button>
                ) : (
                  ""
                  // <button
                  //   className="btn px-1"
                  //   onClick={() => funcUnsubscribe(emailAddress)}
                  // >
                  //   unsubscribe
                  // </button>
                )
                }
              </div>
            </Grid>
          ) : (
            ""
          )} */}
        </DialogContent>

        <DialogActions>
          {(sendOTPStatus && !existingUser) ||
            (sendOTPStatus && existingUser && emailUpdateotp) ? (
            <>
              <Grid item xs={12} sm={12}>
                <span style={{ color: "red" }}>
                  Enter the activation code received on your registered email
                </span>
                <TextField
                  variant="outlined"
                  size="small"
                  id="Registrationcode"
                  name="Registrationcode"
                  label="Enter the activation code received on your registered email"
                  fullWidth
                  className="mt-2"
                  value={emailOTP}
                  onChange={(e) => onchangeemailOtp(e)}
                />
                <div style={{ color: "red" }}>{errOTP}</div>
              </Grid>
            </>
          ) : (
            ""
          )}
          {(!sendOTPStatus && !existingUser && sendMobileOTP) ||
            (existingUser && sendMobileOTP) ? (
            <>
              <Grid item xs={12} sm={12}>
                <span style={{ color: "red" }}>
                  Enter the activation code received on your registered Mobile
                </span>
                <TextField
                  variant="outlined"
                  size="small"
                  id="Registrationcode"
                  name="Registrationcode"
                  label="Enter the activation code received on your registered Mobile"
                  fullWidth
                  className="mt-2"
                  value={smsOTP}
                  onChange={(e) => onchangeSMSOtp(e)}
                />
                <div style={{ color: "red" }}>{errOTP}</div>
              </Grid>
            </>
          ) : (
            ""
          )}
          {existingUser && updatesendSMSOTPStatus && !emailUpdateotp ? (
            <>
              <Grid item xs={12} sm={12}>
                <span style={{ color: "red" }}>
                  Enter the update code received on your registered Mobile
                </span>
                <TextField
                  variant="outlined"
                  size="small"
                  id="Registrationcode"
                  name="Registrationcode"
                  label="Enter the update code received on your registered Mobile"
                  fullWidth
                  className="mt-2"
                  value={updatesmsOTP}
                  onChange={(e) => onchangeUpdateSMSOtp(e)}
                />
                <div style={{ color: "red" }}>{errOTP}</div>
              </Grid>
            </>
          ) : (
            ""
          )}
          {existingUser && updatesendEmailOTPStatus && !sendMobileOTP ? (
            <>
              <Grid item xs={12} sm={12}>
                <span style={{ color: "red" }}>
                  Enter the update code received on your registered email
                </span>
                <TextField
                  variant="outlined"
                  size="small"
                  id="Registrationcode"
                  name="Registrationcode"
                  label="Enter the update code received on your registered email"
                  fullWidth
                  className="mt-2 mr-2"
                  value={updatesmsOTP}
                  onChange={(e) => onchangeUpdateSMSOtp(e)}
                />
                <div style={{ color: "red" }}>{errOTP}</div>
              </Grid>
            </>
          ) : (
            ""
          )}
          {!existingUser ? (
            <Button
              color="primary"
              variant="contained"
              disabled={!fieldsValidated}
              className={classes.btnRound}
              onClick={saveNotify}
            >
              {(sendOTPStatus && !existingUser) ||
                (sendMobileOTP && !existingUser)
                ? "Verify"
                : "Save"}
            </Button>
          ) : (
            ""
          )}

          {existingUser && !checkUpdateOtp ? (
            <Button
              color="primary"
              variant="contained"
              disabled={!fieldsValidated}
              className={classes.btnRound}
              onClick={
                getUpdateSMSEmailvalue !== null
                  ? UpdateCodeNotify
                  : UpdateNotify
              }
            >
              {existingUser &&
                // MobileEmpty &&
                // sendUpdateOtp &&
                getUpdateSMSEmailvalue !== null
                ? //    ||
                // (existingUser &&
                //   !MobileEmpty &&
                //   !sendUpdateOtp &&
                //   getUpdateSMSEmailvalue !== null)
                "Save Update"
                : "Update"}
            </Button>
          ) : (
            ""
          )}
          {existingUser && checkUpdateOtp ? (
            <Button
              color="primary"
              variant="contained"
              disabled={!fieldsValidated}
              className={classes.btnRound}
              onClick={
                checkUpdateOtp && getUpdateSMSEmailvalue !== null
                  ? UpdateverifyCode
                  : UpdateNotify
              }
            >
              {getUpdateSMSEmailvalue !== null && checkUpdateOtp && existingUser
                ? "Verify Update"
                : "Update"}
            </Button>
          ) : (
            ""
          )}
          <Button
            color="default"
            round
            className={classes.btnRound}
            onClick={cancelNotify}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </div>
  );
}

//new code starts here
// import React, { useEffect, useRef, useState } from "react";
// // nodejs library that concatenates classes
// import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
// import "../../assets/scss/App.scss";
// import styles from "../../components/components.js";
// import InputMask from "react-input-mask";
// import notifyMe from "./styles";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormLabel from "@material-ui/core/FormLabel";
// import { GenerateEncryptedToken } from "../../views/Common";
// import FormControl from "@material-ui/core/FormControl";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Chip from "@material-ui/core/Chip";
// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { withStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import smartParkService from "../../axios";
// import { Bounce, ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// const useStyles = makeStyles(styles);
// const usenotifyMe = makeStyles(notifyMe);
// const ModalDialog = withStyles((theme) => ({
//   root: {
//     "& .MuiDialog-paper": {
//       padding: "1px",
//       maxHeight: "90vh",
//     },
//   },
// }))(Dialog);
// export default function NotifyMe(props) {
//   const classes = useStyles();
//   const notify = usenotifyMe();
//   const inputRef = useRef(null);
//   const [inputFields, setInputFields] = useState({
//     mobile: {
//       value: "",
//       error: "",
//     },
//     email: {
//       value: "",
//       error: "",
//     },
//   });
//   const [globalError, setGlobalError] = useState("");
//   const [selectedType, setSelectedType] = useState("");
//   const [fullWidth, setFullWidth] = React.useState(true);
//   const [maxWidth, setMaxWidth] = React.useState("sm");
//   const [facilitiesList, setFacilitiesList] = useState({});
//   const [fetchedUserDetails, setfetchedUserDetails] = useState({
//     UserNotifyID: null,
//     MobilePhone: null,
//     Email: null,
//     Fieldssubscribed: [],
//   });
//   const [userDetailsFetchField, setUserDetailsFetchField] = useState("");
//   const [fetchedSecondaryFieldValue, setFetchedSecondaryFieldValue] =
//     useState("");
//   const [otpField, setOtpField] = useState({
//     show: false,
//     error: "",
//     fieldValue: null,
//     fieldLabel: "",
//   });
//   const [formState, setFormState] = useState("submit");
//   const [blockInputField, setBlockInputField] = useState(false);
//   const [consentField, setConsentField] = useState({
//     name: "mobileNotificationConsentField",
//     show: false,
//     value: "",
//     errorMessage: "",
//     label:
//       "I consent to receive an activation code and notifications on my mobile number.",
//   });

//   const renderToasts = ({ message, type }) => {
//     toast(message, {
//       position: toast.POSITION.TOP_CENTER,
//       transition: Bounce,
//     });
//   };
//   const closeModal = () => {
//     props.handleCloseEvent();
//     //reset user details
//     setFormState("submit");
//     setUserDetailsFetchField("");
//     setFetchedSecondaryFieldValue("");
//     setGlobalError("");
//     setBlockInputField(false);
//     setfetchedUserDetails({
//       UserNotifyID: null,
//       MobilePhone: null,
//       Email: null,
//       Fieldssubscribed: [],
//     });
//     setConsentField({
//       ...consentField,
//       show: false,
//       errorMessage: "",
//       value: false,
//     });

//     //reset input fields
//     setInputFields({
//       mobile: {
//         value: "",
//         error: "",
//       },
//       email: {
//         value: "",
//         error: "",
//       },
//     });

//     //reset otp field
//     setOtpField({
//       show: false,
//       error: "",
//       fieldValue: null,
//       fieldLabel: "",
//     });
//   };

//   const consentBoxHandler = (e) => {
//     if (blockInputField) {
//       displayGlobalError("Fields can't be updated during form submission.");
//       return;
//     }
//     setConsentField({
//       ...consentField,
//       value: e.target.checked,
//       errorMessage: "",
//     });
//   };
//   const otpInputHandler = (e) => {
//     const inputValue = e.target.value.substring(0, 6);
//     setOtpField({
//       ...otpField,
//       fieldValue: inputValue,
//     });
//   };
//   const displayGlobalError = (errorMessage) => {
//     // console.log("global errror called");
//     setGlobalError(errorMessage);
//     setTimeout(() => {
//       setGlobalError("");
//     }, 3000);
//   };
//   const hideOtpField = () => {
//     setOtpField({
//       ...otpField,
//       show: false,
//     });
//   };
//   const removeField = ({ index, id, fieldName, park }) => {
//     if (blockInputField) {
//       displayGlobalError(
//         "Field can't be removed while form submission is in process. "
//       );
//       return;
//     }
//     setfetchedUserDetails((prevDetails) => ({
//       ...prevDetails,
//       Fieldssubscribed: prevDetails.Fieldssubscribed.map((subscription) => {
//         console.log("subs", subscription);
//         if (
//           subscription.fields.length > 1 &&
//           subscription.fieldNames.length > 1
//         ) {
//           return {
//             type: subscription.type,
//             fields: subscription.fields.filter((name) => name !== id),
//             fieldNames: subscription.fieldNames.filter(
//               (name) => name !== fieldName
//             ),
//           };
//         } else if (
//           subscription.fields.length === 1 &&
//           subscription.fieldNames.length === 1
//         ) {
//           return {
//             type: subscription.type,
//             fields: subscription.fields.filter((name) => name !== id),
//             fieldNames: subscription.fieldNames.filter(
//               (name) => name !== fieldName
//             ),
//           };
//         }
//         return subscription;
//       }),
//     }));
//     if (park.fields.length === 1 && park.fieldNames.length === 1) {
//       removePark({ field: park });
//     }
//   };
//   const removePark = ({ field }) => {
//     if (blockInputField) {
//       displayGlobalError(
//         "Park can't be removed while form submission is in process."
//       );
//       return;
//     }
//     setfetchedUserDetails((prevDetails) => ({
//       ...prevDetails,
//       Fieldssubscribed: prevDetails.Fieldssubscribed.filter(
//         (subscriptions) => subscriptions.type !== field.type
//       ),
//     }));
//   };
//   const setFieldValue = ({ fieldName, fieldValue }) => {
//     setInputFields((prevInputFields) => ({
//       ...prevInputFields,
//       [fieldName]: {
//         ...prevInputFields[fieldName],
//         value: fieldValue,
//       },
//     }));
//   };
//   const inputHandler = ({ name, value }) => {
//     // console.log(userDetailsFetchField, name, value.target.value);
//     if (blockInputField) {
//       setErrors({
//         name: name,
//         errorMessage:
//           "Input Fields cannot be modified while form submission is in process.",
//       });
//       setTimeout(() => {
//         setErrors({
//           name: name,
//           errorMessage: "",
//         });
//       }, 3000);
//       return;
//     }
//     //remove all previus errors for the input field
//     setErrors({ name: name, errorMessage: "" });
//     let inputValue = value.target.value;

//     //remove user fetched details once input field is cleared after fetching details
//     if (
//       userDetailsFetchField &&
//       userDetailsFetchField === name &&
//       (inputValue?.replace(/-/g, "") === "" || inputValue === "")
//     ) {
//       setUserDetailsFetchField("");
//       setfetchedUserDetails({
//         UserNotifyID: null,
//         MobilePhone: null,
//         Email: null,
//         Fieldssubscribed: [],
//       });
//       setInputFields({
//         mobile: {
//           value: "",
//           error: "",
//         },
//         email: {
//           value: "",
//           error: "",
//         },
//       });
//       setConsentField({
//         ...consentField,
//         show: false,
//         value: false,
//       });

//       // console.log("value is empty");
//     }

//     if (name === "mobile") {
//       //remove '-' from the input value
//       let formattedResult = inputValue.replace(/-/g, "");
//       if (
//         userDetailsFetchField === "email" &&
//         formattedResult.trim().length > 0
//       ) {
//         setConsentField({ ...consentField, show: true, value: false });
//       } else if (
//         userDetailsFetchField === "email" &&
//         formattedResult.trim().length === 0
//       ) {
//         setConsentField({ ...consentField, show: false, value: false });
//       }
//       setFieldValue({ fieldName: name, fieldValue: inputValue });

//       //mobile number validations on input
//       if (
//         formattedResult.trim().length !== 10 &&
//         formattedResult.trim().length > 0
//       ) {
//         setErrors({
//           name: name,
//           errorMessage: "Enter 10 digit mobile number.",
//         });
//         return;
//       }
//       if (
//         value.target.value.replace(/-/g, "").trim() !== "" &&
//         !fetchedUserDetails.Email
//       ) {
//         getUserDetails({ inputFieldName: name, inputFieldValue: inputValue });
//       }
//       if (userDetailsFetchField === "email") {
//         setfetchedUserDetails((prevState) => ({
//           ...prevState,
//           MobilePhone: inputValue,
//         }));
//       }
//     } else if (name === "email") {
//       setFieldValue({ fieldName: name, fieldValue: inputValue });

//       //email field validation on input
//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       if (!emailRegex.test(inputValue) && inputValue.trim().length > 0) {
//         setErrors({
//           name: name,
//           errorMessage: "Enter a valid  email address.",
//         });
//         return;
//       }
//       if (inputValue.trim() === "" && inputValue.length !== 0) {
//         setErrors({
//           name: name,
//           errorMessage: "Invalid entry!",
//         });
//         return;
//       }
//       if (!fetchedUserDetails.MobilePhone && inputValue.trim().length !== 0) {
//         setTimeout(() => {
//           getUserDetails({ inputFieldName: name, inputFieldValue: inputValue });
//         }, 500);
//       }
//       if (userDetailsFetchField === "mobile") {
//         setfetchedUserDetails((prevState) => ({
//           ...prevState,
//           Email: inputValue,
//         }));
//       }
//     } else {
//       setErrors({
//         name: name,
//         errorMessage: `Something went wrong.Please try entering ${name} again`,
//       });
//     }
//   };
//   const setErrors = ({ name, errorMessage }) => {
//     setInputFields((prevInputFields) => ({
//       ...prevInputFields,
//       [name]: {
//         ...prevInputFields[name],
//         error: errorMessage,
//       },
//     }));
//   };
//   const isChecked = (value) => {
//     return (
//       fetchedUserDetails.Fieldssubscribed.find(
//         (e) => e.type == selectedType
//       )?.fields?.indexOf(value) >= 0
//     );
//   };
//   const checkboxHandler = async (event, name) => {
//     // console.log("field selected", event.target.checked, name);
//     if (blockInputField) {
//       displayGlobalError(
//         "Fields can't be modified while the form submission is in process."
//       );
//       return;
//     }
//     let idx = null;
//     fetchedUserDetails?.Fieldssubscribed?.forEach((field, index) => {
//       if (name === field.type) {
//         idx = index;
//       }
//     });
//     //if the field is checked then it will add that particular field to the user details
//     if (event.target.checked && idx === null) {
//       // console.log("index not availble");
//       setfetchedUserDetails((prevDetails) => ({
//         ...prevDetails,
//         Fieldssubscribed: [
//           ...prevDetails.Fieldssubscribed,
//           {
//             type: selectedType,
//             fields: [event.target.value],
//             fieldNames: [event.target.name],
//           },
//         ],
//       }));
//     } else if (event.target.checked && idx !== null) {
//       // console.log("index available", event.target.value, name);
//       setfetchedUserDetails((prevDetails) => ({
//         ...prevDetails,
//         Fieldssubscribed: prevDetails.Fieldssubscribed.map((subscription) => {
//           // console.log("subuuus", subscription);
//           if (subscription.type === name) {
//             return {
//               type: subscription.type,
//               fields: [...subscription.fields, event.target.value],
//               fieldNames: [...subscription.fieldNames, event.target.name],
//             };
//           }
//           return subscription;
//         }),
//       }));
//     } else if (!event.target.checked && idx !== null) {
//       // console.log("removing field");
//       setfetchedUserDetails((prevDetails) => ({
//         ...prevDetails,
//         Fieldssubscribed: prevDetails.Fieldssubscribed.map((subscription) => {
//           if (subscription.type === name) {
//             return {
//               type: subscription.type,
//               fields: subscription.fields.filter(
//                 (name) => name !== event.target.value
//               ),
//               fieldNames: subscription.fieldNames.filter(
//                 (name) => name !== event.target.name
//               ),
//             };
//           }

//           return subscription;
//         }),
//       }));

//       if (fetchedUserDetails.Fieldssubscribed[idx].fields.length === 1) {
//         removePark({ field: fetchedUserDetails.Fieldssubscribed[idx] });
//       }
//     } else {
//       displayGlobalError("Something went wrong!.Please try again.");
//       setTimeout(() => {
//         closeModal();
//       }, 2000);
//     }
//   };
//   const handleTypeChange = (event) => {
//     if (blockInputField) {
//       displayGlobalError(
//         "Fields can't be modified while the form submission is in process."
//       );
//       return;
//     }
//     setSelectedType(event.target.value);
//   };

//   const getUserDetails = async ({ inputFieldName, inputFieldValue }) => {
//     setUserDetailsFetchField(inputFieldName);
//     let userDetailsApi =
//       inputFieldName === "mobile"
//         ? "api/park/notifyusersmobile?phone="
//         : "api/park/notifyusersemail?email=";

//     await smartParkService
//       .get(userDetailsApi + inputFieldValue)
//       .then((res, err) => {
//         if (res.status === 200 && res.data.data) {
//           inputFieldName === "mobile"
//             ? setFetchedSecondaryFieldValue(res.data.data.Email)
//             : setFetchedSecondaryFieldValue(res.data.data.MobilePhone);

//           if (inputFieldName === "mobile" && res.data.data.UserNotifyID) {
//             setConsentField({ ...consentField, show: false, value: true });
//           }
//           if (
//             inputFieldName === "email" &&
//             res.data.data.UserNotifyID &&
//             res.data.data.MobilePhone
//           ) {
//             setFieldValue({
//               fieldName: "mobile",
//               fieldValue: res.data.data.MobilePhone,
//             });
//             setConsentField({ ...consentField, show: false, value: true });
//           }

//           if (
//             inputFieldName === "mobile" &&
//             res.data.data.UserNotifyID &&
//             res.data.data.Email
//           ) {
//             setFieldValue({
//               fieldName: "email",
//               fieldValue: res.data.data.Email,
//             });
//             setConsentField({ ...consentField, show: false, value: true });
//           }

//           //for existing user with data in database
//           const fields = [];
//           //iterating the user subscribed fields with the fields available at the park
//           for (const field of res.data.data.Fieldssubscribed) {
//             console.log("field", field);
//             if (field.fields.length > 0) {
//               // Check if the field subscirbed by the user exists in current park
//               if (facilitiesList[field.type]) {
//                 // If the subscribed field is available then push it into the list of fields.
//                 fields.push(field);
//               } else {
//                 // Handle the case where there is no match
//                 // console.log(field,`No match found for type ${field.type}`);
//               }
//             }
//           }

//           setfetchedUserDetails((prevState) => ({
//             ...prevState,
//             UserNotifyID: res.data.data.UserNotifyID,
//             MobilePhone: res.data.data.MobilePhone,
//             Email: res.data.data.Email,
//             Fieldssubscribed: fields,
//           }));
//         } else if (res.status === 200 && res.data.data === null) {
//           if (inputFieldName === "mobile" && res.data.data === null) {
//             // console.log("new user with mobile number");
//             setConsentField({ ...consentField, show: true });
//           }
//           //if there is no available user data
//           setfetchedUserDetails({
//             UserNotifyID: null,
//             MobilePhone: inputFieldName === "mobile" ? inputFieldValue : "",
//             Email: inputFieldName === "email" ? inputFieldValue : "",
//             Fieldssubscribed: [],
//           });
//         } else {
//           renderToasts({
//             message: "Something went wrong while fetching the user data.",
//           });
//         }
//       });
//   };

//   const submitForm = async () => {
//     // console.log("formstate", formState, fetchedUserDetails);
//     if (formState === "submit") {
//       await sendVerificationOtp();
//     }
//     if (formState === "NU-OTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "NU");
//     }
//     if (formState === "EU-OTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "EU");
//     }
//     if (formState === "eEU-OTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "eEU");
//     }
//     if (formState === "eNU-OTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "eNU");
//     }
//     if (formState === "DV-NU-MOTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "DV-M-NU");
//     }
//     if (formState === "DV-NU-EOTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "DV-E-NU");
//     }
//     if (formState === "DV-EU-MOTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "DV-M-EU");
//     }
//     if (formState === "DV-EU-EOTP") {
//       await verifyEnteredOtp(otpField.fieldValue, "DV-E-EU");
//     }
//     if (formState === "NU-SAVE") {
//       saveUserData("NU");
//     }
//     if (formState === "EU-SAVE") {
//       saveUserData("EU");
//     }
//   };
//   const sendVerificationOtp = async () => {
//     if (
//       fetchedUserDetails.MobilePhone === null &&
//       fetchedUserDetails.Email === null
//     ) {
//       displayGlobalError(
//         "Mobile/Email is required for subscribing to notifications."
//       );
//       return;
//     }

//     if (
//       fetchedUserDetails.UserNotifyID === null &&
//       fetchedUserDetails.Fieldssubscribed.length === 0
//     ) {
//       displayGlobalError("Please select the fields before submitting.");
//       return;
//     }

//     if (!consentField.value && userDetailsFetchField === "mobile") {
//       setConsentField({
//         ...consentField,
//         errorMessage: "Consent is required for notifications.",
//       });
//       return;
//     }

//     if (!consentField.value && consentField.show) {
//       setConsentField({
//         ...consentField,
//         errorMessage: "Consent is required for notifications.",
//       });
//       return;
//     }

//     //block any updation to the fields once validation is passed
//     setBlockInputField(true);
//     const accessToken = GenerateEncryptedToken();

//     // console.log("before", fetchedUserDetails);
//     if (
//       (userDetailsFetchField === "mobile" &&
//         fetchedSecondaryFieldValue === fetchedUserDetails.Email) ||
//       (userDetailsFetchField === "email" &&
//         fetchedSecondaryFieldValue === fetchedUserDetails.MobilePhone) ||
//       (userDetailsFetchField === "email" &&
//         fetchedUserDetails.MobilePhone === "") ||
//       (userDetailsFetchField === "mobile" && fetchedUserDetails.Email === "")
//     ) {
//       // console.log("mobile or email", fetchedUserDetails);
//       if (fetchedUserDetails.UserNotifyID === null) {
//         const verificationApi =
//           userDetailsFetchField === "email"
//             ? `api/park/verifyuseremail/${fetchedUserDetails.Email}`
//             : `api/park/verifyusermobile/${fetchedUserDetails.MobilePhone.replace(
//                 /-/g,
//                 ""
//               )}`;
//         smartParkService.get(verificationApi).then(async (res, err) => {
//           if (res.status === 200) {
//             // renderToasts({ message: res.data.message });
//             renderToasts({ message: "Activation code sent successfully!" });
//             setFormState(
//               userDetailsFetchField === "email" ? "eNU-OTP" : "NU-OTP"
//             );
//             setOtpField({
//               ...otpField,
//               show: true,
//               fieldLabel:
//                 userDetailsFetchField === "email"
//                   ? "Enter activation code received on your email address."
//                   : "Enter activation code received on your mobile phone.",
//             });
//           } else {
//             renderToasts({ message: "Something went wrong.Please try again." });
//             // console.log("error occured while sending otp");
//           }
//           // console.log("mobile", res);
//         });
//       } else {
//         // console.log("otp else");
//         let data;
//         if (userDetailsFetchField === "email") {
//           data = {
//             type: 0, //0 for email 1 for sms
//             email: fetchedUserDetails.Email,
//             mobile:
//               fetchedSecondaryFieldValue === fetchedUserDetails.MobilePhone
//                 ? ""
//                 : fetchedUserDetails.MobilePhone,
//           };
//         } else {
//           data = {
//             type: 1, //0 for email 1 for sms
//             mobile: fetchedUserDetails.MobilePhone,
//             email:
//               fetchedSecondaryFieldValue === fetchedUserDetails.Email
//                 ? ""
//                 : fetchedUserDetails.Email,
//           };
//         }
//         smartParkService
//           .post("api/park/update/confirmation", data, {
//             headers: {
//               "x-access-token": accessToken,
//             },
//           })
//           .then((res) => {
//             if (res.data.status === 200) {
//               // renderToasts({ message: res.data.message });
//               renderToasts({ message: "Update code sent successfully!" });
//               setFormState(
//                 userDetailsFetchField === "mobile" ? "EU-OTP" : "eEU-OTP"
//               );
//               setOtpField({
//                 ...otpField,
//                 show: true,
//                 fieldLabel:
//                   userDetailsFetchField === "mobile"
//                     ? "Enter update code received on your mobile."
//                     : "Enter update code received on your email",
//               });
//             } else {
//               renderToasts({ message: "Something went wrong!" });
//             }
//           });
//       }
//     } else {
//       // console.log("mobile and email");
//       doubleOtpVerification({ verificationField: "mobile" });
//     }
//   };
//   const verifyEnteredOtp = async (otp, userstatus) => {
//     const responseToken = GenerateEncryptedToken();
//     if (userstatus === "NU" || userstatus === "eNU") {
//       let api =
//         userDetailsFetchField === "mobile"
//           ? `api/park/verifysmsotp?mobile=${fetchedUserDetails.MobilePhone.replace(
//               /-/g,
//               ""
//             )}&otp=${otp}`
//           : `api/park/verifyemailotp?email=${fetchedUserDetails.Email}&otp=${otp}`;
//       await smartParkService.get(api).then((res) => {
//         if (res.data.status === 200) {
//           // console.log("thisis");
//           // renderToasts({ message: res.data.message });
//           renderToasts({ message: "Activation code verified." });
//           setFormState(userstatus === "DV-NU" ? "DV-NU-EOTP" : "NU-SAVE");
//           hideOtpField();
//         } else if (res.data.status === 500) {
//           setOtpField({
//             ...otpField,
//             fieldValue: otp,
//             error: res.data.message,
//           });
//         } else {
//           setOtpField({
//             ...otpField,
//             error: "Activation code could not be verified. Please try again!",
//           });
//         }
//         // console.log("otp verification", otpField.fieldValue, res);
//       });
//     } else if (userstatus === "EU" || userstatus === "eEU") {
//       let data;
//       if (userstatus === "EU") {
//         data = {
//           type: 0, // 0 for sms 1 for email
//           mobile: fetchedUserDetails.MobilePhone,
//           otp: otp,
//         };
//       } else if (userstatus === "eEU") {
//         data = {
//           type: 1, // 0 for sms 1 for email
//           email: fetchedUserDetails.Email,
//           mobile: null,
//           otp: otp,
//         };
//       } else {
//         displayGlobalError("Something went wrong. Please try again.");
//         setTimeout(() => {
//           closeModal();
//         }, 2000);
//       }

//       smartParkService
//         .post("api/park/update/verify", data, {
//           headers: {
//             "x-access-token": responseToken,
//           },
//         })
//         .then((res) => {
//           if (res.data.status == 200) {
//             // renderToasts({ message: res.data.message });
//             renderToasts({ message: "Updation code verified successfully." });
//             setFormState("EU-SAVE");
//             hideOtpField();
//           } else if (res.data.status === 500) {
//             setOtpField({
//               ...otpField,
//               fieldValue: otp,
//               error: res.data.message,
//             });
//           } else {
//             // console.log("else");
//             setOtpField({
//               ...otpField,
//               error: "Updation code could not be verified. Please try again!",
//             });
//           }
//         });
//     } else if (userstatus === "DV-M-NU" || userstatus === "DV-E-NU") {
//       // console.log("verify otp twice", userstatus);
//       let api =
//         userstatus === "DV-M-NU"
//           ? `api/park/verifysmsotp?mobile=${fetchedUserDetails.MobilePhone.replace(
//               /-/g,
//               ""
//             )}&otp=${otp}`
//           : `api/park/verifyemailotp?email=${fetchedUserDetails.Email}&otp=${otp}`;
//       await smartParkService.get(api).then((res) => {
//         if (res.data.status === 200) {
//           // console.log("ressave", userstatus);
//           hideOtpField();
//           renderToasts({ message: "Activation code verified." });
//           // setFormState(userstatus === "DV-M-NU" ? "DV-NU-EOTP" : "NU-SAVE");
//           if (userstatus === "DV-M-NU") {
//             setFormState("DV-NU-EOTP");
//             doubleOtpVerification({ verificationField: "email" });
//             return;
//           } else {
//             setFormState("NU-SAVE");
//           }
//         } else if (res.data.status === 500) {
//           setOtpField({
//             ...otpField,
//             fieldValue: otp,
//             error: res.data.message,
//           });
//         } else {
//           setOtpField({
//             ...otpField,
//             error: "Activation code could not be verified. Please try again!",
//           });
//         }
//         // console.log("otp in ", otpField.fieldValue, res);
//       });
//     } else if (userstatus === "DV-M-EU" || userstatus === "DV-E-EU") {
//       // console.log("userstatus verification", userstatus);
//       let data;
//       if (userstatus === "DV-M-EU") {
//         data = {
//           type: 0, // 0 for sms 1 for email
//           mobile: fetchedUserDetails.MobilePhone,
//           otp: otp,
//         };
//       } else if (userstatus === "DV-E-EU") {
//         data = {
//           type: 1, // 0 for sms 1 for email
//           email: fetchedUserDetails.Email,
//           mobile: null,
//           otp: otp,
//         };
//       } else {
//         displayGlobalError("Something went wrong. Please try again.");
//         setTimeout(() => {
//           closeModal();
//         }, 2000);
//       }

//       smartParkService
//         .post("api/park/update/verify", data, {
//           headers: {
//             "x-access-token": "citizen",
//           },
//         })
//         .then((res) => {
//           if (res.data.status == 200) {
//             // renderToasts({ message: res.data.message });
//             renderToasts({ message: "Updation code verified." });
//             hideOtpField();
//             if (userstatus === "DV-M-EU") {
//               // console.log("entered here");
//               setFormState("DV-E-EU");
//               doubleOtpVerification({ verificationField: "email" });
//               return;
//             } else {
//               // console.log("reached here FOR SAVING");
//               setFormState("EU-SAVE");
//             }
//           } else if (res.data.status === 500) {
//             setOtpField({
//               ...otpField,
//               fieldValue: otp,
//               error: res.data.message,
//             });
//           } else {
//             // console.log("else");
//             setOtpField({
//               ...otpField,
//               error: "Updation code could not be verified. Please try again!",
//             });
//           }
//         });
//     }
//   };
//   const doubleOtpVerification = async ({ verificationField }) => {
//     if (fetchedUserDetails.UserNotifyID === null) {
//       // console.log("doubleverification function", verificationField);
//       const verificationApi =
//         verificationField === "mobile"
//           ? `api/park/verifyusermobile/${fetchedUserDetails.MobilePhone.replace(
//               /-/g,
//               ""
//             )}`
//           : `api/park/verifyuseremail/${fetchedUserDetails.Email}`;

//       await smartParkService.get(verificationApi).then(async (res, err) => {
//         if (res.status === 200) {
//           renderToasts({
//             message:
//               verificationField === "email"
//                 ? "Activation code sent successfully on the provided email address."
//                 : "Activation code sent successfully on the provided mobile number.",
//           });
//           setFormState(
//             verificationField === "mobile" ? "DV-NU-MOTP" : "DV-NU-EOTP"
//           );
//           setOtpField({
//             ...otpField,
//             fieldValue: "",
//             show: true,
//             fieldLabel:
//               verificationField === "email"
//                 ? "Enter the activation code received on your email address."
//                 : "Enter the activation code received on your mobile phone.",
//           });
//         } else {
//           renderToasts({
//             message:
//               "Error occured while sending activation code.Please try again.",
//           });
//         }
//       });
//     } else {
//       // console.log("double verification existing user", verificationField);
//       let data;
//       if (verificationField === "mobile") {
//         data = {
//           type: 1, //0 for email 1 for sms
//           mobile: fetchedUserDetails.MobilePhone,
//         };
//       } else {
//         data = {
//           type: 0, //0 for email 1 for sms
//           email: fetchedUserDetails.Email,
//         };
//       }
//       smartParkService
//         .post("api/park/update/confirmation", data, {
//           headers: {
//             "x-access-token": "citizen",
//           },
//         })
//         .then((res) => {
//           if (res.data.status == 200) {
//             setFormState(
//               verificationField === "mobile" ? "DV-EU-MOTP" : "DV-EU-EOTP"
//             );
//             setOtpField({
//               ...otpField,
//               fieldValue: "",
//               show: true,
//               fieldLabel:
//                 verificationField === "mobile"
//                   ? "Enter update code received on your mobile."
//                   : "Enter update code received on your email",
//             });
//           } else {
//             renderToasts({ message: "Something went wrong.Please try again." });
//           }
//         });

//       // console.log("data", data);
//     }
//   };
//   const saveUserData = async (userstatus) => {
//     // console.log("here", userstatus);
//     const accessToken = GenerateEncryptedToken();
//     const saveUserApi =
//       userstatus === "NU"
//         ? "/api/park/notifyusers"
//         : "api/park/update/notification";

//     let data;
//     if (userstatus === "NU") {
//       data = {
//         MobilePhone: fetchedUserDetails.MobilePhone.replace(/-/g, ""),
//         Email: fetchedUserDetails.Email,
//         Fieldssubscribed: fetchedUserDetails.Fieldssubscribed,
//       };
//     } else {
//       data = fetchedUserDetails;
//     }
//     await smartParkService
//       .post(saveUserApi, data, {
//         headers: {
//           "x-access-token": accessToken,
//         },
//       })
//       .then((res) => {
//         if (res.data.status == 200) {
//           // renderToasts({ message: res.data.message });
//           renderToasts({
//             message:
//               userstatus === "NU"
//                 ? "Subscription successful."
//                 : "Successfully updated.",
//           });
//           closeModal();
//         }
//       });
//   };

//   useEffect(async () => {
//     // Set the initial value and cursor position at the beginning after the component mounts
//     if (inputRef.current) {
//       inputRef.current.setSelectionRange(0, 0);
//     }
//     const list = {};
//     const mcpFields = [];
//     await smartParkService
//       .get("/api/citizen/fielddata2")
//       .then(async (res, err) => {
//         if (res.data.status == 200) {
//           setSelectedType("MCP");
//           list["MCP"] = {
//             name: "Morrisville Community Park",
//             value: "MCP",
//             fields: res.data.data,
//           };
//         }
//       })
//       .then(async () => {
//         await smartParkService
//           .get("/api/citizen/fielddata3")
//           .then(async (res, err) => {
//             if (res.data.status == 200) {
//               list["CFDP"] = {
//                 name: "Cedar Fork District Park",
//                 value: "CFDP",
//                 fields: res.data.data,
//               };
//             }
//           });
//       })
//       .then(async () => {
//         await smartParkService
//           .get("/api/citizen/fielddata4")
//           .then(async (res, err) => {
//             if (res.data.status == 200) {
//               list["SP"] = {
//                 name: "Shiloh Park",
//                 value: "SP",
//                 fields: res.data.data,
//               };
//             }
//           });
//       })
//       .then(async () => {
//         await smartParkService
//           .get("/api/citizen/fielddata5")
//           .then(async (res, err) => {
//             if (res.data.status == 200) {
//               list["CCNP"] = {
//                 name: "Crabtree Creek Nature Park",
//                 value: "CCNP",
//                 fields: res.data.data,
//               };
//             }
//           });
//       })
//       .then(async () => {
//         await smartParkService.get("/api/courts").then(async (res, err) => {
//           if (res.data.status == 200) {
//             // console.log("thirs", res);
//             list["MAFC"] = {
//               name: "Morrisville Aquatics & Fitness Center",
//               value: "MAFC",
//               fields: res.data.data,
//             };
//           }
//         });
//       })
//       .then(async () => {
//         await smartParkService
//           .get("/api/churchstreetcourts")
//           .then(async (res, err) => {
//             if (res.data.status == 200) {
//               // console.log("forth", res);
//               list["CSPTC"] = {
//                 name: "Church Street Park",
//                 value: "CSPTC",
//                 fields: res.data.data,
//               };
//             }
//           });
//       })
//       .then(async () => {
//         await smartParkService.get("api/mcpcourts").then(async (res, err) => {
//           if (res.data.status == 200) {
//             // console.log("this responses", res);
//             res.data.data.forEach((data) => {
//               list["MCP"].fields.push(data);
//             });
//           }
//         });
//       });

//     setFacilitiesList(list);
//     // setTimeout(() => {
//     //   console.log("list", facilitiesList);
//     // }, 10000);
//     // smartParkService.get("api/mcpcourts").then(async (res, err) => {
//     //   if (res.data.status == 200) {
//     //     await setmcpcourtdata(res.data.data);
//     //   }
//     // });

//     // await smartParkService
//     //   .get("/api/events/wake-demo/wf/park")
//     //   .then(async (res, err) => {
//     //     if (res.data.status == 200) {
//     //       const list = {};
//     //       await res.data.data.forEach((data, idx) => {
//     //         if (idx === 0) {
//     //           setSelectedType(data.park_name);
//     //         }
//     //         list[data.park_name] = {
//     //           name: data.park_name,
//     //           value: data.park_name,
//     //           fields: data.field_data,
//     //         };
//     //       });
//     //       setFacilitiesList(list);
//     //     }
//     //   });
//   }, []); // Empty dependency array ensures this effect runs only once on mount

//   return (
//     <div>
//       <ToastContainer />
//       <ModalDialog
//         fullWidth={fullWidth}
//         maxWidth={maxWidth}
//         open={props.NotifyPopUp}
//         aria-labelledby="field-model"
//       >
//         <DialogTitle id="field-model-title">
//           <strong> Notify Me</strong>
//           <div style={{ color: "red" }}>{globalError}</div>
//         </DialogTitle>
//         <DialogContent className={classes.modalHeight}>
//           <DialogContentText>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <InputMask
//                   mask="999-999-9999"
//                   fullWidth
//                   value={inputFields.mobile.value}
//                   onChange={(e) => {
//                     inputHandler({ name: "mobile", value: e });
//                   }}
//                   maskChar=" "
//                 >
//                   {() => (
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       fullWidth
//                       id="phone"
//                       label="Mobile Number ( Optional )"
//                       inputRef={inputRef}
//                     />
//                   )}
//                 </InputMask>
//                 <div style={{ color: "red" }}>{inputFields.mobile.error}</div>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   variant="outlined"
//                   size="small"
//                   id="emailadresss"
//                   name="emailadresss"
//                   label="Email Address"
//                   fullWidth
//                   value={inputFields.email.value}
//                   onChange={(e) => {
//                     inputHandler({ name: "email", value: e });
//                   }}
//                 />
//                 <div style={{ color: "red" }}>{inputFields.email.error}</div>
//               </Grid>

//               <Grid item xs={12} sm={12}>
//                 <FormControl
//                   size="small"
//                   variant="outlined"
//                   className={classes.w100p}
//                 >
//                   <InputLabel id="parkSelect">Select Park</InputLabel>
//                   <Select
//                     labelId="parkSelect"
//                     fullWidth
//                     value={selectedType}
//                     label="Facility Name"
//                     onChange={handleTypeChange}
//                   >
//                     {Object.values(facilitiesList).map((e) => {
//                       return (
//                         <MenuItem key={e.value} value={e.value}>
//                           {e.name}
//                         </MenuItem>
//                       );
//                     })}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={12}>
//                 <FormControl
//                   component="fieldset"
//                   className={classes.formControl}
//                 >
//                   <FormLabel>
//                     <strong>Choose fields/Courts</strong>
//                   </FormLabel>
//                   {Object.values(facilitiesList).map((value) => {
//                     if (value.value === selectedType) {
//                       return (
//                         <FormGroup row>
//                           {value.value === selectedType
//                             ? value.fields.map((field) => {
//                                 return (
//                                   <FormControlLabel
//                                     key={field.field_id}
//                                     control={
//                                       <Checkbox
//                                         color="primary"
//                                         checked={isChecked(
//                                           field.FieldID || field.court_api_id
//                                         )}
//                                         value={
//                                           field.FieldID || field.court_api_id
//                                         }
//                                         onChange={(e) => {
//                                           checkboxHandler(e, selectedType);
//                                         }}
//                                         name={
//                                           field.FieldName || field.court_name
//                                         }
//                                       />
//                                     }
//                                     label={
//                                       field.FieldName ||
//                                       `${field.FieldGroup} (${field.court_name}) `
//                                     }
//                                   />
//                                 );
//                               })
//                             : ""}
//                         </FormGroup>
//                       );
//                     }
//                   })}
//                 </FormControl>
//               </Grid>
//               <p className={notify.subcribed}>
//                 <strong>Subscribed Summary</strong>
//               </p>
//               <Grid item xs={12} sm={12}>
//                 {fetchedUserDetails?.Fieldssubscribed?.map((field) => {
//                   // console.log("name", field);
//                   return (
//                     <>
//                       <ul
//                         className={notify.subcribedol}
//                         style={{ listStyle: "none" }}
//                       >
//                         <li className={notify.subcribedli}>
//                           <Chip
//                             label={facilitiesList[field.type].name}
//                             onDelete={() => {
//                               removePark({ field: field });
//                             }}
//                           />
//                         </li>
//                       </ul>
//                       <ul>
//                         {field.fieldNames.map((item, idx) => (
//                           <li key={item}>
//                             <Chip
//                               label={item}
//                               id={item}
//                               key={item}
//                               onDelete={() =>
//                                 removeField({
//                                   index: idx,
//                                   id: field.fields[idx],
//                                   fieldName: field.fieldNames[idx],
//                                   park: field,
//                                 })
//                               }
//                             />
//                           </li>
//                         ))}
//                       </ul>
//                     </>
//                   );
//                 })}
//               </Grid>
//               <Grid item xs={12} sm={12}>
//                 <div className="checknotifynumber">
//                   <>
//                     <div style={{ color: "red" }}>
//                       {consentField.errorMessage}
//                     </div>
//                     {consentField.show ? (
//                       <FormControlLabel
//                         key={consentField.label}
//                         control={
//                           <Checkbox
//                             color="primary"
//                             checked={consentField.value}
//                             value={consentField.value}
//                             onChange={consentBoxHandler}
//                             name={consentField.name}
//                           />
//                         }
//                         label={consentField.label}
//                       />
//                     ) : (
//                       ""
//                     )}
//                   </>
//                   <>
//                     {!consentField.show && consentField.value ? (
//                       <div>
//                         You have already provided the consent to receive SMS
//                         notifications.
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </>
//                 </div>
//               </Grid>
//               {/* form footer */}
//               {otpField.show ? (
//                 <>
//                   <Grid item xs={12} sm={12}>
//                     <span style={{ color: "red" }}>{otpField.fieldLabel}</span>
//                     <TextField
//                       variant="outlined"
//                       size="small"
//                       id="Registrationcode"
//                       name="Registrationcode"
//                       label="Verification code"
//                       fullWidth
//                       className="mt-2"
//                       value={otpField.fieldValue}
//                       onChange={otpInputHandler}
//                     />
//                     <div style={{ color: "red" }}>{otpField.error}</div>
//                   </Grid>
//                 </>
//               ) : (
//                 ""
//               )}
//               <>
//                 <Button
//                   color="primary"
//                   variant="contained"
//                   disabled={false}
//                   className={classes.btnRound}
//                   onClick={submitForm}
//                 >
//                   {formState === "submit"
//                     ? "Submit"
//                     : formState === "NU-SAVE" || formState === "EU-SAVE"
//                     ? "Save"
//                     : "Verify"}
//                 </Button>
//                 <Button
//                   color="default"
//                   round
//                   className={classes.btnRound}
//                   onClick={closeModal}
//                 >
//                   Cancel
//                 </Button>
//               </>
//             </Grid>
//           </DialogContentText>
//         </DialogContent>
//       </ModalDialog>
//     </div>
//   );
// }
