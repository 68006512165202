import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import smartParkService from "../../axios";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { Edit, Delete, Add, ToggleOff } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@material-ui/data-grid";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { login_, token_ } from "../../reducers/login";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const useStyles = makeStyles(styles);
const featureColumns = [
  { field: "id", headerName: "ID", width: 70, hide: true },
  { field: "name", headerName: "Name", width: 200 },
  { field: "desc", headerName: "Description", width: 250 },
];
const roleColumns = [
  { field: "id", headerName: "ID", width: 70, hide: true },
  { field: "Role", headerName: "Role", width: 200 },
  { field: "RoleDescription", headerName: "Role Description", width: 200 },
  {
    field: "InsertedDate",
    headerName: "Last Edited Date",
    width: 200,
    valueFormatter: (params) => {
      return formatDate(params.value);
    },
  },
];

const permissionsColumns = [
  { field: "id", headerName: "ID", width: 70, hide: true },
  { field: "feature", headerName: "Feature", width: 160 },
  { field: "create", headerName: "Create", width: 100 },
  { field: "read", headerName: "Read", width: 100 },
  { field: "update", headerName: "Update", width: 100 },
  { field: "delete", headerName: "Delete", width: 100 },
];
const permissionsRows = [];
function formatDate(dateVal) {
  if (!dateVal) return "";

  var newDate = new Date(dateVal);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return (
    sMonth +
    "/" +
    sDay +
    "/" +
    sYear +
    " " +
    sHour +
    ":" +
    sMinute +
    " " +
    sAMPM
  );
}
function padValue(value) {
  return value < 10 ? "0" + value : value;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ backgroundColor: "transparent" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AccessManagement(props) {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [loading, setLoading] = React.useState(true);
  const [color, setColor] = React.useState("#ffffff");
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [role, setrole] = React.useState("");
  const [rolename, setrolename] = React.useState([]);
  const [permissionrole, setpermissionrole] = React.useState("");
  const [permissionsRows, setpermissionsRows] = React.useState([]);
  const [featureRows, setfeatureRows] = React.useState([]);
  const [roleRows, setroleRows] = React.useState([]);
  const [permission, setpermission] = React.useState({});
  const handleChangeRole = (event) => {
    setpermissionrole(event.target.value);
    smartParkService
      .get("api/park/" + parkid + "/rolepermission/" + event.target.value)
      .then((res, error) => {
        if (res.data.data.length > 0) {
          var RParray = [];
          var RPdata = res.data.data[0].RolePermission;
          for (var i = 0; i < RPdata.length; i++) {
            var obj = RPdata[i];
            obj.id = i;
            RParray.push(obj);
          }
          setpermissionsRows(RParray);
        } else {
        }
      });
  };
  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  React.useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);

  React.useEffect(() => {
    if (value == 0) {
      smartParkService.get("api/park/" + parkid + "/features").then((res) => {
        if (res.data.status == 200) {
          setfeatureRows(res.data.data);
        }
      });
    }
    if (value == 1) {
      smartParkService.get("api/park/" + parkid + "/roles").then((res) => {
        if (res.data.status == 200) {
          setroleRows(res.data.data);
        }
      });
    }
    if (value == 2) {
      smartParkService
        .get("api/park/" + parkid + "/rolepermission")
        .then((res, error) => {
          if (res.data.data.length > 0) {
            setpermissionrole(res.data.data[0].Role);

            setrolename([...res.data.data]);
          } else {
          }
        });
      smartParkService
        .get("api/park/" + parkid + "/rolepermission/" + role)
        .then((res, error) => {
          if (res.data.data.length > 0) {
            var RParray = [];
            var RPdata = res.data.data[0].RolePermission;
            for (var i = 0; i < RPdata.length; i++) {
              var obj = RPdata[i];
              obj.id = i;
              RParray.push(obj);
            }
            setpermissionsRows(RParray);
          } else {
          }
        });
    }
  }, [value]);
  const getpermissions = async () => {
    const permissionsobject = permissionsobj;
    var Dpermissions = permissionsobject.filter(function (e) {
      return e.feature == "AccessManagment";
    });
    setpermission(Dpermissions[0]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const routeAddFeature = () => {
    let path = "AddFeature";
    history.push(path);
  };
  const routeAddRole = () => {
    let path = "AddRole";
    history.push(path);
  };
  const routeAddRolePermission = () => {
    let path = "AddRolePermission";
    history.push(path);
  };
  return (
    <div className={classes.root}>
      {permission.read == 1 ? (
        <div className={classes.contentPage}>
          <section class="content-header">
            <h3 class="float-left">Access Management</h3>
            <div class="float-right">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a class="active" href="#">
                    Access Management
                  </a>
                </li>
              </ol>
            </div>
          </section>
          <section class="content">
            <div class="content-middle">
              <Paper className={classes.paper}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="FEATURES"
                    {...a11yProps(0)}
                    style={{ justifyContent: "flex-end" }}
                  />
                  <Tab label="ROLES" {...a11yProps(1)} />
                  <Tab label="PERMISSIONS" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid container>
                    <Grid item xs={12}>
                      {permission.create == 1 ? (
                        <div class="btnRules">
                          <Link component="button" onClick={routeAddFeature}>
                            {" "}
                            <Add style={{ fontSize: 18 }} /> Add Feature
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 350, width: "100%" }}>
                        <DataGrid
                          rows={featureRows}
                          columns={featureColumns}
                          hideFooter={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container>
                    <Grid item xs={12}>
                      {permission.create == 1 ? (
                        <div>
                          <Link component="button" onClick={routeAddRole}>
                            {" "}
                            <Add style={{ fontSize: 18 }} /> Add Role
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 350, width: "100%" }}>
                        <DataGrid
                          rows={roleRows}
                          columns={roleColumns}
                          hideFooter={true}
                          autoHeight={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      {permission.update == 1 ? (
                        <div>
                          <Link
                            component="button"
                            onClick={routeAddRolePermission}
                          >
                            {" "}
                            <Edit style={{ fontSize: 18 }} /> Edit Role
                            Permissions
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ margin: "20px" }}>
                        <div class="form-col">
                          <FormControl
                            variant="outlined"
                            size="small"
                            className={classes.w80p}
                          >
                            <InputLabel id="ChooseLableRole">
                              Choose Role
                            </InputLabel>
                            <Select
                              labelId="ChooseLableRole"
                              id="ChooseRole"
                              value={permissionrole}
                              label="Choose Role"
                              onChange={handleChangeRole}
                              inputProps={{ "aria-label": "Choose Role" }}
                            >
                              <MenuItem value="" disabled>
                                Choose
                              </MenuItem>
                              {rolename.length > 0
                                ? rolename.map((e) => {
                                    return (
                                      <MenuItem value={e.Role}>
                                        {e.Role}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                              {/* <MenuItem value="test">test</MenuItem>
						<MenuItem value="my dest">my dest</MenuItem>
						<MenuItem value="PW Supervisor">PW Supervisor</MenuItem>
						<MenuItem value="Administrator">Administrator</MenuItem> */}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 350, width: "100%" }}>
                        <DataGrid
                          rows={permissionsRows}
                          columns={permissionsColumns}
                          hideFooter="true"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </div>
          </section>
        </div>
      ) : { loading } ? (
        <ClipLoader color={color} loading={loading} css={override} size={60} />
      ) : (
        <div
          style={{
            color: "red",
            position: "absolute",
            top: "50%",
            left: "40%",
            fontSize: "20px",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
          }}
        >
          "You do not have read permissions. Please contact Admin"
        </div>
      )}
    </div>
  );
}
