import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    background: "#fff",
    padding: "0 30px",
    boxSizing: "border-box",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "70px",
    display: "flex",
  },
  breadcrumb: {
    display: "flex",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  breadcrumbItem: {
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "&.active a": {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
    },
  },
  floatLeft: {
    float: "left",
  },
  floatRight: {
    float: "right",
  },
}));
const Breadcrumbs = ({ title, crumbs }) => {
  const classes = useStyles();
  return (
    <section className={classes.contentHeader}>
      <h3 className="editleft">{title}</h3>
      <div className="editright">
        <div className="reloadbtn">
          <ol className="breadcrumb">
            {crumbs.map((crumb) => {
              return (
                <li className="breadcrumb-item">
                  <Link
                    to={crumb.link}
                    className={crumb.active ? "active" : ""}
                  >
                    {crumb.name}
                  </Link>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
