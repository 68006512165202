import React, { useEffect, useState } from "react";
import MultiSelect from "react-multi-select-component";
import {
  alpha,
  makeStyles,
  IconButton,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import {
  DateRange,
  ExpandMore,
  Remove,
  Build,
  Clear,
} from "@material-ui/icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
const formatLabel = ({ startDate, endDate }) => {
  return (
    startDate.format("MMMM D, YYYY") + " - " + endDate.format("MMMM D, YYYY")
  );
};

const getDateBasedOnRange = (rangeType) => {
  switch (rangeType) {
    case "Last 24 Hours":
      return [moment().subtract(1, "days").toDate(), moment().toDate()];
    case "LastWeek":
      return [moment().subtract(6, "days").toDate(), moment().toDate()];
    case "Last Month":
      return [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    case "Last 3 Month":
      return [
        moment().subtract(3, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    case "Last 6 Month":
      return [
        moment().subtract(6, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    case "LastYear":
      return [
        moment().subtract(12, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    default:
      return [];
  }
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  section1: {
    background: "#f8f8f8",
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  section2: {
    background: "#fff",
    padding: theme.spacing(1, 4),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  AppTitle: {
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  pageHeader: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  appBar: {
    background: "#fff",
    padding: theme.spacing(1),
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mainContent: {
    marginTop: 90,
  },
  userLogin: {
    color: "#444",
    margin: theme.spacing(0, 2),
  },
  refreshButton: {
    background: "#fff !important",
    fontWeight: 600,
  },
}));
const DatePickerTab = ({ start, end, maxDate, ranges, callbackHandler }) => {
  // console.log("ranges", ranges);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  // useEffect(() => {
  //   setStartDate(start || moment().tz("America/New_York").startOf("day"));
  //   setEndDate(end || moment().tz("America/New_York").endOf("day"));
  // }, []);

  const classes = useStyles();
  const handleCallback = (start, end, rangeType) => {
    callbackHandler(start, end, rangeType);
  };

  return (
    <ButtonGroup size="small" color="primary" aria-label="">
      <Button>
        {start && end && (
          <DateRangePicker
            initialSettings={{
              startDate: start,
              endDate: end,
              maxDate: maxDate,
              ranges: ranges,
            }}
            onCallback={handleCallback}
          >
            <div id="reportrange">
              <DateRange />
              <span>{formatLabel({ startDate: start, endDate: end })}</span>
              <ExpandMore />
            </div>
          </DateRangePicker>
        )}
      </Button>
    </ButtonGroup>
  );
};

export default DatePickerTab;
