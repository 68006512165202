import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Paper,
  Button,
  Typography,
} from "@material-ui/core";
import FirstFloor from "../../../assets/img/Layout/firstFloor.jpg";
import SecondFloor from "../../../assets/img/Layout/secondFloor.jpg";
import SwimmingPool from "../../../assets/img/Layout/swimmingpool.jpg";
import halfBattery from "../../../assets/img/Layout/HalfBattery.png";
import fullBattery from "../../../assets/img/Layout/FullBattery.png";
import lowBattery from "../../../assets/img/Layout/LowBattery.png";
import SvgContainer from "../../UserDashboard/Dashboard/SvgContainer";
import smartParkService from "../../../axios";

const batteryPositionObj = {
  "6654ee908ee1a10f5f453d5e": {
    name: "Locker Room Men Battery Pool Entrance",
    display: "Men's Locker Room Outside",
    fieldGroup: "Battery",
    xAxis: 230,
    yAxis: 366.56,
    floor: "Swimming Pool",
  },
  "6654e647a7144a0ab9464e6e": {
    name: "Locker Room Men Battery Lobby Entrance",
    display: "Men's Locker Room Inside",
    fieldGroup: "Battery",
    xAxis: 65,
    yAxis: 310,
    floor: "Swimming Pool",
  },
  "6654effe9260b60b56ab934d": {
    name: "Racquet Ball Court1 Battery",
    display: "Court 1 Battery",
    fieldGroup: "Battery",
    xAxis: 76.516,
    yAxis: 327.8333,
    floor: "First",
  },
  "6654fab9fb7e5008bc958456": {
    name: "Racquet Ball Court2 Battery",
    display: "Court 2 Battery",
    fieldGroup: "Battery",
    xAxis: 254.364,
    yAxis: 327.8333,
    floor: "First",
  },
  "6077617e73efc35483adaa01": {
    name: "Racquetball Court 1",
    display: "Racquetball Court 1",
    fieldGroup: "Court",
    xAxis: 40,
    yAxis: 200,
    floor: "First",
  },
  "6077617e73efc35483adaa02": {
    name: "Racquetball Court 2",
    display: "Racquetball Court 2",
    fieldGroup: "Court",
    xAxis: 210,
    yAxis: 200,
    floor: "First",
  },
  "6654ebb59260b60d96e3deb7": {
    name: "Locker Room Women Battery Lobby Entrance",
    display: "Women's Locker Room Inside",
    fieldGroup: "Battery",
    xAxis: 65,
    yAxis: 215,
    floor: "Swimming Pool",
  },
  "6654efaca7144a0f922e8798": {
    name: "Locker Room Women Battery Pool Entrance",
    display: "Women's Locker Room Outside",
    fieldGroup: "Battery",
    xAxis: 230,
    yAxis: 150,
    floor: "Swimming Pool",
  },
  "6654ea62b3e53a000e545ed2": {
    name: "Staircase",
    display: "Staircase",
    fieldGroup: "Battery",
    xAxis: 579.04,
    yAxis: 127.3866,
    floor: "First",
  },
  "6654e4bcd14ca907e021b12d": {
    name: "Group Excercise Hallway",
    display: "Group Excercise Hallway",
    fieldGroup: "Battery",
    xAxis: 585.6,
    yAxis: 264.12,
    floor: "Second",
  },
  "6654f0e59260b60b56ab9351": {
    name: "Group Exercise Corner",
    display: "Group Exercise Corner",
    fieldGroup: "Battery",
    xAxis: 634.4,
    yAxis: 353.4,
    floor: "Second",
  },
  "6654ead8fb7e50000d126c34": {
    name: "Spin Room",
    display: "Spin Studio",
    fieldGroup: "Battery",
    xAxis: 620,
    yAxis: 470,
    floor: "First",
  },
  "6654ed43b6eb4b0c44a4f780": {
    name: "Streching Area",
    display: "Stretch Area",
    fieldGroup: "Battery",
    xAxis: 250,
    yAxis: 353.4,
    floor: "Second",
  },
  "6654e4bcebeba9086fd9672f": {
    name: "Group Exercise Room",
    display: "Group Exercise Room",
    fieldGroup: "Counter",
    xAxis: 673.44,
    yAxis: 167.4,
    floor: "Second",
  },
  "6654ead818873d08a664442a": {
    name: "Spin Room",
    display: "Spin Studio",
    fieldGroup: "Counter",
    xAxis: 620,
    yAxis: 440,
    floor: "First",
  },
  "6654ed43b6eb4b095b73e933": {
    name: "Streching Area",
    display: "Stretch Area",
    fieldGroup: "Counter",
    xAxis: 29.28,
    yAxis: 446.4,
    floor: "Second",
  },
  "6654ebb5b6eb4b000dab1bd0": {
    name: "Locker Room Women",
    display: "Locker Room Women",
    fieldGroup: "Counter",
    xAxis: 100,
    yAxis: 120,
    floor: "Swimming Pool",
  },
  "Not Available": {
    name: "Lap Pool",
    display: "Lap Pool",
    fieldGroup: "Counter",
    xAxis: 750,
    yAxis: 325,
    floor: "Swimming Pool",
  },
  "6654e646f5d3940b20b6e012": {
    name: "Locker Room Men",
    display: "Locker Room Men",
    fieldGroup: "Counter",
    xAxis: 100,
    yAxis: 430,
    floor: "Swimming Pool",
  },
};

const getBatteryImage = (batteryPercentage) => {
  if (Number(batteryPercentage) >= 70) {
    return fullBattery;
  } else if (Number(batteryPercentage) < 70 && Number(batteryPercentage) > 40) {
    return halfBattery;
  } else if (Number(batteryPercentage) <= 40) {
    return lowBattery;
  }
};

const textColor = (group, value) => {
  if (group === "Counter") {
    if (value === 0) {
      return "green";
    } else if (value > 0) {
      return "red";
    }
  } else if (group === "Racquetball Court") {
    if (value === "Occupied") {
      return "red";
    } else if (value === "Unoccupied") {
      return "green";
    }
  } else {
    return "black";
  }
};

const MafcLayoutsPage = () => {
  const [firstFloorMap, setfirstFloorMap] = useState([]);
  const [secondFloorMap, setsecondFloorMap] = useState([]);
  const [swimmingPoolMap, setswimmingPoolMap] = useState([]);
  const [apiData, setapiData] = useState([]);
  let apiKeys = ["ubidots/batteries", "ubidots/counters", "courts"];

  useEffect(async () => {
    setapiData([]);
    apiKeys.forEach(async (key) => {
      let url = `api/${key}/list/91474e36-76c5-4d59-a8da-2998ab14a4bf`;
      await smartParkService.get(url).then((res) => {
        if (res && res.status === 200 && res.data.data.length > 0) {
          setapiData((prevdata) => [...prevdata, res.data.data]);
        }
      });
    });
  }, []);
  useEffect(() => {
    if (apiData.length > 0) {
      const firstFloor = [];
      const secondFloor = [];
      const swimmingPool = [];

      apiData.forEach((data) => {
        data.forEach((item) => {
          console.log("item", item);
          let isBattery = item.FieldGroup === "Battery" ? true : false;
          const assetId = isBattery
            ? item.batteryid
            : item.counterid || item.courtid;
          const assetDetails = batteryPositionObj[assetId];

          if (assetDetails) {
            assetDetails.name = isBattery
              ? item.batteryname
              : item.countername || item.courtname;
            assetDetails.value = isBattery
              ? item.batteryvalue
              : item.countervalue || item.courtoccupancy;
            assetDetails.type = isBattery ? "image" : "text";
            assetDetails.width = 40;
            assetDetails.textColor =
              textColor(item.FieldGroup, item.courtoccupancy) ||
              textColor(item.FieldGroup, item.countervalue) ||
              textColor(item.FieldGroup, item.batteryvalue);
            assetDetails.text = isBattery ? item.batteryvalue + "%" : null;
            assetDetails.body = isBattery
              ? getBatteryImage(item.batteryvalue)
              : item.FieldGroup === "Racquetball Court"
                ? item.courtoccupancy
                : item.FieldGroup === "Counter"
                  ? item.countervalue > 0
                    ? item.countervalue === 1
                      ? `${item.countervalue} person`
                      : `${item.countervalue} people`
                    : "Unoccupied"
                  : "Not Available";

            if (assetDetails.floor === "First") {
              firstFloor.push(assetDetails);
            } else if (assetDetails.floor === "Second") {
              secondFloor.push(assetDetails);
            } else if (assetDetails.floor === "Swimming Pool") {
              swimmingPool.push(assetDetails);
            }
          }
        });
      });
      setfirstFloorMap(firstFloor);
      setsecondFloorMap(secondFloor);
      setswimmingPoolMap(swimmingPool);
    }
  }, [apiData]);
  let spacing = 4;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const firstFloorRef = useRef(null);
  const secondFloorRef = useRef(null);
  const swimmingPoolRef = useRef(null);
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  if (isXs) spacing = 2;
  else if (isMd) spacing = 4;
  return (
    <div>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 5 }}
          onClick={() => scrollToSection(firstFloorRef)}
        >
          Scroll to First Floor
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 5 }}
          onClick={() => scrollToSection(secondFloorRef)}
        >
          Scroll to Second Floor
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 5 }}
          onClick={() => scrollToSection(swimmingPoolRef)}
        >
          Scroll to Swimming Pool
        </Button>
      </Box>
      <Grid container spacing={spacing}>
        <Grid ref={firstFloorRef} item xs={12}>
          <Box>
            <Paper
              style={{
                borderRadius: 0,
                borderBottom: "4px solid black",
                backgroundColor: "#0076bb",
                color: "white",
              }}
              elevation={8}
            >
              <Typography color="inherit" variant="h3" align="center">
                First Floor
              </Typography>
            </Paper>
            {firstFloorMap.length > 0 && (
              <SvgContainer
                title={"First Floor"}
                image={FirstFloor}
                mappingArray={firstFloorMap}
              />
            )}
          </Box>
        </Grid>
        <Grid ref={secondFloorRef} item xs={12}>
          <Box>
            <Paper
              style={{
                borderRadius: 0,
                borderBottom: "4px solid black",
                backgroundColor: "#0076bb",
                color: "white",
              }}
              elevation={8}
            >
              <Typography color={"inherit"} variant="h3" align="center">
                Second Floor
              </Typography>
            </Paper>
            <Paper elevation={8}>
              {secondFloorMap.length > 0 && (
                <SvgContainer
                  title={"Second Floor"}
                  image={SecondFloor}
                  mappingArray={secondFloorMap}
                />
              )}
            </Paper>
          </Box>
        </Grid>
        <Grid ref={swimmingPoolRef} item xs={12}>
          <Box>
            <Paper
              style={{
                borderRadius: 0,
                borderBottom: "4px solid black",
                backgroundColor: "#0076bb",
                color: "white",
              }}
              elevation={8}
            >
              <Typography color={"inherit"} variant="h3" align="center">
                Swimming Pool
              </Typography>
            </Paper>
            <Paper elevation={8}>
              {swimmingPoolMap.length > 0 && (
                <SvgContainer
                  title={"Swimming Pool"}
                  image={SwimmingPool}
                  mappingArray={swimmingPoolMap}
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MafcLayoutsPage;
