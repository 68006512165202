import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatDisplayDate, formatIsoDate } from "../../utils/IsoDateConverter";
import smartParkService from "../../axios";
import "jspdf-autotable";

import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Tabs/Breadcrumbs";
import moment from "moment";
import { Container, Typography, Paper, Button, Box } from "@material-ui/core";
import Highcharts from "highcharts";
import { css } from "@emotion/core";
import exporting from "highcharts/modules/exporting";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/img/varidxlogo.png";
import DatePickerTab from "../../components/Tabs/DatePickerTab";
import Counters from "../../views/Charts/Counters";
import ChartTable from "../../views/Charts/ChartTable";
exporting(Highcharts);
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;
const useStyles = makeStyles((theme) => ({
  contentPage: {
    flexGrow: 1,
    marginTop: 65,
    minHeight: " calc(100vh - 100px )",
  },
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  export: {
    display: "flex",
    justifyContent: "end",
    // float: "right",
  },
  button: {
    marginLeft: "auto",
  },
  chartContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
  },
  tableContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
    borderCollapse: "collapse",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  header: {
    border: "1px solid #000",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  cell: {
    border: "1px solid #000",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    textAlign: "left",
  },
  rowEven: {
    backgroundColor: theme.palette.grey[200],
  },
  rowOdd: {
    backgroundColor: theme.palette.common.white,
  },
}));

const sensorNames = {
  "6654e4bcebeba9086fd9672f": "Group Exercise Room",
  "6654ead818873d08a664442a": "Spin Room",
  "6654ed43b6eb4b095b73e933": "Streching Area",
  "6654ebb5b6eb4b000dab1bd0": "Locker Room Women",
  "Not Available": "Lap Pool",
  "6654e646f5d3940b20b6e012": "Locker Room Men",
};
const title = "Morrisville Smart City Report";
const subtitle = "Morrisville Aquatics & Fitness Centre";
const transformDataForHighcharts = (report) => {
  const series = report.Content.flatMap((content) =>
    content.Sensors.map((sensor) => ({
      name: sensorNames[sensor.SensorID],
      data: sensor.Data.map((dataPoint) => ({
        x: new Date(dataPoint.date).getTime(),
        y: dataPoint.value,
        date: dataPoint.date,
      })),
    }))
  );

  return {
    chart: {
      zoomType: "xy",
      //   type: "spline",
    },
    title: {
      text: "Morrisville Aquatics & Fitness Centre",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
    },
    yAxis: {
      title: {
        text: "Occupancy",
      },
    },
    rangeSelector: {
      selected: 1,
      enabled: false, // To hide the zoom 1m 3m and Input fields
    },
    navigator: {
      enabled: true,
    },
    chart: {
      zoomType: "xy",
    },
    series,
    tooltip: {
      formatter: function () {
        const date = new Date(this.x);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `Date: ${date.toDateString()}<br>Time: ${hours}:${minutes}:${seconds}<br>Value: ${this.y
          }`;
      },
    },
    navigator: {
      enabled: true,
    },
  };
};

export default function Reports({ parkArray }) {
  const classes = useStyles();
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  function formattedDate(isoString) {
    return moment(isoString).format("MMMM D, YYYY HH:mm");
  }
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").tz("America/New_York").startOf("day")
  );
  const [endDate, setEndDate] = useState(
    moment().tz("America/New_York").endOf("day")
  );
  const [maxDate, setmaxDate] = useState(moment().tz("America/New_York"));
  const ranges = {
    "Current Week": [
      moment().tz("America/New_York").startOf("week"),
      moment().tz("America/New_York").endOf("week"),
    ],
    "Last Week": [
      moment().tz("America/New_York").subtract(1, "weeks").startOf("week"),
      moment().tz("America/New_York").subtract(1, "weeks").endOf("week"),
    ],
  };

  const exportPDF = () => {
    html2canvas(document.querySelector(".highcharts-container"), {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF();

      // Add logo
      const logoWidth = 40;
      const logoHeight = 20;
      const logoX = pdf.internal.pageSize.width - logoWidth - 10;
      const logoY = 10;
      pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Calculate the center position for the title
      const pageWidth = pdf.internal.pageSize.width;
      const titleFontSize = 18;
      const subtitleFontSize = 14;
      const dateFontSize = 10;
      pdf.setFontSize(titleFontSize);
      const titleWidth = pdf.getTextWidth(title);
      const titleX = (pageWidth - titleWidth) / 2;
      const titleY = 20;

      // Add title
      pdf.text(title, titleX, titleY);

      // Add subtitle
      pdf.setFontSize(subtitleFontSize);
      const subtitleX = 10; // Left align
      const subtitleY = titleY + 7; // Adjust this value to control spacing
      pdf.text(subtitle, subtitleX, subtitleY);

      // Add date range
      pdf.setFontSize(dateFontSize);
      const dateRangeText = `(${formatDisplayDate(
        startDate
      )} - ${formatDisplayDate(endDate)})`;
      const dateRangeX = 10; // Left align
      const dateRangeY = subtitleY + 5; // Adjust this value to control spacing
      pdf.text(dateRangeText, dateRangeX, dateRangeY);

      // Add chart image
      pdf.addImage(imgData, "JPEG", 10, dateRangeY + 10, 190, 100);

      // Extract and add tables
      const tables = extractTableData();
      let startY = dateRangeY + 120; // Adjust startY to account for title, subtitle, date range, and chart

      tables.forEach((table) => {
        pdf.autoTable({
          head: [["Date", `Occupancy-${sensorNames[table.sensorID]}`]],
          body: table.rows,
          startY: startY,
          theme: "striped",
        });
        startY = pdf.autoTable.previous.finalY + 10; // Update startY for the next table
      });

      pdf.save("chart_and_table.pdf");
    });
  };

  const counterIds = [
    "6654e4bcebeba9086fd9672f",
    "6654ead818873d08a664442a",
    "6654ed43b6eb4b095b73e933",
    "6654ebb5b6eb4b000dab1bd0",
    "Not Available",
    "6654e646f5d3940b20b6e012",
  ];
  const fetchData = async () => {
    setLoading(true);
    setReportData(null);
    const sensors = counterIds.map((counterId) => ({
      ParkID: "91474e36-76c5-4d59-a8da-2998ab14a4bf",
      SensorID: counterId,
      SensorType: "Counter", // Moisture, Flood etc.
      Datatype: "Default", // Variable Type
    }));
    const payload = {
      OrganisationID: "xxxx-xxxx-xxxx-xxxx",
      StartDate: formatIsoDate(startDate), // YYYY-MM-DD HH:MM TZ
      EndDate: formatIsoDate(endDate),
      Content: [
        {
          AggregationMethod: "Avg", // Avg, Raw, Min, Max
          SamplingPeriod: "1", // In Minutes
          Sensors: sensors,
        },
      ],
    };
    let url = "api/report/rawdata";
    await smartParkService
      .post(url, payload)
      .then((res) => {
        if (res.data.status === 200) {
          setReportData(res.data.Report);
          setLoading(false);
        }
      })
      .catch((error) => { });
    //   .finally(setLoading(true));
  };

  useEffect(async () => {
    if (startDate && endDate && counterIds) {
      await fetchData();
    }
  }, [startDate, endDate]);
  if (reportData) {
  }
  const handleCallBack = ({ start, end, rangeType }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const extractTableData = () => {
    let tables = [];

    reportData.Content[0].Sensors.forEach((sensor) => {
      let rows = [];
      sensor.Data.forEach((row, index) => {
        if (row.value > 0) {
          rows.push([formatDisplayDate(row.date), row.value]);
        }
      });

      if (rows.length > 0) {
        tables.push({
          sensorID: sensor.SensorID,
          rows: rows,
        });
      }
    });

    return tables;
  };

  return (
    <div className={classes.contentPage}>
      <Breadcrumbs
        crumbs={[
          { name: "Home", link: "/overview", active: false },
          { name: "Reports", link: "/reports", active: true },
        ]}
      />

      <Container id="reports-page">
        <Box className={classes.container}>
          <Typography variant="h4" className={classes.title}>
            Morrisville Smart City Report
          </Typography>
          <div className={classes.button}>
            <DatePickerTab
              start={startDate}
              end={endDate}
              maxDate={maxDate}
              ranges={ranges}
              callbackHandler={(start, end, rangeType) => {
                handleCallBack({
                  start: start,
                  end: end,
                  rangeType: rangeType,
                });
              }}
            />
          </div>
        </Box>
        <div className={classes.export}>
          <Button
            id="export-button"
            variant="contained"
            color="primary"
            onClick={exportPDF}
          >
            Export to PDF
          </Button>
        </div>

        <Typography variant="h4">{title}</Typography>
        <Typography variant="h6">
          ({formattedDate(startDate)} - {formattedDate(endDate)} )
        </Typography>

        <Paper elevation={7} className={classes.chartContainer}>
          {loading ? (
            <ClipLoader loading={loading} css={override} size={60} />
          ) : (
            <>
              <Counters options={transformDataForHighcharts(reportData)} />
            </>
          )}
        </Paper>
        <div className={classes.tableContainer}>
          {loading ? (
            <ClipLoader loading={loading} css={override} size={60} />
          ) : (
            <>
              {reportData.Content.map((data) => {
                return <ChartTable data={data} sensors={sensorNames} />;
              })}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
