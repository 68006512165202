import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputMask from "react-input-mask";
import smartParkService from "../../axios";
import styles from "../../components/components.js";
import FormControl from "@material-ui/core/FormControl";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
//import log from "../../logger/logger";
import Grid from "@material-ui/core/Grid";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";

const useStyles = makeStyles(styles);

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "red",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));
export default function AddUser() {
  const classes = useStyles();
  const history = useHistory();
  const helperTestClasses = helperTextStyles();
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const [role, setRole] = React.useState({});
  const [UserName, SetUserName] = React.useState("");
  const [UserType, SetUserType] = React.useState("");
  const [roleRows, setroleRows] = React.useState([]);
  const [FirstName, SetFirstName] = React.useState("");
  const [LastName, SetLastName] = React.useState("");
  const [MiddleName, SetMiddleName] = React.useState("");
  const [EmailAddress, SetEmailAddress] = React.useState("");
  const [Gender, SetGender] = React.useState("");
  const [Address1, SetAddress1] = React.useState("");
  const [Address2, SetAddress2] = React.useState("");
  const [City, SetCity] = React.useState("");
  const [State, setState] = React.useState("");
  const [PhoneNumber, setPhoneNumber] = React.useState("");
  const [errors, seterrors] = React.useState("");
  const [errusername, seterrusername] = React.useState("");
  const [errfirstname, seterrfirstname] = React.useState("");
  const [errlastname, seterrlastname] = React.useState("");
  const [erremailaddress, seterremailaddress] = React.useState("");
  const [errphonenumber, seterrphonenumber] = React.useState("");
  const [erraddress1, seterraddress1] = React.useState("");
  const [errstate, seterrstate] = React.useState("");
  const [errcity, seterrcity] = React.useState("");

  const States = [
    { StateCode: "AL", StateName: "ALABAMA" },
    { StateCode: "AK", StateName: "ALASKA" },
    { StateCode: "AZ", StateName: "ARIZONA" },
    { StateCode: "AR", StateName: "ARKANSAS" },
    { StateCode: "CA", StateName: "CALIFORNIA" },
    { StateCode: "CO", StateName: "COLORADO" },
    { StateCode: "CT", StateName: "CONNECTICUT" },
    { StateCode: "DE", StateName: "DELAWARE" },
    { StateCode: "DC", StateName: "DISTRICT OF COLUMBIA" },
    { StateCode: "FL", StateName: "FLORIDA" },
    { StateCode: "GA", StateName: "GEORGIA" },
    { StateCode: "HI", StateName: "HAWAII" },
    { StateCode: "ID", StateName: "IDAHO" },
    { StateCode: "IL", StateName: "ILLINOIS" },
    { StateCode: "IN", StateName: "INDIANA" },
    { StateCode: "IA", StateName: "IOWA" },
    { StateCode: "KS", StateName: "KANSAS" },
    { StateCode: "KY", StateName: "KENTUCKY" },
    { StateCode: "LA", StateName: "LOUISIANA" },
    { StateCode: "ME", StateName: "MAINE" },
    { StateCode: "MD", StateName: "MARYLAND" },
    { StateCode: "MA", StateName: "MASSACHUSETTS" },
    { StateCode: "MI", StateName: "MICHIGAN" },
    { StateCode: "MN", StateName: "MINNESOTA" },
    { StateCode: "MS", StateName: "MISSISSIPPI" },
    { StateCode: "MO", StateName: "MISSOURI" },
    { StateCode: "MT", StateName: "MONTANA" },
    { StateCode: "NE", StateName: "NEBRASKA" },
    { StateCode: "NV", StateName: "NEVADA" },
    { StateCode: "NH", StateName: "NEW HAMPSHIRE" },
    { StateCode: "NJ", StateName: "NEW JERSEY" },
    { StateCode: "NM", StateName: "NEW MEXICO" },
    { StateCode: "NY", StateName: "NEW YORK" },
    { StateCode: "NC", StateName: "NORTH CAROLINA" },
    { StateCode: "ND", StateName: "NORTH DAKOTA" },
    { StateCode: "OH", StateName: "OHIO" },
    { StateCode: "OK", StateName: "OKLAHOMA" },
    { StateCode: "OR", StateName: "OREGON" },
    { StateCode: "PA", StateName: "PENNSYLVANIA" },
    { StateCode: "RI", StateName: "RHODE ISLAND" },
    { StateCode: "SC", StateName: "SOUTH CAROLINA" },
    { StateCode: "SD", StateName: "SOUTH DAKOTA" },
    { StateCode: "TN", StateName: "TENNESSEE" },
    { StateCode: "TX", StateName: "TEXAS" },
    { StateCode: "UT", StateName: "UTAH" },
    { StateCode: "VT", StateName: "VERMONT" },
    { StateCode: "VA", StateName: "VIRGINIA" },
    { StateCode: "WA", StateName: "WASHINGTON" },
    { StateCode: "WV", StateName: "WEST VIRGINIA" },
    { StateCode: "WI", StateName: "WISCONSIN" },
    { StateCode: "WY", StateName: "WYOMING" },
    { StateCode: "PR", StateName: "PUERTO RICO" },
    { StateCode: "GU", StateName: "GUAM" },
    { StateCode: "AS", StateName: "AMERICAN SAMOA" },
  ];
  const renderstateoptions = (option, { inputValue }) => {
    const matches = match(option.StateName, inputValue);
    const parts = parse(option.StateName, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const handleChange = (event) => {
    setRole({ ...role, [event.target.name]: event.target.checked });
  };
  const validation = () => {
    var requiredFields = true;
    // if (UserName === "" || UserName === undefined) {
    // 	seterrusername("Please Enter User Name");
    // 	requiredFields = false;
    // }
    if (FirstName === "" || FirstName === undefined) {
      seterrfirstname("Please Enter FirstName");
      requiredFields = false;
    }
    if (LastName === "" || LastName === undefined) {
      seterrlastname("Please Enter LastName");
      requiredFields = false;
    }
    if (EmailAddress === "" || EmailAddress === undefined) {
      seterremailaddress("Please Enter EmailAddress");
      requiredFields = false;
    }
    if (EmailAddress !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(EmailAddress)) {
        seterremailaddress("Invalid email address");
        requiredFields = false;
      }
    }
    // if (Address1 === "" || Address1 === undefined) {
    //   seterraddress1("Please Enter Address Line 1");
    //   requiredFields = false;
    // }
    // if (City === "" || City === undefined) {
    //   seterrcity("Please Enter City");
    //   requiredFields = false;
    // }
    if (PhoneNumber === "" || PhoneNumber === undefined) {
      seterrphonenumber("Please Enter PhoneNumber");
      requiredFields = false;
    }

    if (
      PhoneNumber !== "" &&
      PhoneNumber.replaceAll("-", "").trim().length !== 10
    ) {
      seterrphonenumber("Please Enter 10 Digit PhoneNumber");
      requiredFields = false;
    }

    // if (State === "" || State === undefined) {
    //   seterrstate("Please Enter State");
    //   requiredFields = false;
    // }
    return requiredFields;
  };
  const validateEmail = () => {
    if (EmailAddress === "" || EmailAddress === undefined) {
      seterremailaddress("Please Enter EmailAddress");
    }
    if (EmailAddress !== "") {
      var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(EmailAddress)) {
        seterremailaddress("Invalid email address");
      }
    }
  };
  const validatephonenumber = () => {
    if (
      PhoneNumber !== "" &&
      PhoneNumber.replaceAll("-", "").trim().length !== 10
    ) {
      seterrphonenumber("Please Enter 10 Digit PhoneNumber");
    }
  };
  React.useEffect(() => {
    var url = "api/park/" + parkid + "/roles";
    smartParkService.get(url).then((res) => {
      if (res.data.status == 200) {
        setroleRows([...res.data.data]);
        // prepareruleobject(res.data.data);
      }
    });
  }, []);
  const prepareruleobject = (data) => {
    var obj = {};
    for (var i = 0; i < data.length; i++) {
      obj[data[i].RoleName] = false;
    }
    setRole({ ...obj });
  };
  const SaveuserClick = async () => {
    var validdata = validation();

    try {
      if (validdata) {
        var Dataobject = {
          // UserName: UserName,
          UserType: "Public Worker",
          EmailAddress: EmailAddress.toLowerCase(),
          Password: "smartpark@123",
          parkID: parkid.toString(),
          Role: role,
          FirstName: FirstName,
          LastName: LastName,
          MiddleName: MiddleName,
          Gendar: Gender,
          Address1: Address1,
          Address2: Address2,
          City: City,
          State: State,
          MobilePhone: PhoneNumber,
          IsActive: true,
          Authentication: "Smartpark",
        };

        await smartParkService
          .post("api/users", Dataobject, {
            headers: {
              userid: userid,
            },
          })
          .then((res, err) => {
            if (res.data.status === "200") {
              history.push("/Users");
            } else {
              alert("User Data Invalid");
            }
          })
          .catch(() => {
            alert("Error occurred while adding the user");
          });
      } else {
        alert("Please enter the Required fields");
      }
    } catch (err) {
      //log.error(err);
      console.log(err);
    }
  };
  const onChangeValue = (e) => {
    SetGender(e.target.value);
  };
  //Used for Radio Buttons
  const [value, setValue] = React.useState("female");

  const handleRdChange = (event) => {
    setValue(event.target.value);
  };
  const CheckEmailValidonblur = async () => {
    try {
      validateEmail();
      if (EmailAddress !== "") {
        await smartParkService
          .head("api/user?userEmail=" + EmailAddress.toLowerCase())
          .then((res) => {
            if (res.status === 200) {
              seterremailaddress("Email '" + EmailAddress + " 'Already Exists");
              SetEmailAddress("");
            }
          });
      }
    } catch (error) {
      //log.error(error);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Users List</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Users
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link component="button" onClick={() => history.push("/Users")}>
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Add New User</h3>
              </div>
              <div>
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/Users")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body">
                <Grid container spacing={3} className={classes.pad20}>
                  {/* <Grid item xs={12} sm={6}>
											<TextField
												variant="outlined"
												size="small"
												required
												id="username"
												name="username"
												onBlur={CheckEmailValidonblur}
												label="User Name"
												onClick={() => {
													seterrusername("");
												}}
												value={UserName}
												fullWidth
												autoComplete="given-name"
												onChange={(e) => {
													SetUserName(e.target.value);
												}}
											/>
											<div style={{ color: "red" }}>{errusername}</div>
										</Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      id="Email"
                      name="Email"
                      label="Email"
                      value={EmailAddress}
                      onClick={() => {
                        seterremailaddress("");
                      }}
                      fullWidth
                      helperText={erremailaddress}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      autoComplete="Email"
                      onBlur={CheckEmailValidonblur}
                      onChange={(e) => {
                        SetEmailAddress(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      id="firstName"
                      name="firstName"
                      label="First name"
                      value={FirstName}
                      fullWidth
                      onClick={() => {
                        seterrfirstname("");
                      }}
                      helperText={errfirstname}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      autoComplete="given-name"
                      onChange={(e) => {
                        SetFirstName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      value={LastName}
                      helperText={errlastname}
                      FormHelperTextProps={{ classes: helperTestClasses }}
                      fullWidth
                      onClick={() => {
                        seterrlastname("");
                      }}
                      autoComplete="family-name"
                      onChange={(e) => {
                        SetLastName(e.target.value);
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="address1"
                        name="address1"
                        label="Address line 1*"
                        value={Address1}
                        helperText={erraddress1}
                        FormHelperTextProps={{ classes: classes }}
                        fullWidth
                        autoComplete="address-line1"
                        onClick={() => {
                          seterraddress1("");
                        }}
                        onChange={(e) => {
                          SetAddress1(e.target.value);
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        id="address2"
                        name="address2"
                        value={Address2}
                        label="Address line 2"
                        fullWidth
                        autoComplete="address-line"
                        onChange={(e) => {
                          SetAddress2(e.target.value);
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        id="city"
                        name="city"
                        onClick={() => {
                          seterrcity("");
                        }}
                        helperText={errcity}
                        FormHelperTextProps={{ classes: classes }}
                        label="City"
                        value={City}
                        fullWidth
                        autoComplete="address-level"
                        onChange={(e) => {
                          SetCity(e.target.value);
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item xs={12} sm={4}>
                      <Autocomplete
                        size="small"
                        id="state-selection"
                        options={States}
                        value={State}
                        onChange={(event, newValue) => {
                          setState(newValue);
                        }}
                        getOptionLabel={(option) => option.StateName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select State"
                            variant="outlined"
                            helperText={errstate}
                            FormHelperTextProps={{ classes: classes }}
                            onClick={() => {
                              seterrstate("");
                            }}
                          />
                        )}
                        renderOption={renderstateoptions}
                      />
                    </Grid> */}
                  {/* <Grid item xs={12} sm={4}>
		  <TextField variant="outlined"  size="small"
			required
			id="zip"
			name="zip"
			label="Zip / Postal code"
			fullWidth
			autoComplete="postal-code"
			
		  />
		</Grid>
	   <Grid item xs={12} sm={4}>
		  <TextField variant="outlined"  size="small"
			required
			id="country"
			name="country"
			label="Country"
			fullWidth
			autoComplete="country"
		  />
		</Grid>*/}
                  <Grid item xs={12}>
                    <InputMask
                      mask="999-999-9999"
                      fullWidth
                      value={PhoneNumber}
                      onBlur={validatephonenumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          id="username"
                          name="username"
                          helperText={errphonenumber}
                          FormHelperTextProps={{ classes: helperTestClasses }}
                          label="Phone"
                          onClick={() => {
                            seterrphonenumber("");
                          }}
                        />
                      )}
                    </InputMask>
                    {/* <TextField variant="outlined" size="small"
						required
						id="Phone"
						name="Phone"
						label="Phone"
						value={PhoneNumber}
						fullWidth
						onClick={()=>{
						  seterrphonenumber("")
						}}
						autoComplete="Phone Number"
						onChange={(e) => { setPhoneNumber(e.target.value); }} */}
                  </Grid>
                  <Grid item xs={12}>
                    <label class="form-label">Choose Role &nbsp;&nbsp;</label>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {roleRows.length > 0
                          ? roleRows.map((e) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      onChange={handleChange}
                                      name={e.Role}
                                    />
                                  }
                                  label={e.RoleDescription}
                                />
                              );
                            })
                          : null}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                <Button
                  size="sm"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={SaveuserClick}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="outlined"
                  className={classes.btnRound}
                  onClick={() => {
                    history.push("/Users");
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
