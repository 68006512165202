import { ResponseType } from "@microsoft/microsoft-graph-client";
import axios from "axios";
import serviceconfig from "../config";

export const dummyService = () => {
    return axios;
};

const customAxios = axios.create({
    baseURL: serviceconfig.SmartParkService.baseURL,
});

const requestHandler = (request) => request;

const responseHandler = (response) => response;

var one401Error = false;
const errorHandler = (error) => {
    try {
        if (one401Error == false && error.response.status == 401) {
            one401Error = true;
            window.location.href = window.location.origin + "/login?sessionexpired=1";
            setTimeout(() => {
                one401Error = false;
            }, 1000);
        } else if (error.response.status == 403) {
            alert(JSON.stringify(error.response.data));
        }
    } catch (error) {
        console.log(
            "Error occurred while redirecting user to login page after session expiry"
        );
    }
};
customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default customAxios;
