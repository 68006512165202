import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import smartParkService from "../../axios";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { BiSolidCheckCircle } from "react-icons/bi";
import { BiSolidXCircle } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import "../../assets/scss/App.scss";
import styles from "../../components/components.js";
import ClipLoader from "react-spinners/ClipLoader";
import { GenerateEncryptedToken } from "../Common.js";
import { css } from "@emotion/core";
import Form from "react-bootstrap/Form";
import {
    permissions_,
    Getaccesspermissions,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { login_, token_ } from "../../reducers/login";

const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const useStyles = makeStyles(styles);
const timezoneclass = makeStyles((theme) => ({
    timezonebutton: {
        [theme.breakpoints.down(767)]: {
            "& .MuiButton-label": {
                fontSize: "1rem !important",
            },
        },
        [theme.breakpoints.down(959)]: {
            "& .MuiButton-label": {
                padding: "3px 16px !important",
                fontSize: "11px !important",
            },
        },
    },
}));
const TimeZoneArray = ["America/New_York"];
const featureColumns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "name", headerName: "Name", width: 200 },
    { field: "desc", headerName: "Description", width: 250 },
];
const roleColumns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "Role", headerName: "Role", width: 200 },
    { field: "RoleDescription", headerName: "Role Description", width: 200 },
    {
        field: "InsertedDate",
        headerName: "Last Edited Date",
        width: 200,
        valueFormatter: (params) => {
            return formatDate(params.value);
        },
    },
];

const permissionsColumns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "feature", headerName: "Feature", width: 160 },
    { field: "create", headerName: "Create", width: 100 },
    { field: "read", headerName: "Read", width: 100 },
    { field: "update", headerName: "Update", width: 100 },
    { field: "delete", headerName: "Delete", width: 100 },
];
const permissionsRows = [];
function formatDate(dateVal) {
    if (!dateVal) return "";

    var newDate = new Date(dateVal);

    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
        sHour = "12";
    }

    sHour = padValue(sHour);

    return (
        sMonth +
        "/" +
        sDay +
        "/" +
        sYear +
        " " +
        sHour +
        ":" +
        sMinute +
        " " +
        sAMPM
    );
}
function padValue(value) {
    return value < 10 ? "0" + value : value;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ backgroundColor: "transparent" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export default function TimeZone() {
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const [loading, setLoading] = React.useState(true);
    const [color, setColor] = React.useState("#ffffff");
    const myclass = timezoneclass();
    const classes = useStyles();
    const history = useHistory();
    const [handleTimeZoneParkID, setHandleTimeZoneParkID] = React.useState("");
    const [permission, setpermission] = React.useState({});
    const [getparks, setparks] = React.useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllrow, setSelectAllrow] = React.useState(false);
    const [getListdata, setListdata] = React.useState([]);
    const [rows, setRowsdata] = React.useState([]);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [getTimeZone, setTimeZone] = React.useState("");
    const [pending, setPending] = React.useState(true);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = React.useState(null);
    const [getTimezoneParkError, setTimezoneParkError] = React.useState(false);
    const [getTimezoneError, setTimezoneError] = React.useState(false);
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);

    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "AccessManagment";
        });
        setpermission(Dpermissions[0]);
    };

    React.useEffect(() => {
        funcSearchoptions();
    }, []);

    React.useEffect(() => {
        if (getListdata.length > 0) {
            const timeout = setTimeout(() => {
                setRowsdata(getListdata);
                setPending(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [getListdata, refresh]);

    React.useEffect(() => {
        // console.log(itemsPerPage);
        try {
            let url = "api/park/timezone/list";
            var restoken = GenerateEncryptedToken();
            var requestBody = {
                Page: currentPage,
                Limit: itemsPerPage,
            };
            smartParkService
                .post(url, requestBody, {
                    headers: {
                        "x-access-token": restoken,
                    },
                })
                .then((res) => {
                    if (res) {
                        var finaldata = [];
                        try {
                            var counter = 1;
                            setTotalRows(res?.data?.metadata?.pages);
                            if (res.data.facilities?.length < 1) {
                                setPending(false);
                            }
                            res?.data?.facilities?.forEach((data) => {
                                console.log(data);
                                const Park_Name = () => {
                                    return (
                                        <div className="w-100">
                                            <div className="commonsize">{data.facility_name}</div>
                                        </div>
                                    );
                                };
                                const Park_Status = () => {
                                    return (
                                        <div className="w-100">
                                            <div className="commonsize">
                                                {data.facility_open ? "Open" : "Closed"}
                                            </div>
                                        </div>
                                    );
                                };
                                const Park_timezone = () => {
                                    return (
                                        <div className="w-100">
                                            <div className="commonsize">{data.timezone}</div>
                                        </div>
                                    );
                                };

                                finaldata.push({
                                    id: data._id,
                                    ParkName: Park_Name(),
                                    ParkStatus: Park_Status(),
                                    TimeZone: Park_timezone(),
                                });
                            });
                            setListdata(finaldata);
                        } catch {
                            console.log("Error in Timezone list");
                        }
                    }
                })
                .catch((error) => {
                    console.error("TimeZone List error: ", error);
                    // setDeleterefresh(false);
                });
        } catch {
            console.log("List error ");
        }
    }, [currentPage, itemsPerPage, refresh]);

    function funcSearchoptions() {
        try {
            let url = "api/park/ruleengine/searchoptions";
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res) {
                        try {
                            if (res.status === 200) {
                                if (res.data.status == 200) {
                                    var dataa = [];
                                    var devices = {};
                                    res.data.options?.forEach((data, i) => {
                                        dataa.push({
                                            id: data.ID,
                                            name: data.Name,
                                            shortname: data.ShortName,
                                            device: data.Devices,
                                        });
                                        devices[data.ID] = data.Devices;
                                    });
                                    setparks(dataa);
                                    setSearchfilter(devices);
                                } else {
                                    funcSearchoptions();
                                }
                            } else {
                                funcSearchoptions();
                            }
                        } catch {
                            console.log("some error in search Dropdown api ");
                        }
                    } else {
                        console.log("Search else error: ");
                    }
                })
                .catch((error) => {
                    console.error("Search error: ", error);
                });
        } catch {
            console.log("Search Option Error");
        }
    }

    const htmlFormatter = ({ value }) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    );
    const columnsdata = [
        {
            field: "ParkName",
            name: (
                <div className="w-100">
                    <div className="tableheading">Park Name</div>
                </div>
            ),
            minWidth: "10%",
            width: "auto",
            cell: (row) => row.ParkName,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "ParkStatus",
            name: (
                <div className="w-100">
                    <div className="tableheading">Park Status</div>
                </div>
            ),
            minWidth: "10%",
            width: "auto",
            cell: (row) => row.ParkStatus,
            editable: true,
            renderCell: htmlFormatter,
        },
        {
            field: "ParkTimezone",
            name: (
                <div className="w-100">
                    <div className="tableheading">Park TimeZone</div>
                </div>
            ),
            minWidth: "10%",
            width: "auto",
            cell: (row) => row.TimeZone,
            editable: true,
            renderCell: htmlFormatter,
        },
        // {
        //   field: "Settings",
        //   name: <div className="tableheading">Settings</div>,
        //   minWidth: "5%",
        //   editable: true,
        //   cell: (row) => row.enabledisablemenu,
        //   renderCell: htmlFormatter,
        // },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "100px",
                // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                minHeight: "80px",

                // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",

                // override the row height
            },
        },
    };

    const handleTimeZone = (e) => {
        if (e.target.value !== "") {
            setTimeZone(e.target.value);
            setTimezoneError(false);
        } else {
            setTimeZone("");
        }
    };

    const FuncHandleTimeZoneParkID = (e) => {
        if (e.target.value !== "") {
            setHandleTimeZoneParkID(e.target.value);
            setTimezoneParkError(false);
        } else {
            setHandleTimeZoneParkID("");
        }
    };

    function validation(timezone, timezoneparkid) {
        setTimezoneError(false);
        setTimezoneParkError(false);
        if (timezone === "" && timezoneparkid === "") {
            setTimezoneError(true);
            setTimezoneParkError(true);
            return false;
        }
        if (timezone === "") {
            setTimezoneError(true);
            return false;
        }
        if (timezoneparkid === "") {
            setTimezoneParkError(true);
            return false;
        }
        return true;
    }

    const updateTimezone = () => {
        console.log(getTimeZone);
        console.log(handleTimeZoneParkID);
        if (validation(getTimeZone, handleTimeZoneParkID)) {
            try {
                let url = "api/park/timezone/update";
                var restoken = GenerateEncryptedToken();
                var requestBody = {
                    park_id: handleTimeZoneParkID,
                    timezone: getTimeZone,
                };
                smartParkService
                    .post(url, requestBody, {
                        headers: {
                            "x-access-token": restoken,
                        },
                    })
                    .then((res) => {
                        if (res) {
                            // console.log(res);
                            if (res.data.status == "200") {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="toasticon">
                                            <BiSolidCheckCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Success</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.success(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "toastcontainer",
                                });
                                setRefresh(!refresh);
                            } else {
                                const mes = (
                                    <div className="toastsuccess">
                                        <div className="erroricon">
                                            <BiSolidXCircle />
                                        </div>
                                        <div className="toastmessage">
                                            <b>Error</b>
                                            <div>{res.data.message}</div>
                                        </div>
                                    </div>
                                );
                                toast.error(mes, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                    className: "errorcontainer",
                                });
                                setRefresh(!refresh);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Update Timezone error: ", error);
                        // setDeleterefresh(false);
                    });
            } catch {
                console.log("TimeZone error ");
            }
        }
    };

    function selectrulelist(rulelistdata) {
        console.log(rulelistdata);
    }

    return (
        <div className={classes.root}>
            {permission.read == 1 ? (
                <div className={classes.contentPage}>
                    <section className="content-header">
                        <h3 className="float-left">TimeZone</h3>
                    </section>
                    <section className="content">
                        <div className="timezone-middle">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} className="p-0">
                                    <div className="container-fluid ">
                                        <div className="row datepickerwidth">
                                            <div className="col-12 col-md-5 col-lg-5">
                                                <div className="notificationlabel">Select Park :</div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={FuncHandleTimeZoneParkID}
                                                    value={handleTimeZoneParkID}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">All Parks</option>
                                                    {getparks?.map((data, idx) => (
                                                        <option
                                                            key={idx}
                                                            value={data.id}
                                                            data-idx={idx}
                                                            data-name={data.name}
                                                        >
                                                            {data.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                {getTimezoneParkError && (
                                                    <p style={{ color: "red" }}>* Please Select Park</p>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-5 col-lg-5">
                                                <div className="notificationlabel">Select Timezone</div>
                                                <Form.Control
                                                    as="select"
                                                    onChange={handleTimeZone}
                                                    value={getTimeZone}
                                                    style={{ width: "100%", marginRight: "5px" }}
                                                >
                                                    <option value="">TimeZones</option>
                                                    {TimeZoneArray?.map((data, idx) => (
                                                        <option
                                                            key={idx}
                                                            value={data}
                                                            data-idx={idx}
                                                            data-name={data}
                                                        >
                                                            {data}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                {getTimezoneError && (
                                                    <p style={{ color: "red" }}>
                                                        * Please Select Timezone
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-2 col-lg-2 align-self-end">
                                                <Button
                                                    className={`timezonebtn w-100 m-0 ${myclass.timezonebutton}`}
                                                    size="md"
                                                    variant="contained"
                                                    onClick={updateTimezone}
                                                >
                                                    Update TimeZone
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="timezone-middle mt-4">
                            <div className="content-middle rules-middle mobilewidth">
                                <DataTable
                                    columns={columnsdata}
                                    data={getListdata}
                                    // selectableRows
                                    // selectableRowsHighlight
                                    striped
                                    responsive
                                    className="custom-table"
                                    progressPending={pending}
                                    progressComponent={
                                        <ClipLoader
                                            color={color}
                                            loading={loading}
                                            css={override}
                                            size={60}
                                        />
                                    }
                                    customStyles={customStyles}
                                    clearSelectedRows={selectAllrow}
                                    onSelectedRowsChange={({ selectedRows }) => {
                                        setSelectedRows(selectedRows);
                                        selectrulelist(selectedRows);
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            ) : { loading } ? (
                <ClipLoader color={color} loading={loading} css={override} size={60} />
            ) : (
                <div
                    style={{
                        color: "red",
                        position: "absolute",
                        top: "50%",
                        left: "40%",
                        fontSize: "20px",
                        transform: "translate(" - (50 % ", ") - (50 % ")"),
                    }}
                >
                    "You do not have read permissions. Please contact Admin"
                </div>
            )}
        </div>
    );
}
