import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import smartParkService from "../../axios";
import { KeyboardBackspace, LocalDrinkSharp } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import styles from "../../components/components.js";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { login_, token_ } from "../../reducers/login";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const loginDetails = useSelector(login_);
  const parkid = _.get(loginDetails, ["parkID"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const name = _.get(loginDetails, ["FirstName"], "");
  const userid = _.get(loginDetails, ["UserID"], "");
  const classes = useStyles();
  const history = useHistory();
  const [featureName, setfeatureName] = React.useState("");
  const [featureDesc, setfeatureDesc] = React.useState("");

  const saveFeature = () => {
    var featureobject = {};
    featureobject.FeatureName = featureName.replace(/ /g,'')
    ;
    featureobject.Featuredescription = featureDesc;
    featureobject.parkID = parkid;
    //    featureobject.InsertedBy= JSON.parse(localStorage.getItem('user')).name;
    featureobject.InsertedBy = name;
    smartParkService
      .post("api/park/" + parkid + "/features", featureobject, {
        headers: {
          userid: userid,
        },
      })
      .then(async (res, err) => {
        if (res.data.status === "200") {
          await updaterolefeature(res.data.data);
          history.push("/AccessManagment");
        } else {
          alert("User Data Invalid");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const updaterolefeature = (data) => {
    const featureobject = {};
    featureobject.RolePermission = {
      featureID: data.FeatureID,
      feature: data.FeatureName,
      create: "0",
      read: "0",
      update: "0",
      delete: "0",
    };
    smartParkService
      .post("api/park/" + parkid + "/rolepermissionsfeature", featureobject, {
        headers: {
          userid: userid,
        },
      })
      .then((res, err) => {
        if (res) {
          alert("Feature updated");
          history.push("/AccessManagment");
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.contentPage}>
        <section class="content-header">
          <h3 class="float-left">Access Management</h3>
          <div class="float-right">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a class="active" href="#">
                  Access Management
                </a>
              </li>
            </ol>
          </div>
        </section>
        <section class="content">
          <div class="content-middle">
            <div class="content-sub-header">
              <div>
                <Link
                  component="button"
                  onClick={() => history.push("./AccessManagment")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
              <div>
                <h3>Add Feature</h3>
              </div>
              <div class="display-xs-0">
                <Link
                  style={{ visibility: "hidden" }}
                  component="button"
                  onClick={() => history.push("/AccessManagement")}
                >
                  {" "}
                  <KeyboardBackspace style={{ fontSize: 18 }} /> &nbsp; Back
                </Link>
              </div>
            </div>

            <Paper className={classes.paper}>
              <div class="form-body pad20">
                <Grid container spacing={3} className={classes.pad20}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      id="featureName"
                      name="featureName"
                      value={featureName}
                      label="Feature Name"
                      onChange={(e) => {
                        setfeatureName(e.target.value);
                      }}
                      autoComplete="Feature-name"
                      className={classes.w80p}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      id="Description"
                      label="Description"
                      multiline
                      rows={4}
                      value={featureDesc}
                      onChange={(e) => {
                        setfeatureDesc(e.target.value);
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
              <div class="form-footer">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.btnRound}
                  onClick={saveFeature}
                >
                  SAVE
                </Button>
                &nbsp;&nbsp;
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.btnRound}
                >
                  CANCEL
                </Button>
              </div>
            </Paper>
          </div>
        </section>
      </div>
    </div>
  );
}
