import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { Link } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "./ParkOpenCloseModal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import smartParkService from "../../axios";
import IconButton from "@material-ui/core/IconButton";
import { Clear, OpenInNewRounded } from "@material-ui/icons";
import { login_, token_ } from "../../reducers/login";
import _ from "lodash";
import "../../assets/scss/App.scss";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import ToggleModal from "../../components/Modals/ToggleModal";
const ButtonLink = withStyles((theme) => ({
    root: {
        display: "inline !important",
        color: "blue !important",
        marginRight: "10px !important",
        padding: "0 !important",
        textDecoration: "underline",
        border: "none !important",
        fontSize: "13px !important",
        [theme.breakpoints.down("xl")]: {
            fontSize: "14px !important",
        },
    },
}))(Link);

const useStyles = makeStyles(styles);

export default function ParkOpenClose(props) {
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const classes = useStyles();
    const { parkID } = props;
    const token = useSelector(token_);
    smartParkService.defaults.headers.common["x-access-token"] = token;
    const [devicestatus, setdevicestatus] = React.useState({});
    const [status, setstatus] = React.useState([]);
    const [permission, setpermission] = React.useState({});
    const [open, setopen] = React.useState(false);
    const [Openorclose, setOpenorclose] = React.useState("");
    const [selectedparkOpenColse, setselectedparkOpenColse] = React.useState("");
    const [devicename, setdevicename] = React.useState([]);
    const [Iselectall, setIselectall] = React.useState(false);
    const [btnopenactive, setopenbtnactive] = React.useState(false);
    const [btncloseactive, setclosebtnactive] = React.useState(false);
    const [getParkNameStatus, setParkNameStatus] = React.useState("");
    const [allChecked, setallChecked] = React.useState(false);
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    React.useEffect(() => {
        if (parkID == "91474e36-76c5-4d59-a8da-2998ab14a4bf") {
            setselectedparkOpenColse("Morrisville Aquatics & Fitness Center");
        } else if (parkID == "ba0a6f3f-e265-43d1-aaf8-ff21d43d3944") {
            setselectedparkOpenColse("Church Street Park");
        } else if (parkID == "c7a1d85f-8168-4e64-8e38-116a0624bfeb") {
            setselectedparkOpenColse("Morrisville Community Park");
        } else if (parkID == "c7a1d85f-8168-4e64-8e38-116a0624bfec") {
            setselectedparkOpenColse("Shiloh Park");
        } else if (parkID == "c7a1d85f-8168-4e64-8e38-116a0624bfed") {
            setselectedparkOpenColse("Crabtree Creek Nature Park");
        } else {
            setselectedparkOpenColse("Cedar Fork District Park");
        }
    }, [parkID]);

    const getFields = () => {
        var url = "api/park/" + parkID + "/facilitiesmanagement";
        // console.log(url);
        smartParkService
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    var data = res.data;
                    if (data.status == "200") {
                        setParkNameStatus(data.message);
                    }
                }
            })
            .catch((error) => {
                console.log("park status error");
            });
    };
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "GateStatus";
        });
        setpermission(Dpermissions[0]);
    };
    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const handleClose = () => {
        setopen(false);
        setallChecked(false);
        setopenbtnactive(false);
        setclosebtnactive(false);
        getFields();
    };
    const handleFieldopen = () => {
        setFieldData((prevState) => [
            ...prevState,
            {
                deviceType: "Park",
                park_id: parkID,
                primaryStatus: getParkNameStatus === true ? "Open" : "Closed",
                updateStatus: "Open",
                field_name: selectedparkOpenColse,
                field_status: getParkNameStatus === true ? "Open" : "Closed",
                field_id: parkID,
                url_suffix: "/facilitiesmanagement",
            },
        ]);

        setModalStatus(true);

        // setOpenorclose("Open");
        // setopen(true);
    };
    const handleFieldClose = () => {
        setFieldData((prevState) => [
            ...prevState,
            {
                deviceType: "Field",
                park_id: parkID,
                primaryStatus: getParkNameStatus === true ? "Open" : "Closed",
                updateStatus: "Closed",
                field_name: selectedparkOpenColse,
                field_status: getParkNameStatus === true ? "Open" : "Closed",
                field_id: parkID,
                url_suffix: "/facilitiesmanagement",
            },
        ]);
        setModalStatus(true);
        // setOpenorclose("Closed");
        // setopen(true);
    };
    const handleChange = (e) => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        let devicename1 = devicename;
        let allChecked1 = allChecked;
        if (itemName === "checkAll") {
            allChecked1 = checked;
            devicename1 = devicename.map((item) => ({
                ...item,
                isChecked: checked,
            }));
        } else {
            devicename1 = devicename.map((item) =>
                item.deviceID === itemName ? { ...item, isChecked: checked } : item
            );
            allChecked1 = devicename1.every((item) => item.isChecked);
        }

        setopenbtnactive(false);
        setclosebtnactive(false);
        devicename1.map((item) => {
            if (item.devicestatus === "Open") {
                if (item.isChecked == true) {
                    setopenbtnactive(true);
                    setclosebtnactive(true);
                }
            } else if (item.devicestatus === "Closed") {
                if (item.isChecked == true) {
                    setopenbtnactive(true);
                    setclosebtnactive(true);
                }
            }
        });

        setdevicename(devicename1);
        setallChecked(allChecked1);
    };
    const updateStateList = (e, value) => {
        if (e.target.checked) {
            setstatus(status.concat([value]));
        } else {
            setstatus(
                status.filter(function (val) {
                    return val !== value;
                })
            );
        }
    };
    React.useEffect(() => {
        getFields();
    }, [parkID, selectedparkOpenColse]);
    React.useEffect(() => {
        setopenbtnactive(false);
        setclosebtnactive(false);
    }, []);

    const [modalStatus, setModalStatus] = useState(false);
    const userid = _.get(loginDetails, ["UserID"], "");
    const [fieldData, setFieldData] = useState([]);
    const firstname = _.get(loginDetails, ["FirstName"], "");
    const lastname = _.get(loginDetails, ["LastName"], "");
    const toggleHandler = async (e) => {
        const overrideActions = Object.values(toggleCheckBoxData).filter(
            (checkbox) => {
                if (checkbox.value) {
                    return checkbox;
                }
            }
        );
        let url = `api/park/${e[0].park_id}${e[0].url_suffix}`;
        const data = {
            LastEditedBy: `${firstname} ${lastname}`,
            overrideActions: overrideActions[0].name,
            status: e[0].updateStatus,
        };

        await smartParkService
            .post(url, data, {
                headers: {
                    userid: userid,
                },
            })
            .then((res) => {
                if (Number(res.status) === 200) {
                    getFields();
                    handleChange({ target: { checked: false, name: "checkAll" } });
                }
            });
    };
    const handleCheckbox = (e) => {
        Object.keys(toggleCheckBoxData).map((checkbox) => {
            if (e.target.checked) {
                setToggleCheckBoxData((prevdata) => ({
                    ...prevdata,
                    [checkbox]: {
                        ...prevdata[checkbox],
                        value: checkbox === e.target.name ? e.target.checked : false,
                    },
                }));
            } else {
                setToggleCheckBoxData((prevState) => ({
                    ...prevState,
                    [e.target.name]: {
                        ...prevState[e.target.name],
                        value: e.target.checked,
                    },
                }));
            }
        });
    };
    const closeModal = () => {
        setFieldData([]);
        setModalStatus(false);
    };
    const [toggleCheckBoxData, setToggleCheckBoxData] = useState({
        Block: { name: "Block", label: "Block rule actions", value: false },
        Unblock: { name: "Unblock", label: "Unblock rule actions", value: false },
    });
    const checkBoxDescription = [
        "The automatic rules have actions that can open or close the devices.",
        "Do you want to block the actions of those rules?",
    ];
    const helpText = [
        `If you chose to block, then gates, fields, park will remain ${fieldData[0]?.updateStatus
            ? fieldData[0].updateStatus
            : "in [open/closed] state"
        }, until the next manual change.`,
        "If you chose to unblock the rule actions, the gates, fields, park will change state based on the rules. This manual action will be overridden.",
    ];

    return (
        <div>
            {/*<Modal*/}
            {/*    show={open}*/}
            {/*    status={devicename}*/}
            {/*    closePopup={handleClose}*/}
            {/*    openclose={Openorclose}*/}
            {/*    nameofPark={selectedparkOpenColse}*/}
            {/*    currentparkstatus={getParkNameStatus}*/}
            {/*    currentparkID={parkID}*/}
            {/*/>*/}
            <ToggleModal
                submitButtonHandler={(e) => {
                    toggleHandler(e);
                }}
                handleCheckbox={handleCheckbox}
                disableData={fieldData}
                closeModalHandler={closeModal}
                checkBoxData={toggleCheckBoxData}
                checkBoxDescription={checkBoxDescription}
                modalState={modalStatus}
                checkBoxHelpText={helpText}
            />
            <div class="box">
                <div class="box-header text-center">
                    <h6 class="">Park Status</h6>
                    <div class="text-center" style={{ marginBottom: "-10px" }}>
                        {permission.update == 1 ? (
                            <ButtonLink
                                onClick={handleFieldopen}
                                className={`${classes.blueButton}`}
                            >
                                Open Park
                            </ButtonLink>
                        ) : (
                            ""
                        )}
                        {permission.update == 1 ? (
                            <ButtonLink
                                onClick={handleFieldClose}
                                className={`${classes.redButton} `}
                            >
                                Close Park
                            </ButtonLink>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div class="box-body">
                    <div class="divGrid">
                        <div class="divGridHeading">
                            <div class="divGridRow">
                                <div class="divGridHead">Park</div>
                                <div class="divGridHead">Status</div>
                            </div>
                        </div>
                        <div class="divGridBody">
                            <div class="divGridRow">
                                <div class="divGridCell">{selectedparkOpenColse}</div>
                                <div class="divGridCell">
                                    <div class="d-flex">
                                        <FiberManualRecordIcon
                                            fontSize="small"
                                            style={{
                                                color:
                                                    getParkNameStatus === true
                                                        ? green[500]
                                                        : getParkNameStatus === false
                                                            ? red[500]
                                                            : "white",
                                            }}
                                        />
                                        {getParkNameStatus === true ? (
                                            <span className="wrapopenclose">Open</span>
                                        ) : getParkNameStatus === false ? (
                                            <span className="wrapopenclose"> Close</span>
                                        ) : (
                                            <span>Loading ...</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
