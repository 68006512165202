import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import Button from "../../components/CustomButtons/Button.js";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import smartParkService from "../../axios";
import { GenerateEncryptedToken } from "../../views/Common";
import _ from "lodash";
import { useSelector } from "react-redux";
import { login_ } from "../../reducers/login";
const ToggleModal = ({ disableData, closeModalHandler, modalState, checkBoxData, checkBoxDescription, checkBoxHelpText, handleCheckbox, submitButtonHandler }) => {
    const [fieldBlockData, setFieldBlockData] = useState({})
    const loginDetails = useSelector(login_);
    const userid = _.get(loginDetails, ["UserID"], "");
    const [submitError, setSubmitError] = useState(false);
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [confirmationInput, setConfirmationInput] = useState("");
    const [modalValue, setModalValue] = useState(false);
    const checkboxHandler = (e) => {
        handleCheckbox(e)
    }
    const inputHandler = (e) => {
        const input = e.target.value;
        setConfirmationInput(input.toLowerCase());
    };

    const resetCheckbox = () => {
        Object.values(checkBoxData).map((data) => {
            (data.value = false)
        })
    }
    const submitHandler = () => {
        let checkboxChecked = false
        Object.values(checkBoxData).map((data) => {
            if (data.value === true) {
                return checkboxChecked = true
            }
            return checkboxChecked
        })
        if (!checkboxChecked) {
            setCheckBoxError(true)
            return;
        }
        if (confirmationInput !== "yes") {
            setSubmitError(true);
            return;
        }
        submitButtonHandler(disableData);
        closeToggleModalHandler();
    };

    const fetchStatus = async () => {
        let token = GenerateEncryptedToken();
        await smartParkService
            .get('api/park/ruleengine/listruleactionstatus', {
                headers: {
                    "x-access-token": token,
                    userid: userid,
                },
            }).then((res) => {
                if (res.data) {
                    if (Number(res.data.status) === 200) {
                        res.data.data.forEach((data) => {
                            setFieldBlockData((prevState) => ({
                                ...prevState, [data.DeviceID]: data
                            }))
                            // fieldBlockData[data.DeviceID]=data
                        })
                    }
                }
            })
    }

    useEffect(() => {
        if (modalState === true) {
            fetchStatus()
        }
        setModalValue(modalState);
    }, [modalState]);

    useEffect(() => {
        console.log('dd', disableData)
    }, [disableData])


    const closeToggleModalHandler = () => {
        setCheckBoxError(false)
        setSubmitError(false);
        setConfirmationInput("");
        setModalValue(false);
        resetCheckbox()
        closeModalHandler();
    };

    return (
        <Dialog
            open={modalValue}
            keepMounted
            className="notificationconfirmdialogbox"
            onClose={closeToggleModalHandler}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <div className="Details notificationdetail">
                        <table className="table w-100">
                            <thead>
                                <tr>
                                    <th>Device Name</th>
                                    <th>Current Status</th>
                                    <th>Rule Action Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {disableData.map((data) => {

                                    return (
                                        <tr key={data.field_id}>
                                            <td>{data.field_name}</td>
                                            <td>{data.field_status}</td>
                                            <td>{fieldBlockData[data.field_id] ? fieldBlockData[data.field_id].IsBlocked === true ? 'Blocked' : 'Unblocked' : 'Not Available'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {checkBoxDescription && checkBoxDescription.map((description) => {
                            return <div>
                                <strong>{description}</strong>
                            </div>

                        })}

                        {checkBoxData && Object.values(checkBoxData).map((data) => {
                            return <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={data.value}
                                        value={data.value}
                                        onChange={checkboxHandler}
                                        name={data.name}
                                    />
                                }
                                label={data.label}
                            />
                        })
                        }
                    </div>
                    {checkBoxError && (
                        <p style={{ color: "red" }}>
                            * Please select a checkbox before submitting.{" "}
                        </p>
                    )}
                    {checkBoxHelpText && <strong>Note:-</strong>}
                    {checkBoxHelpText.map((helpText) => {
                        return <div>
                            <span>{helpText}</span>
                        </div>
                    })}

                    <div className={'mt-5'}>
                        <strong>Are you sure you want to change the status of the above mentioned devices ?</strong>
                    </div>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Please Type Yes or yes"
                    type="text"
                    value={confirmationInput}
                    onChange={(e) => inputHandler(e)}
                    className="text-uppercase"
                    fullWidth
                />
                {submitError && (
                    <p style={{ color: "red" }}>
                        * Please type ( YES or yes ) before submitting.{" "}
                    </p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeToggleModalHandler} className="cancelbtn">
                    Cancel
                </Button>
                <Button onClick={submitHandler} className="agreebtn">
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ToggleModal