import React, { useEffect, useState, useRef, Suspense } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-maskedinput";
import ClipLoader from "react-spinners/ClipLoader";
import { Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../components/components.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../../components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import DatePicker from "react-datepicker";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import RichTextEditor from "react-rte";
import smartParkService from "../../axios";
import { useLocation } from "react-router-dom";
import { HiMiniInformationCircle } from "react-icons/hi2";
import printer from "../../assets/img/printer-icn.png";
import { GenerateEncryptedToken } from "../../views/Common";
import { login_ } from "../../reducers/login";
import Link from "@material-ui/core/Link";
import "react-toastify/dist/ReactToastify.css";
import { TiArrowBack } from "react-icons/ti";
import { css } from "@emotion/core";
import {
    permissions_,
    hasLoadedpermissions_,
} from "../../reducers/permissions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { todosAction } from "../../reducers/reducers.js";
const EditNewSummary = React.lazy(() => import("./EditSummary.js"));

const useStyles = makeStyles(styles);
const weekdayclass = makeStyles((theme) => ({
    weekdayborder: {
        border: "1px solid #fff",
        borderRadius: "0.25rem",
    },
    samepadd: {
        "& .MuiSelect-select": {
            padding: "12px auto !important",
        },
    },
    outlinedayweek: {
        border: "1px solid #ced4da",
        borderRadius: "5px",
        padding: "4px",
        "&:before": {
            border: "none !important",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1px",
        },
    },
    dayscss: {
        fontSize: "13px",
        marginBottom: "2px",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        [theme.breakpoints.down("sm")]: {
            // Styles for screens smaller than or equal to 'sm' breakpoint
            fontSize: "11px",
            marginBottom: "2px",
            paddingLeft: "0.3rem",
            paddingRight: "0.3rem",
        },
    },
}));
const override = css`
  display: block;
  margin: 250px auto;
  border-color: #0076bb;
`;

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const Accordion = withStyles({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: 0,
        minHeight: 35,
        "&$expanded": {
            minHeight: 35,
        },
    },
    content: {
        margin: "20px 5px",
        "&$expanded": {
            margin: "20px 5px",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

// Multiple select ================

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Park = ["Gates", "Fields", "Moisture"];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

//  emaillist section ====================

export default function CreatenewRule() {
    const classes = useStyles();
    const theme = useTheme();
    const myclass = weekdayclass();
    const dispatch = useDispatch();
    const loginDetails = useSelector(login_);
    const parkid = _.get(loginDetails, ["parkID"], "");
    const userid = _.get(loginDetails, ["UserID"], "");
    const location = useLocation();
    // console.log(location);
    var stateDetail;
    if (location?.state !== undefined) {
        try {
            stateDetail = location?.state?.detail;
        } catch (error) {
            console.log("Location state error " + error);
        }
    }
    const [loading, setLoading] = React.useState(true);
    const [color, setColor] = React.useState("#ffffff");
    // datainput ------======================================================
    const [getRulename, setRuleName] = React.useState("");
    const [getonDescriptionMessage, setonDescriptionMessage] = React.useState("");
    const [priorityno, setPriorityno] = React.useState("");
    const [getOnOFFValue, setOnOFFValue] = React.useState(false);
    const [getemailOnOFFValue, setemailOnOFFValue] = React.useState(false);
    const [getsmsOnOFFValue, setsmsOnOFFValue] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(startDate);
    const [getDayOfWeek, setDayOfWeek] = React.useState([]);
    const [timevalue, onTimeChange] = React.useState("");
    const [endtimevalue, oneTimeendChange] = React.useState("");
    const [getTrigerstrategy, setTrigerstrategy] = React.useState("");
    const [getShowCondition, setShowCondition] = React.useState("");
    const [getparkDeviceType, setparkDeviceType] = React.useState("");
    const [getparkDeviceId, setparkDeviceId] = React.useState("");
    const [getTriggervalue, setTriggervalue] = React.useState("");
    const [getTriggerCriteria, setTriggerCriteria] = React.useState("");
    const [getTolerance, setTolerance] = React.useState("");
    const [gethandleParkId, sethandleParkId] = React.useState("");
    const [getUpdateActionID, setUpdateActionID] = React.useState("");
    const [getUpdateConditionID, setUpdateConditionID] = React.useState("");
    const [gethandleOpenClosePark, sethandleOpenClosePark] = React.useState("");
    const [EmailsList, setEmailsList] = React.useState([]);
    const [EmailsStringList, setEmailsStringList] = React.useState([]);
    const [MobileStringList, setMobileStringList] = React.useState([]);
    const [dropEmailsList, setdropEmailsList] = React.useState([]);
    const [MobileNumbersList, setMobileNumbersList] = React.useState([]);
    const [dropMobileNumbersList, setdropMobileNumbersList] = React.useState([]);
    const [getSmsMessage, setSmsMessage] = React.useState("");
    const [permission, setpermission] = React.useState({});
    const [expanded, setExpanded] = React.useState("panel1");
    const [getparkName, setparkName] = React.useState("");
    const [personName, setPersonName] = React.useState([]);
    const [gethandleParkDeviceType, sethandleParkDeviceType] = React.useState("");
    const [gethandleParkDevice, sethandleParkDevice] = React.useState("");
    const [getRuleError, setRuleError] = React.useState(false);
    // const [isConditionsMet, setIsConditionsMet] = useState(false);
    // const [isActionsMet, setIsActionsMet] = useState(false);
    const [getrefreshstorage, setrefreshstorage] = useState(false);
    const [geteditdata, seteditdata] = useState({});
    const history = useHistory();
    const [getTimeZoneValue, setTimeZoneValue] = React.useState("");
    const [getToggleconfirm, setToggleconfirm] = React.useState(true);
    // form field store ----------------
    const [getParktype, setParktype] = React.useState([]);
    const [getActionParktype, setActionParktype] = React.useState([]);
    const [getParkDevicetype, setParkDevicetype] = React.useState([]);
    const [getActionParkDevicetype, setActionParkDevicetype] = React.useState([]);
    const [getActionpartkname, setActionpartkname] = React.useState([]);
    const [getActiondevicetypename, setActiondevicetypename] = React.useState([]);
    const [getConditionParkName, setConditionParkName] = React.useState([]);
    const [getConditonparktype, setConditonparktype] = React.useState([]);
    const [gethandlevisible, sethandlevisible] = React.useState(true);
    const [getendTimeErrorText, setEndTimeErrorText] = useState("");
    const [getStartTimeErrorText, setStartTimeErrorText] = useState("");
    const [getActionmore, setActionmore] = useState("");
    const [getConditionmore, setConditionmore] = useState("");
    const [getaddValue, setaddValue] = React.useState("");
    const [getCleareditoverlay, setCleareditoverlay] = useState(false);
    const summrydata = useSelector((state) => state.formdatareduce.basicSettings);
    const validitySetting = useSelector(
        (state) => state.formdatareduce.validitySettings
    );
    const operationalSetting = useSelector(
        (state) => state.formdatareduce.operationalSettings
    );
    const conditionTrigerSetting = useSelector(
        (state) => state.formdatareduce.conditionTrigerSettings
    );
    const conditionTrigerConditionSetting = useSelector(
        (state) => state.formdatareduce.ConditionTrigerConditionSettings
    );
    const actionSetting = useSelector(
        (state) => state.formdatareduce.actionSettings
    );
    const notificationSetting = useSelector(
        (state) => state.formdatareduce.notificationSettings
    );
    const blockDurationSetting = useSelector(
        (state) => state.formdatareduce.blockDurationSettings
    );

    // form field store ---------------
    // form dropdown ===================
    const [getSearchfilter, setSearchfilter] = React.useState([]);
    const [getparks, setparks] = React.useState([]);
    const [getactionpark, setactionpark] = React.useState([]);
    const [getActionparkID, setActionparkID] = React.useState([]);
    const [getSubmitrule, setSubmitrule] = React.useState("");
    const [ruleNameError, setRuleNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [priorityError, setPriorityError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [dayOfWeekError, setDayOfWeekError] = useState(false);
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    // State variables for error messages
    const [trigerstrategyError, setTrigerstrategyError] = useState(false);
    const [parkNameError, setParkNameError] = useState(false);
    const [parkDeviceTypeError, setParkDeviceTypeError] = useState(false);
    const [parkDeviceIdError, setParkDeviceIdError] = useState(false);
    const [triggervalueError, setTriggervalueError] = useState(false);
    const [triggerCriteriaError, setTriggerCriteriaError] = useState(false);
    const [toleranceError, setToleranceError] = useState(false);
    const [ActionparkNameError, setActionParkNameError] = useState(false);
    const [ActiondeviceTypeError, setActionDeviceTypeError] = useState(false);
    const [ActiondeviceError, setActionDeviceError] = useState(false);
    const [actionError, setActionError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [smsError, setSmsError] = useState(false);
    const [toleranceRangeError, setToleranceRangeError] = useState(false);
    const [getEditID, setEditID] = useState(false);
    const [getweekdays, setweekdays] = useState(false);
    const [getweekenddays, setweekenddays] = useState(false);
    const [getalldays, setalldays] = useState(false);
    const [getRandomVal, setRandomVal] = useState(null);
    const [gettrigererror, settrigererror] = useState(false);
    const [getGreenNotify, setGreenNotify] = useState(false);
    const [isTriggerStrategySelected, setIsTriggerStrategySelected] =
        useState(false);
    const [emailBody, setEmailBody] = React.useState(
        RichTextEditor.createEmptyValue("")
    );
    // console.log(emailBody);

    //    fields required error =============
    const [getSummrydataError, setSummrydataError] = useState(false);
    const [getvaliditySettingError, setvaliditySettingError] = useState(false);
    const [getoperationalSettingError, setoperationalSettingError] =
        useState(false);
    const [getconditionTrigerSettingError, setconditionTrigerSettingError] =
        useState(false);
    const [
        getconditionTrigerConditionSettingError,
        setconditionTrigerConditionSettingError,
    ] = useState(false);
    const [getactionSettingError, setactionSettingError] = useState(false);
    const [getemailnotificationSettingError, setemailnotificationSettingError] =
        useState(false);
    const [getsmsnotificationSettingError, setsmsnotificationSettingError] =
        useState(false);
    const [getblockDurationSettingError, setblockDurationSettingError] =
        useState(false);
    //    fields required error ============

    const alldata = {
        getRulename,
        getonDescriptionMessage,
        priorityno,
        getOnOFFValue,
        startDate,
        endDate,
        getDayOfWeek,
        timevalue,
        endtimevalue,
        getTrigerstrategy,
        getparkDeviceType,
        getConditionParkName,
        getConditonparktype,
        getTriggervalue,
        getTriggerCriteria,
        getTolerance,
        gethandleParkId,
        getTimeZoneValue,
        gethandleOpenClosePark,
        getActionpartkname,
        gethandleParkDeviceType,
        gethandleParkDevice,
        getActiondevicetypename,
        getparkName,
        getsmsOnOFFValue,
        MobileNumbersList,
        getSmsMessage,
        getemailOnOFFValue,
        getparkDeviceId,
        EmailsList,
        emailBody,
        // isActionsMet,
        // isConditionsMet,
        // getMultipleActionCondition,
        // setMultiSMSError,
    };
    const names = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    // form dropdown ===================

    React.useEffect(() => {
        if (getrefreshstorage === false) {
            if (localStorage.getItem("addfields")) {
                localStorage.removeItem("addfields");
                setrefreshstorage(true);
            }
        }
    }, [getrefreshstorage]);

    function funcScrollTop() {
        window.scrollTo(0, 0);
    }

    function rulestartenddate(date) {
        try {
            // Convert UTC date to local timezone using Moment.js
            const localDate = new Date(
                moment.utc(date).tz("America/New_York").format("MM-DD-YYYY")
            );
            // Return the formatted date in the local timezone
            return localDate;
        } catch {
            // console.log("sdfjsdf========");
            return date;
        }
    }

    React.useEffect(async () => {
        // console.log(stateDetail);
        if (stateDetail) {
            let url = `api/park/ruleengine/viewrule/${stateDetail}`;
            var restoken = GenerateEncryptedToken();
            await smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res.status == 200) {
                        try {
                            seteditdata(res.data.data);
                            sethandlevisible(false);
                            let editdatabox = res.data.data;
                            // console.log(editdatabox);
                            if (editdatabox !== undefined) {
                                if (editdatabox.EmailRecipients.length > 0) {
                                    setEmailsStringList(editdatabox.EmailRecipients);
                                    setEmailsList(
                                        editdatabox.EmailRecipients.map((option) => option)
                                    );
                                    setEmailBody(
                                        RichTextEditor.createValueFromString(
                                            editdatabox.EmailBody,
                                            "html"
                                        )
                                    );
                                }
                                if (editdatabox.SMSRecipients.length > 0) {
                                    setMobileStringList(editdatabox.SMSRecipients);
                                    setMobileNumbersList(
                                        editdatabox.SMSRecipients.map((option) => option)
                                    );
                                    setSmsMessage(editdatabox.SMSBody);
                                }
                                setRuleName(editdatabox.RuleName);
                                setonDescriptionMessage(editdatabox.RuleDescription);
                                setPriorityno(editdatabox.PriorityValue);
                                setOnOFFValue(editdatabox.RuleIsAlwaysOn);
                                if (editdatabox.RuleIsAlwaysOn) {
                                    setStartDate("");
                                    setEndDate("");
                                } else {
                                    setStartDate(new Date(editdatabox.RuleValidityStartDate));
                                    setEndDate(rulestartenddate(editdatabox.RuleValidityEndDate));
                                }
                                setTrigerstrategy(editdatabox.TriggerStrategy);
                                setShowCondition(editdatabox.TriggerStrategy);
                                setemailOnOFFValue(editdatabox.sendEmailNotification);
                                setsmsOnOFFValue(editdatabox.sendSMSNotification);
                            } else {
                                RestoreAll();
                            }
                        } catch (error) {
                            console.error("edit rule error: ", error);
                        }
                    }
                })
                .catch((error) => {
                    console.error("edit rule details error: ", error);
                });
        }
    }, [stateDetail]);

    function RestoreAll() {
        setRandomVal(Math.floor(Math.random() * 10));
        sessionStorage.removeItem("ranval");
        if (stateDetail) {
            let url = `api/park/ruleengine/viewrule/${stateDetail}`;
            var restoken = GenerateEncryptedToken();
            smartParkService
                .get(url, {
                    headers: {
                        "x-access-token": restoken,
                        userid: userid,
                    },
                })
                .then((res) => {
                    if (res.status == 200) {
                        try {
                            seteditdata(res.data.data);
                            let editdatabox = res.data.data;
                            if (editdatabox !== undefined) {
                                if (editdatabox.EmailRecipients.length > 0) {
                                    // console.log(editdatabox.EmailRecipients);
                                    setEmailsStringList(editdatabox.EmailRecipients);
                                    setEmailsList(
                                        editdatabox.EmailRecipients.map((option) => option)
                                    );
                                    setEmailBody(
                                        RichTextEditor.createValueFromString(
                                            editdatabox.EmailBody,
                                            "html"
                                        )
                                    );
                                }
                                if (editdatabox.SMSRecipients.length > 0) {
                                    setMobileStringList(editdatabox.SMSRecipients);
                                    setMobileNumbersList(
                                        editdatabox.SMSRecipients.map((option) => option)
                                    );
                                    setSmsMessage(editdatabox.SMSBody);
                                }
                                setRuleName(editdatabox.RuleName);
                                setonDescriptionMessage(editdatabox.RuleDescription);
                                setPriorityno(editdatabox.PriorityValue);
                                setOnOFFValue(editdatabox.RuleIsAlwaysOn);
                                if (editdatabox.RuleIsAlwaysOn) {
                                    setStartDate("");
                                    setEndDate("");
                                } else {
                                    setStartDate(new Date(editdatabox.RuleValidityStartDate));
                                    setEndDate(new Date(editdatabox.RuleValidityEndDate));
                                }
                                setTrigerstrategy(editdatabox.TriggerStrategy);
                                setShowCondition(editdatabox.TriggerStrategy);
                                setemailOnOFFValue(editdatabox.sendEmailNotification);
                                setsmsOnOFFValue(editdatabox.sendSMSNotification);
                                setdropMobileNumbersList([]);
                                setdropEmailsList([]);
                            }
                        } catch (error) {
                            console.error("edit rule error: ", error);
                        }
                    }
                })
                .catch((error) => {
                    console.error("edit rule details error: ", error);
                });
        }
    }

    function timestampconvert(time) {
        const dateTimeObj = new Date(time);
        // Get the timestamp in milliseconds
        let unixTimestampInMilliseconds = dateTimeObj.getTime();
        // Get the time zone offset in minutes
        const timezoneOffsetInMinutes = dateTimeObj.getTimezoneOffset();
        // Adjust the timestamp to include the time zone offset
        unixTimestampInMilliseconds += timezoneOffsetInMinutes * 60 * 1000;
        // Return the timestamp
        return unixTimestampInMilliseconds;
    }
    // dropdown Api =====================

    function converthtmlcontent(test) {
        const parser = new DOMParser();
        const parsedHTML = parser.parseFromString(test, "text/html");
        const textContent = parsedHTML.body.textContent;
        return textContent;
    }
    function formatBlockDuration(blockhour, blockmin) {
        let hours = blockhour * 60;
        let mins = blockmin;
        let totalMinutes = parseInt(hours) + parseInt(mins);
        return totalMinutes;
    }
    function dayweeknum(dayweek) {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const dayIndex = days.findIndex(
            (day) => day.toLowerCase() === dayweek.toLowerCase()
        );
        if (dayIndex !== -1) {
            return dayIndex;
        } else {
            return -1; // Indicate that the input day is not valid
        }
    }

    React.useEffect(() => {
        let finalrule = {};
        summrydata?.forEach((data) => {
            // Create a new object for each data item
            finalrule["RuleName"] = data.RuleName;
            finalrule["RuleDescription"] = data.Rule_Description;
            finalrule["PriorityValue"] = data.priorityno;
            // finalRules.push(finalrule); // Add the current finalrule object to the array
        });
        validitySetting?.forEach((data) => {
            finalrule["RuleIsAlwaysOn"] = data.RuleIsAlwaysOn;
            finalrule["RuleValidityStartDate"] = timestampconvert(
                data.RuleValidityStartDate
            );
            finalrule["RuleValidityEndDate"] = timestampconvert(
                data.RuleValidityEndDate
            );
        });
        finalrule["OperationalTime"] = [];
        operationalSetting?.forEach((data) => {
            finalrule["OperationalTime"].push({
                DayofWeek: dayweeknum(data.DayofWeek),
                StartTime: data.StartTime,
                EndTime: data.EndTime,
            });
        });
        conditionTrigerSetting?.forEach((data) => {
            finalrule["TriggerStrategy"] = data.TriggerStrategy;
        });
        finalrule["Conditions"] = [];
        if (finalrule["TriggerStrategy"] == "TIME BASED RULE") {
            finalrule["Conditions"].push({
                ParkID: "",
                ParkName: "",
                Devicetype: "", //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: "",
                DeviceName: "",
                TriggerValue: "",
                TriggerCriteria: "", //Below, Above, Exactly
                Tolerance: "", //In Minutes
            });
        } else {
            conditionTrigerConditionSetting?.forEach((data) => {
                finalrule["Conditions"].push({
                    ParkID: data.ParkID,
                    ParkName: data.ParkName,
                    Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                    DeviceID: data.DeviceID,
                    DeviceName: data.DeviceName,
                    TriggerValue: data.TriggerValue,
                    TriggerCriteria: data.TriggerCriteria, //Below, Above, Exactly
                    Tolerance: data.Tolerance, //In Minutes
                });
            });
        }

        finalrule["Actions"] = [];
        actionSetting?.forEach((data) => {
            finalrule["Actions"].push({
                ParkID: data.ParkID,
                ParkName: data.ParkName,
                Devicetype: data.Devicetype, //Gate, Field, Court, Park, Moisture, Precipitation, Temperature, Sunset, Sunrise
                DeviceID: data.DeviceID,
                DeviceName: data.DeviceName,
                Action: data.Action,
            });
        });

        finalrule["EmailRecipients"] = [];
        finalrule["SMSRecipients"] = [];

        notificationSetting?.forEach((data) => {
            data.EmailRecipients.forEach((dataone) => {
                finalrule["EmailRecipients"].push(dataone.EmailAddress);
            });
            let htmltext = data.EmailBody._cache.html;
            finalrule["EmailBody"] = converthtmlcontent(htmltext);
            data.SMSRecipients?.forEach((dataone) => {
                finalrule["SMSRecipients"].push(dataone.MobilePhone);
            });
            finalrule["SMSBody"] = data.SMSBody;
        });
    }, []);

    useEffect(() => {
        if (Number(permission.update) === 1) {
            setToggleconfirm(false);
        }
    }, [permission]);
    React.useEffect(() => {
        let url = "api/park/ruleengine/dropdownoptions";
        var restoken = GenerateEncryptedToken();
        smartParkService
            .get(url, {
                headers: {
                    "x-access-token": restoken,
                    userid: userid,
                },
            })
            .then((res) => {
                // setPriorityno(res.data.priority);
                const conditions = res?.data?.dropdown?.conditions;
                var dataa = [];
                var devices = {};
                conditions?.forEach((data) => {
                    dataa.push({
                        id: data.ID,
                        name: data.Name,
                        shortname: data.ShortName,
                        device: data.Devices,
                    });
                    devices[data.ID] = data.Devices;
                });

                setparks(dataa);
                setSearchfilter(devices);

                const actionsdata = res.data.dropdown.actions;
                var actiondatta = [];
                var actiondevices = {};
                actionsdata?.forEach((data, idx) => {
                    actiondatta.push({
                        id: data.ID,
                        name: data.Name,
                        shortname: data.ShortName,
                        device: data.Devices,
                    });
                    actiondevices[data.ID] = data.Devices;
                });

                setactionpark(actiondatta);
                setActionparkID(actiondevices);
            })
            .catch((error) => {
                console.error("FloodChart error: ", error);
            });
    }, []);

    const handleparkChange = (event) => {
        let storeParkname = [];
        setparkName(event.target.value);
        setparkDeviceType("");
        setparkDeviceId("");
        setParkDevicetype([]);
        setConditonparktype("");
        setTriggervalue("");
        setTriggerCriteria("");
        setTolerance("");
        let alldevices = getSearchfilter;
        const selectedParkName = event.target.value;
        if (selectedParkName !== "") {
            setParkNameError(false);
            storeParkname = alldevices[selectedParkName];
        }
        setParktype(
            Object.keys(storeParkname).filter(
                (category) => category === "Park" || storeParkname[category].length > 0
            )
        );
        // console.log(getparks);
        const selectedOption = getparks.find(
            (data) => data.id === selectedParkName
        );
        const getparkname = selectedOption ? selectedOption.name : "";
        setConditionParkName(getparkname);
    };

    const handleparkType = (event) => {
        setparkDeviceType(event.target.value);
        if (event.target.value !== "") {
            setParkDeviceTypeError(false);
        }
        setparkDeviceId("");
        setParkDevicetype([]);
        setConditonparktype("");
        setTriggervalue("");
        setTriggerCriteria("");
        setTolerance("");
        const seldevice = event.target.value;
        const parkid = getparkName;
        const park = getparks;
        var parkobj = [];
        park?.forEach((data, idx) => {
            if (data.id == parkid) {
                parkobj = data;
            }
        });
        var devices = parkobj["device"] ?? [];
        var devicetype = devices[seldevice] ?? [];
        if (!Array.isArray(devicetype)) {
            let devicetypeone = [devicetype];
            setParkDevicetype(devicetypeone);
        } else {
            setParkDevicetype(devicetype);
        }
    };
    const handleparkDevices = (event) => {
        // console.log("parkdevice 999999999999 ", event.target.value);
        setparkDeviceId(event.target.value);
        if (event.target.value !== "") {
            setParkDeviceIdError(false);
        }
        // console.log("parkdevice getParkDevicetype ", getParkDevicetype);
        const seldevice = event.target.value;
        const selectedOption = getParkDevicetype.find(
            (data) =>
                data.SensorID === seldevice ||
                data.FieldID === seldevice ||
                data.ID === seldevice
        );

        // console.log("selectedOption 8888888 ", selectedOption);
        const getparkname = selectedOption
            ? selectedOption.FieldName
                ? selectedOption.FieldName
                : selectedOption.Name
                    ? selectedOption.Name
                    : selectedOption.SensorName
                        ? selectedOption.SensorName
                        : ""
            : "";
        // console.log("selectedOption getparkname ******** ", getparkname);
        setConditonparktype(getparkname);
        setTolerance("");
        setTriggerCriteria("");
        setTriggervalue("");
    };

    useEffect(() => {
        // console.log(gethandleParkId);
        if (gethandleParkId !== "") {
            try {
                let url = `api/park/timezone/${gethandleParkId}`;
                var restoken = GenerateEncryptedToken();
                smartParkService
                    .get(url, {
                        headers: {
                            "x-access-token": restoken,
                        },
                    })
                    .then((res) => {
                        if (res.data.status == "200") {
                            try {
                                setTimeZoneValue(res.data.facilities.timezone);
                            } catch {
                                console.log("timezone facilities error");
                            }
                        }
                    });
            } catch {
                console.log("Timezone get error");
            }
        }
    }, [gethandleParkId]);
    // action dropdown============

    const handleParkId = (elem) => {
        sethandleParkId(elem.target.value);
        sethandleParkDeviceType("");
        sethandleParkDevice("");
        sethandleOpenClosePark("");
        let storeParkname = [];
        let alldevices = getActionparkID;
        const selectedParkName = elem.target.value;
        if (selectedParkName !== "") {
            setActionParkNameError(false);
            storeParkname = alldevices[selectedParkName];
        }
        setActionParktype(
            Object.keys(storeParkname).filter(
                (category) => category === "Park" || storeParkname[category].length > 0
            )
        );
        const selectedOption = getactionpark.find(
            (data) => data.id === selectedParkName
        );
        const getparkname = selectedOption ? selectedOption.name : "";
        setActionpartkname(getparkname);
    };
    const handledeviceType = (event) => {
        sethandleParkDeviceType(event.target.value);
        if (event.target.value !== "") {
            setActionDeviceTypeError(false);
        }
        sethandleParkDevice("");
        const seldevice = event.target.value;
        const actionparkid = gethandleParkId;
        const actionpark = getactionpark;
        var actionparkobj = [];
        actionpark?.forEach((data, idx) => {
            if (data.id == actionparkid) {
                actionparkobj = data;
            }
        });
        var actiondevices = actionparkobj["device"] ?? [];
        var actiondevicetype = actiondevices[seldevice] ?? [];
        if (!Array.isArray(actiondevicetype)) {
            let actiondevicetypeone = [actiondevicetype];
            setActionParkDevicetype(actiondevicetypeone);
        } else {
            setActionParkDevicetype(actiondevicetype);
        }
    };

    const handledevice = (elem) => {
        sethandleParkDevice(elem.target.value);
        if (elem.target.value !== "") {
            setActionDeviceError(false);
        }
        const seldevice = elem.target.value;
        const selectedOption = getActionParkDevicetype.find((data) => {
            if (data.FieldID === seldevice) {
                return data.FieldID;
            } else if (data.ID === seldevice) {
                return data.ID;
            } else {
                return "";
            }
        });
        const getparkname = selectedOption
            ? selectedOption.FieldName
                ? selectedOption.FieldName
                : selectedOption.Name
            : "";
        setActiondevicetypename(getparkname);
    };
    // action dropdown============
    // dropdown Api =====================
    const handleOnOffChange = (event) => {
        setStartDateError(false);
        setEndDateError(false);
        setOnOFFValue(event.target.checked);
        if (event.target.checked === true) {
            setStartDate("");
            setEndDate("");
        } else {
            setStartDate(new Date());
            setEndDate(new Date());
        }
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handleemailOnOffChange = (event) => {
        // console.log(event.target.checked);
        setEmailError(false);
        setEmailsStringList([]);
        setEmailsList([]);
        setEmailBody(RichTextEditor.createEmptyValue(""));
        setemailOnOFFValue(event.target.checked);
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handlesmsOnOffChange = (event) => {
        // console.log(event.target.checked);
        setSmsError(false);
        setMobileStringList([]);
        setMobileNumbersList([]);
        setSmsMessage("");
        setsmsOnOFFValue(event.target.checked);
        setOnOffState({ ...onoffstate, [event.target.name]: event.target.checked });
    };
    const handleChange = (panel) => (event, isExpanded) => {
        event.preventDefault();
        event.stopPropagation();
        setExpanded(isExpanded ? panel : false);
    };
    const [onoffstate, setOnOffState] = React.useState({
        checkedC: true,
    });
    const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
    const permissionsobj = useSelector(permissions_);

    const handleTriggerstrategyChange = (e) => {
        if (e.target.value !== "") {
            setTrigerstrategyError(false);
        }
        setTrigerstrategy(e.target.value);
        if (e.target.value === "TIME BASED RULE") {
            dispatch(todosAction.resetTrigerConditionState());
        }
    };
    // action setting fields -------------==================
    const handleTriggervalue = (event) => {
        // console.log(event.target.value);
        setTriggervalue(event.target.value);
        if (event.target.value !== "") {
            setTriggervalueError(false);
        }
    };
    const handleTriggerCriteria = (event) => {
        setTriggerCriteria(event.target.value);
        if (event.target.value !== "") {
            setTriggerCriteriaError(false);
        }
    };

    const handleOpenClosepark = (elem) => {
        sethandleOpenClosePark(elem.target.value);
        if (elem.target.value !== "") {
            setActionError(false);
        }
    };

    const summaryRef = useRef(null);

    const handleOnChangeSmsMessage = (elem) => {
        setSmsMessage(elem.target.value);
        if (elem.target.value !== "") {
            setSmsError(false);
        }
    };

    const prepareUserNameList = (value) => {
        if (value.length > 2) {
            smartParkService.get("api/UserName/" + value).then((res) => {
                if (res) {
                    setdropMobileNumbersList(res.data.data);
                    setSmsError(false);
                } else {
                }
            });
        } else {
            setdropMobileNumbersList([]);
        }
    };
    const prepareUserEmailList = (value) => {
        if (value.length > 2) {
            smartParkService
                .get("api/UserName/" + value.toLowerCase())
                .then((res) => {
                    if (res) {
                        setdropEmailsList(res.data.data);
                        setEmailError(false);
                    } else {
                    }
                });
        } else {
            setdropEmailsList([]);
        }
    };

    const onChange = (value) => {
        setEmailBody(value);
        setEmailError(false);
    };

    function randvalue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const randomNumber = randvalue(1, 100);
    // datainput ------=======================================================
    const [getBasicSetting, sendBasicSetting] = React.useState([]);

    const handleAdddata = (e, expandeddata, btnaction) => {
        validation(e);
        const data = [
            {
                RuleName: getRulename,
                Rule_Description: getonDescriptionMessage,
                priorityno: priorityno,
                RuleIsAlwaysOn: getOnOFFValue,
                RuleValidityStartDate: startDate,
                RuleValidityEndDate: endDate,
                OperationalTime: [
                    {
                        DayofWeek: getDayOfWeek,
                        StartTime: timevalue,
                        EndTime: endtimevalue,
                    },
                ],
                TriggerStrategy: getTrigerstrategy,
                Conditions: [
                    {
                        ParkID: getparkName,
                        ParkName: getConditionParkName,
                        Devicetype: getparkDeviceType,
                        DeviceID: getparkDeviceId,
                        DeviceName: getConditonparktype,
                        TriggerValue: getTriggervalue,
                        TriggerCriteria: getTriggerCriteria,
                        Tolerance: getTolerance,
                    },
                ],
                Action: [
                    {
                        ParkID: gethandleParkId,
                        ParkName: getActionpartkname,
                        Devicetype: gethandleParkDeviceType,
                        DeviceID: gethandleParkDevice,
                        DeviceName: getActiondevicetypename,
                        Action: gethandleOpenClosePark,
                    },
                ],
                sendSMSNotification: getsmsOnOFFValue,
                SMSRecipients: MobileNumbersList,
                SMSBody: getSmsMessage,
                sendEmailNotification: getemailOnOFFValue,
                EmailRecipients: EmailsList,
                EmailBody: emailBody,
            },
        ];
        sendBasicSetting(data);
        if (e === "1" && expandeddata) {
            if (
                getRulename !== "" &&
                getonDescriptionMessage !== "" &&
                priorityno !== ""
            ) {
                // setExpanded(expandeddata.replace(e,parseInt(e)+1));
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
            }
        }
        if (e === "2" && expandeddata) {
            if (
                getOnOFFValue === false &&
                startDate !== "" &&
                endDate !== "" &&
                startDate !== null &&
                endDate !== null
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
            } else if (getOnOFFValue === true) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
            }
        }
        if (e === "3" && expandeddata) {
            if (
                getDayOfWeek.length > 0 &&
                endtimevalue !== "" &&
                timevalue !== "" &&
                getStartTimeErrorText == "" &&
                getendTimeErrorText == ""
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
            }
        }
        if (e === "4" && expandeddata) {
            if (getTrigerstrategy !== "") {
                setShowCondition(getTrigerstrategy);
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
                if (getTrigerstrategy === "TIME BASED RULE") {
                    setExpanded(expandeddata.replace(e, parseInt(e) + 1));
                }
            }
        }
        if (e === "5" && expandeddata) {
            if (
                getparkName !== "" &&
                getTolerance !== "" &&
                getTriggerCriteria !== "" &&
                getConditonparktype !== "" &&
                getTriggervalue !== "" &&
                getparkDeviceId !== "" &&
                getparkDeviceType !== "" &&
                getConditionParkName !== "" &&
                getTrigerstrategy !== "" &&
                toleranceRangeError === false
            ) {
                if (getTrigerstrategy !== "TIME BASED RULE" && btnaction === "") {
                    setExpanded(expandeddata.replace(e - 1, parseInt(e)));
                }
                localStorage.setItem("addfields", e + "~" + randomNumber);
                settrigererror(true);
                setaddValue(expandeddata);
                // console.log("============>>>>>> ", e);
                if (btnaction && btnaction !== "") {
                    // console.log("============>>>>>> ", btnaction);
                    setConditionmore(btnaction);
                }
            }
        }
        if (e === "6" && expandeddata) {
            if (
                gethandleParkId !== "" &&
                getActionpartkname !== "" &&
                gethandleOpenClosePark !== "" &&
                getActiondevicetypename !== "" &&
                gethandleParkDevice !== "" &&
                gethandleParkDeviceType !== ""
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setaddValue(expandeddata);
                if (btnaction && btnaction !== "") {
                    setActionmore(btnaction);
                }
            }
        }
        if (e === "7") {
            localStorage.setItem("addfields", e + "~" + randomNumber);
            setaddValue(e);
        }
        if (e === "canceledit") {
            setUpdateActionID("");
            setUpdateConditionID("");
            setActionParktype([]);
            setActionParkDevicetype([]);
            sethandleParkDevice("");
            sethandleOpenClosePark("");
            setActiondevicetypename([]);
            sethandleParkDeviceType("");
            sethandleParkId("");
            setActionpartkname([]);
            setUpdateConditionID("");
            setParkDevicetype([]);
            setParktype([]);
            setparkName("");
            setConditionParkName([]);
            setparkDeviceId("");
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkDeviceType("");
            localStorage.setItem("addfields", e + "~" + randomNumber);
            setaddValue(e);
        }
        if (e === "sms") {
            if (
                smsError === false &&
                getSmsMessage !== "" &&
                MobileNumbersList.length > 0
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setGreenNotify(true);
                setaddValue(e);
            }
        }
        if (e === "email") {
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                emailError === false &&
                EmailsList?.length > 0 &&
                emailHtmlContent &&
                emailHtmlContent.trim() !== "" &&
                emailHtmlContent.trim() !== "<p><br></p>" &&
                !emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setGreenNotify(true);
                setaddValue(e);
            }
        }
        if (e === "smsemail" || e === "smsoremail") {
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                emailError === false &&
                smsError === false &&
                getSmsMessage !== "" &&
                MobileNumbersList.length > 0 &&
                EmailsList.length > 0 &&
                !emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                localStorage.setItem("addfields", e + "~" + randomNumber);
                setGreenNotify(true);
                setaddValue(e);
            }
        }

        if (e === "disablesmsemail") {
            localStorage.setItem("addfields", e + "~" + randomNumber);
            setGreenNotify(true);
            setaddValue(e);
        }
        if (e === "restore") {
            setStartTimeError(false);
            setStartTimeErrorText("");
            setEndTimeError(false);
            setEndTimeErrorText("");
            setUpdateActionID("");
            setUpdateConditionID("");
            setCleareditoverlay(true);
            setparkDeviceType("");
            setparkDeviceId("");
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkName("");
            sethandleParkId("");
            sethandleOpenClosePark("");
            setPersonName([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            RestoreAll();
        }
    };

    function UpdateRule(update) {
        setToggleconfirm(true);
        if (update === "updaterule") {
            setSubmitrule(update + "~" + randomNumber);
            setTimeout(() => {
                setToggleconfirm(false);
            }, 6000);
        }
    }

    React.useEffect(() => {
        if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
            getpermissions();
        }
    }, [permissionsHasLoaded]);
    const getpermissions = async () => {
        const permissionsobject = permissionsobj;
        var Dpermissions = permissionsobject.filter(function (e) {
            return e.feature == "RuleEngine";
        });
        setpermission(Dpermissions[0]);
    };

    // validation==========================

    function validation(eid) {
        if (eid === "1") {
            setPriorityError(false);
            setDescriptionError(false);
            setRuleNameError(false);
            if (getRulename === "" || getRulename.trim() === "") {
                setRuleNameError(true);
            }
            if (
                getonDescriptionMessage === "" ||
                getonDescriptionMessage.trim() === ""
            ) {
                setDescriptionError(true);
            }
            if (priorityno === "") {
                setPriorityError(true);
            }
            if (
                getRulename === "" ||
                getonDescriptionMessage === "" ||
                priorityno === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "2") {
            setStartDateError(false);
            setEndDateError(false);
            if ((getOnOFFValue === false && startDate === "") || startDate === null) {
                setStartDateError(true);
            }
            // Validate End Date (only if Off)
            if ((getOnOFFValue === false && endDate === "") || endDate === null) {
                setEndDateError(true);
            }
            // Check if any errors exist
            if (
                getOnOFFValue === false &&
                (startDate === "" || endDate === "") &&
                (startDate === null || endDate === null)
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "3") {
            // Validate Days of Week
            if (getDayOfWeek.length === 0) {
                setDayOfWeekError(true);
            }
            // Validate Start Time
            if (timevalue === "") {
                setStartTimeError(true);
                setStartTimeErrorText("Please Enter Start Time");
            }

            // Validate End Time
            if (endtimevalue === "") {
                setEndTimeError(true);
                setEndTimeErrorText("Please Enter End Time");
            }

            // Check if any errors exist
            if (
                getDayOfWeek.length === 0 ||
                timevalue === "" ||
                endtimevalue === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "4") {
            setTrigerstrategyError(false);
            setIsTriggerStrategySelected(false);
            if (getTrigerstrategy === "") {
                // Reset TriggerStrategy error when a new option is selected
                setTrigerstrategyError(true);
            }
        }
        if (eid === "5") {
            setTrigerstrategyError(false);
            setParkNameError(false);
            setParkDeviceTypeError(false);
            setParkDeviceIdError(false);
            setTriggervalueError(false);
            setTriggerCriteriaError(false);
            setToleranceError(false);
            // Validate TriggerStrategy
            if (!getTrigerstrategy) {
                setTrigerstrategyError(true);
            }
            // Validate other fields as needed...
            // Validate Park Name
            if (!getparkName) {
                setParkNameError(true);
            }
            // Validate Park Device Type
            if (!getparkDeviceType) {
                setParkDeviceTypeError(true);
            }
            // Validate Park Device Id
            if (!getparkDeviceId) {
                setParkDeviceIdError(true);
            }
            // Validate Triggervalue
            if (!getTriggervalue) {
                setTriggervalueError(true);
            }
            // Validate Trigger Criteria
            if (!getTriggerCriteria) {
                setTriggerCriteriaError(true);
            }
            // Validate Tolerance
            if (getTolerance === "") {
                setToleranceError(true);
            }
            // Check if any errors exist
            if (
                trigerstrategyError ||
                parkNameError ||
                parkDeviceTypeError ||
                parkDeviceIdError ||
                triggervalueError ||
                triggerCriteriaError ||
                toleranceError
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "6") {
            // Reset error flags
            setActionParkNameError(false);
            setActionDeviceTypeError(false);
            setActionDeviceError(false);
            setActionError(false);
            // Validate Park Name
            if (gethandleParkId === "") {
                setActionParkNameError(true);
            }
            // Validate Device Type
            if (gethandleParkDeviceType === "") {
                setActionDeviceTypeError(true);
            }
            // Validate Device
            if (gethandleParkDevice === "") {
                setActionDeviceError(true);
            }
            // Validate Action
            if (gethandleOpenClosePark === "") {
                setActionError(true);
            }
            // Check if any errors exist
            if (
                gethandleParkId === "" ||
                gethandleParkDeviceType === "" ||
                gethandleParkDevice === "" ||
                gethandleOpenClosePark === ""
            ) {
                return; // Do not proceed if there are validation errors
            }
        }
        if (eid === "email") {
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                EmailsList?.length === 0 ||
                !emailHtmlContent ||
                emailHtmlContent.trim() === "" ||
                emailHtmlContent.trim() === "<p><br></p>" ||
                emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                setEmailError(true);
                // console.log("Email body");
            } else {
                setEmailError(false);
                // console.log("Email body contains text.");
            }
        }
        if (eid === "sms") {
            setSmsError(false);
            if (MobileNumbersList?.length === 0 || getSmsMessage.trim() === "") {
                // SMS validation: Check if recipients and SMS message are filled
                setSmsError(true);
                return; // Do not proceed with the action
            }
        }

        if (eid === "smsoremail" || eid === "smsemail") {
            setSmsError(false);
            setEmailError(false);
            const emailHtmlContent = emailBody.toString("html");
            const emptyParagraphRegex = /^<p>(&nbsp;|\s)*<\/p>$/;
            if (
                EmailsList?.length === 0 ||
                !emailHtmlContent ||
                emailHtmlContent.trim() === "" ||
                emailHtmlContent.trim() === "<p><br></p>" ||
                emptyParagraphRegex.test(emailHtmlContent.trim())
            ) {
                setEmailError(true);
                // console.log("Email body");
            }
            if (MobileNumbersList?.length === 0 || getSmsMessage.trim() === "") {
                // SMS validation: Check if recipients and SMS message are filled
                setSmsError(true);
                return; // Do not proceed with the action
            }
        }
        if (eid === "sms" && eid === "email") {
            setSmsError(false);
            setEmailError(false);
            if (MobileNumbersList?.length === 0 || getSmsMessage.trim() === "") {
                // SMS validation: Check if recipients and SMS message are filled
                setSmsError(true);
                return; // Do not proceed with the action
            }
            if (EmailsList?.length === 0 || emailBody.trim() === "") {
                // Email validation: Check if recipients and email body are filled
                setEmailError(true);
                return; // Do not proceed with the action
            }
        }
    }

    /* editable action and condition   */
    const handleActionConditionfunc = (e, settingname) => {
        try {
            setUpdateActionID("");
            setUpdateConditionID("");
            let data = e[0];
            if (settingname === "action") {
                setActionParkNameError(false);
                setActionDeviceTypeError(false);
                setActionDeviceError(false);
                setActionError(false);
                setExpanded("panel5");
                setUpdateConditionID("");
                setparkName("");
                setparkDeviceId("");
                setparkDeviceType("");
                setTolerance("");
                setTriggerCriteria("");
                setTriggervalue("");
                // console.log(settingname);
                setUpdateActionID(data._id);
                sethandleParkId(data.ParkID);
                setActionpartkname(data.ParkName);
                let alldevices = getActionparkID;
                let storeParkname = {};
                if (data) {
                    storeParkname = alldevices[data.ParkID];
                }
                setActionParktype(
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    )
                );
                sethandleParkDeviceType(data.Devicetype);
                const seldevice = data.Devicetype;
                setActiondevicetypename(data.DeviceName);
                // console.log(storeParkname);
                const filterdata =
                    storeParkname &&
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    );
                let dataitem = [];
                filterdata &&
                    filterdata.forEach((item) => {
                        // console.log(item);
                        if (item === seldevice) {
                            if (Array.isArray(storeParkname[item])) {
                                dataitem = storeParkname[item];
                            } else {
                                dataitem = [storeParkname[item]];
                            }
                        }
                    });
                // console.log(dataitem);
                setActionParkDevicetype(dataitem);
                sethandleParkDevice(data.DeviceID);
                sethandleOpenClosePark(data.Action);
            }
            if (settingname === "condition") {
                setParkNameError(false);
                setTrigerstrategyError(false);
                setParkDeviceTypeError(false);
                setParkDeviceIdError(false);
                setTriggervalueError(false);
                setToleranceError(false);
                setTriggerCriteriaError(false);
                setToleranceRangeError(false);
                setExpanded("panel4");
                setUpdateActionID("");
                sethandleParkId("");
                sethandleParkDeviceType("");
                sethandleParkDevice("");
                sethandleOpenClosePark("");
                // console.log("condtions ////////// ", e);
                setUpdateConditionID(data._id);
                setparkName(data.ParkID);
                setConditionParkName(data.ParkName);
                let alldevices = getSearchfilter;
                let storeParkname = {};
                const selectedParkName = data.ParkID;
                if (selectedParkName !== "") {
                    setParkNameError(false);
                    storeParkname = alldevices[selectedParkName];
                }
                setParktype(
                    Object.keys(storeParkname).filter(
                        (category) =>
                            category === "Park" || storeParkname[category].length > 0
                    )
                );
                setparkDeviceType(data.Devicetype);
                const seldevice = data.Devicetype;
                const park = getparks;
                let parkobj = [];
                park?.forEach((item) => {
                    if (item.id === data.ParkID) {
                        // console.log(data.ParkID);
                        parkobj = item;
                    }
                });
                let devices = parkobj["device"] ?? [];
                let devicetype = devices[seldevice] ?? [];
                // console.log("devicetype ", devicetype);
                if (!Array.isArray(devicetype)) {
                    let devicetypeone = [devicetype];
                    setParkDevicetype(devicetypeone);
                } else {
                    setParkDevicetype(devicetype);
                }
                setparkDeviceId(data.DeviceID);
                setConditonparktype(data.DeviceName);
                setTriggervalue(data.TriggerValue);
                setTriggerCriteria(data.TriggerCriteria);
                setTolerance(data.Tolerance);
            }
        } catch (error) {
            console.error("handlefunc ===> ", error);
        }
    };

    const handleCancelfunc = () => {
        setUpdateActionID("");
        setUpdateConditionID("");
    };

    // validation==========================

    const deletethisfields = (e) => {
        if (e === "1") {
            setonDescriptionMessage("");
            setRuleName("");
        }
        if (e === "2") {
            setOnOFFValue(false);
            setStartDate(new Date());
            setEndDate(new Date());
        }
        if (e === "3") {
            setStartTimeError(false);
            setEndTimeError(false);
            setStartTimeErrorText("");
            setEndTimeErrorText("");
            setDayOfWeek([]);
            onTimeChange("");
            oneTimeendChange("");
        }
        if (e === "4") {
            setTrigerstrategy("");
            setShowCondition("");
            setparkDeviceType("");
            setparkDeviceId("");
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkName("");
            settrigererror(false);
        }
        if (e === "5") {
            // console.log("fdsfdsfs --------> ", e);
            sethandleParkId("");
            sethandleOpenClosePark("");
            setPersonName([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
        }
        if (e === "smsemailnotify") {
            setsmsOnOFFValue(false);
            setemailOnOFFValue(false);
            setMobileNumbersList([]);
            setSmsMessage("");
            setEmailsList([]);
            setEmailBody("");
            setGreenNotify(false);
        }
        if (e === "smsnotify") {
            setsmsOnOFFValue(false);
            setMobileNumbersList([]);
            setMobileStringList([]);
            setSmsMessage("");
        }
        if (e === "emailnotify") {
            setemailOnOFFValue(false);
            setEmailsList([]);
            setEmailsStringList([]);
            setEmailBody("");
        }
        if (e === "conditionmore") {
            setparkDeviceType("");
            setparkDeviceId("");
            setTriggervalue("");
            setTriggerCriteria("");
            setTolerance("");
            setparkName("");
            settrigererror(false);
            setUpdateConditionID("");
        }
        if (e === "actionremove") {
            // console.log("fdsfdsfs --------> ", e);
            sethandleParkId("");
            sethandleOpenClosePark("");
            setPersonName([]);
            sethandleParkDeviceType("");
            sethandleParkDevice("");
            setUpdateActionID("");
        }
        if (e === "clearoverlay") {
            setCleareditoverlay(false);
        }
    };

    const funcAllErrorSetting = (summarydataerror) => {
        // console.log(summarydataerror);
        setSummrydataError(false);
        setvaliditySettingError(false);
        setoperationalSettingError(false);
        setconditionTrigerSettingError(false);
        setactionSettingError(false);
        setblockDurationSettingError(false);
        if (
            summarydataerror.RuleName == "" &&
            summarydataerror.RuleDescription == ""
        ) {
            setSummrydataError(true);
        }
        if (
            summarydataerror.RuleIsAlwaysOn == false &&
            summarydataerror.RuleValidityEndDate == "" &&
            summarydataerror.RuleValidityStartDate == ""
        ) {
            setvaliditySettingError(true);
        }
        if (summarydataerror.OperationalTime?.length < 1) {
            setoperationalSettingError(true);
        }
        if (
            summarydataerror.Conditions?.length < 1 &&
            summarydataerror.TriggerStrategy !== "TIME BASED RULE"
        ) {
            setconditionTrigerSettingError(true);
        }
        // if (summarydataerror.Actions?.length < 1) {
        //   setactionSettingError(true);
        // }
        // if (summarydataerror.BlockDuration == "") {
        //   setblockDurationSettingError(true);
        // }
    };
    const rulepage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (permission.create == 1) {
            let path = "Rules";
            // setnewrule(true);
            history.push(path);
        } else {
            alert("You do not have permission to create rule");
        }
    };

    const handlePrintClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const printStyles = `
            <style>
            @media print {
                .samebg img {
                    display: none;
                }

                .deleteicon img {
                    display: none;
                }

                .printpageview {
                    flex-direction: column;
                }

                .printpageview .col-md-6 {
                    margin: 0 !important;
                }
                .indiviualdeleteone{
                    display:none;
                }
            }
            </style>`;
        const printContents = `
                <h5>Rule Details</h5>
                ${summaryRef.current.innerHTML} `;

        // Create a new window for printing
        const printWindow = window.open("", "_blank");

        // Write the print styles and contents to the new window
        printWindow.document.write(printStyles);
        printWindow.document.write(printContents);

        // Close the document writing to ensure everything is loaded
        printWindow.document.close();

        // Wait for the content to be loaded before triggering print
        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        };
    };
    // new rule create api =========================

    function funcRulename(e) {
        if (e.target.value !== "") {
            setRuleNameError(false);
        }
        setRuleName(e.target.value);
    }
    function funcRuleDes(e) {
        if (e.target.value !== "") {
            setDescriptionError(false);
        }
        setonDescriptionMessage(e.target.value);
    }
    function funcRulePriority(e) {
        if (e.target.value !== "") {
            setPriorityError(false);
        }
        setPriorityno(e.target.value.trim());
    }
    function funcTolerance(event) {
        if (event.target.value !== "") {
            setToleranceError(false);
        }
        if (event.target.value < 5 || event.target.value > 480) {
            setToleranceRangeError(true);
        } else {
            setToleranceRangeError(false);
        }
        setTolerance(event.target.value);
    }
    const funclistpage = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let path = "/Rules";
        history.push(path);
    };

    function timezoneformat(e) {
        var newDate = new Date(e);
        // Get current time components
        var currentHours = new Date().getHours();
        var currentMinutes = new Date().getMinutes();
        var currentSeconds = new Date().getSeconds();
        // Set the time of the new Date object to the current time
        newDate.setHours(currentHours, currentMinutes, currentSeconds);
        return newDate;
    }

    function funcStartdate(e) {
        if (e !== "" && e !== null) {
            setStartDateError(false);
            let time = timezoneformat(e);
            setStartDate(time);
            setEndDate(time);
        }
    }
    function funcEnddate(e) {
        if (e !== "" && e !== null) {
            setEndDateError(false);
            let time = timezoneformat(e);
            setEndDate(time);
            let time2 = timezoneformat(startDate);
            setStartDate(time2);
        }
    }
    function funcDayofweek(e) {
        setweekenddays(false);
        setalldays(false);
        setweekdays(false);
        if (e.target.value.length > 0) {
            setDayOfWeekError(false);
        }
        setDayOfWeek(e.target.value);
    }

    function convertTo24HourFormat(time12) {
        const [time, period] = time12.split(" ");
        let [hours, minutes] = time.split(":");
        if (period === "PM" && hours !== "12") {
            hours = String(Number(hours) + 12);
        } else if (period === "AM" && hours === "12") {
            hours = "00";
        }
        return `${hours}:${minutes}`;
    }

    function FuncTimeChange(e) {
        onTimeChange(e.target.value);
        const selectedTime = e.target.value;
        const currentTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        setStartTimeError(false);
        if (selectedTime === "") {
            setStartTimeError(true);
            setStartTimeErrorText("Please Enter Start Time");
        } else {
            const selectedTime24 = convertTo24HourFormat(selectedTime);
            const currentTime24 = convertTo24HourFormat(currentTime);
            const endTime24 = convertTo24HourFormat(endtimevalue);

            if (selectedTime24 > endTime24) {
                setStartTimeError(true);
                console.error("Start Time cannot be greator than End Time.");
                setStartTimeErrorText("Start Time cannot be greator than End Time.");
            } else if (selectedTime24 == endTime24) {
                setStartTimeError(true);
                setStartTimeErrorText("Start Time and End Time cannot be same.");
            } else {
                onTimeChange(selectedTime24);
                setStartTimeError(false);
                setStartTimeErrorText("");
                setEndTimeError(false);
                setEndTimeErrorText("");
                // Do something with valid start time
            }
        }
    }

    function FuncTimeendChange(e) {
        e.preventDefault();
        e.stopPropagation();
        oneTimeendChange(e.target.value);
        const selectedEndTime = e.target.value;
        const currentTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });

        setEndTimeError(false);

        if (selectedEndTime === "") {
            setEndTimeError(true);
            setEndTimeErrorText("Please Enter the End Time");
        } else {
            const selectedEndTime24 = convertTo24HourFormat(selectedEndTime);
            const currentTime24 = convertTo24HourFormat(currentTime);
            const startTime24 = convertTo24HourFormat(timevalue);
            if (selectedEndTime24 < startTime24) {
                setEndTimeError(true);
                console.error("End time cannot be before the start time.");
                setEndTimeErrorText("End time cannot be before the start time.");
            } else if (selectedEndTime24 == startTime24) {
                setEndTimeError(true);
                setEndTimeErrorText("End Time and Start Time cannot be same.");
            } else if (selectedEndTime24 > "23:59") {
                setEndTimeError(true);
                setEndTimeErrorText("End time cannot be after 11:59 PM.");
            } else {
                oneTimeendChange(selectedEndTime24);
                setEndTimeError(false);
                setEndTimeErrorText("");
                setStartTimeError(false);
                setStartTimeErrorText("");
                // Do something with valid end time
            }
        }
    }

    const selectAllDays = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setweekenddays(false);
        setweekdays(false);
        setalldays(true);
        setDayOfWeek(names);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };
    // Function to select weekdays (Monday to Friday)
    const selectWeekdays = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setweekenddays(false);
        setalldays(false);
        setweekdays(true);
        setDayOfWeek(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };
    // Function to select weekend days (Saturday and Sunday)
    const selectWeekendDays = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setStartTimeError(false);
        setEndTimeError(false);
        setStartTimeErrorText("");
        setEndTimeErrorText("");
        setDayOfWeekError(false);
        setalldays(false);
        setweekdays(false);
        setweekenddays(true);
        setDayOfWeek(["Saturday", "Sunday"]);
        onTimeChange("00:00");
        oneTimeendChange("23:59");
    };
    function funcBlankField() {
        setDayOfWeek([]);
        onTimeChange("");
        oneTimeendChange("");
    }

    const BasicTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Name :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Name" is where you give your rule a unique title, so that you
                    can easily identify it and keep your rules organized.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Description :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Description" is where you briefly explain what your rule does;
                    making it easier for you and others to understand the rule.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule Priority :-</strong>{" "}
                <span className="tooltiptext">
                    "Rule Priority" is a number that tells the system which rule should be
                    followed first when you have multiple rules impacting an action. A
                    higher number means higher the priority.
                </span>
            </div>
        </Tooltip>
    );
    const ValidityTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Rule always on :-</strong>{" "}
                <span className="tooltiptext">
                    The "Rule Always On" feature employs a toggle button. By default, it's
                    off, limiting the rule's validity to a specific date range. Users can
                    activate it to apply the rule to all dates without restrictions,
                    providing flexibility in rule execution based on their needs.
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Start and End Date :-</strong>{" "}
                <span className="tooltiptext">
                    Set a start date and an end date to define a specific period during
                    which the rule will be in effect. Once the end date is crossed, the
                    rule will no longer be active.
                </span>
            </div>
        </Tooltip>
    );
    const OperationalTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Days of Week :-</strong>{" "}
                <span className="tooltiptext">
                    Selecting one or more days on which you want rule to operate .
                </span>
            </div>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Select Start and End Time :-</strong>{" "}
                <span className="tooltiptext">
                    Define the time when the rule will take effect on the selected day,
                    specifying both the start and end times.
                </span>
            </div>
        </Tooltip>
    );
    const triggerTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Trigger Strategy :-</strong>{" "}
                <span className="tooltiptext">
                    The "Trigger Strategy" is used to specify rule trigger logic. Users
                    can choose from three options:
                    <ul>
                        <li>
                            <strong>All:</strong> The rule triggers only when all specified
                            conditions are met. All conditions must be true for the rule to
                            activate.
                        </li>
                        <li>
                            <strong>Any:</strong> The rule triggers if any of the specified
                            conditions is true.
                        </li>
                        <li>
                            <strong>Based On Time:</strong> The rule triggers solely based on
                            a set window of time. No other specific criteria is specified,
                            making it time-dependent.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const conditionTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Condition settings :-</strong>{" "}
                <span className="tooltiptext">
                    For setting rule conditions, there are several fields to set:
                    <ul>
                        <li>
                            <strong>Park Name :</strong> Select the park for the rule
                            condition.
                        </li>
                        <li>
                            <strong>Device Type : </strong> Select the park's device type.
                        </li>
                        <li>
                            <strong>Device : </strong> Select a specific device within the
                            selected type.
                        </li>
                        <li>
                            <strong>Trigger State/Value :</strong> It depends on the device
                            type. For gates and such, it's open or close state. For sensors,
                            it's a value (number) that you need to type in.
                        </li>
                        <li>
                            <strong>Trigger Criteria : </strong> Decide if it should be
                            ‘above’, ‘below’, or ‘exactly’ the same as the set trigger value
                            if numeric or ‘exactly’ if trigger state was like ‘close’ or
                            ‘open’.
                        </li>
                        <li>
                            <strong>Duration : </strong> Threshold condition has to be true
                            for the duration to trigger the rule action. The threshold
                            duration range is between 5 minutes to 480 minutes.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const ActionTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Action settings :-</strong>{" "}
                <span className="tooltiptext">
                    Configure actions using these fields:
                    <ul>
                        <li>
                            <strong>Park Name :</strong> Select the park where you want to
                            apply the action.
                        </li>
                        <li>
                            <strong>Device Type : </strong> Select the type of device in the
                            selected park, such as Park, Gates, or Fields.
                        </li>
                        <li>
                            <strong>Device : </strong> Pick a specific device within the
                            selected device type.
                        </li>
                        <li>
                            <strong>Action : </strong> Define the action to be performed for
                            the selected park, device type, and device. The action is usually
                            ‘open’ or ‘close’.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );
    const NotificationTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            <div className="tooltipcontent">
                <strong className="tooltiphead">Notification Settings :-</strong>{" "}
                <span className="tooltiptext">
                    Configure notifications based on rule triggers.
                    <ul>
                        <li>
                            <strong>Notification Toggle :</strong> Enable or disable
                            notifications using a toggle button, independently for Email and
                            SMS.
                        </li>
                        <li>
                            <strong>Email addresses :</strong> Specify email addresses to
                            notify to when the rule triggers.
                        </li>
                        <li>
                            <strong>Email Message :</strong> Type in the custom email message.
                        </li>
                        <li>
                            <strong>Phone Numbers for SMS Notification :</strong> Specify
                            phone numbers to notify to via SMS when the rule triggers.
                        </li>
                        <li>
                            <strong>SMS Message :</strong> Type in the custom SMS message.
                        </li>
                    </ul>
                </span>
            </div>
        </Tooltip>
    );

    return (
        <div className={classes.contentPage}>
            <section className="content-header mobileheader">
                <h3 className="editleft">
                    Edit Rule -{" "}
                    <span className="ruleidsize">{getRulename && getRulename}</span>
                </h3>
                <div className="editright">
                    <div className="reloadbtn">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#" onClick={rulepage}>
                                    Home
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#" className="active">
                                    editrule
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="content py-2 backlistbtn">
                <button
                    className="btn-color btn-sm btn badge-pill backicon "
                    onClick={funclistpage}
                >
                    <TiArrowBack />
                    <span className="ml-2">Back To List Page </span>
                </button>
            </section>
            <section className="content py-1 editrightpadd">
                <div className="content-middle rules-middle w-100 hidebackground">
                    <Paper className={`accoclass  ${classes.paper}`}>
                        <div className="form-body">
                            <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                    <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Basic Settings{" "}
                                                {getSummrydataError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${summrydata?.length > 0 ||
                                                                (getRulename !== "" &&
                                                                    getonDescriptionMessage !== "")
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={BasicTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Rule Name
                                                        </span>
                                                        <input
                                                            type="text"
                                                            min={"1"}
                                                            value={getRulename}
                                                            onChange={(e) => {
                                                                funcRulename(e);
                                                            }}
                                                            className="form-control"
                                                            required
                                                        />
                                                        {ruleNameError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter The Rule Name
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Rule Description
                                                        </span>
                                                        <textarea
                                                            className="fc-width100 p-2"
                                                            rows="3"
                                                            value={getonDescriptionMessage}
                                                            onChange={(e) => {
                                                                funcRuleDes(e);
                                                            }}
                                                            required
                                                        ></textarea>
                                                        {descriptionError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Rule Description
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Priority Number
                                                        </span>
                                                        <input
                                                            type="number"
                                                            min={"1"}
                                                            value={priorityno}
                                                            onChange={(e) => {
                                                                funcRulePriority(e);
                                                            }}
                                                            className="form-control"
                                                            required
                                                        />
                                                        {priorityError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Priority Number
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("1", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange("panel2")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Validity Settings{" "}
                                                {getvaliditySettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${validitySetting?.length > 0 ||
                                                                getOnOFFValue === true ||
                                                                (startDate !== "" &&
                                                                    endDate !== "" &&
                                                                    startDate !== null &&
                                                                    endDate !== null)
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={ValidityTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2} className="mobilegridalign">
                                                <Grid item xs={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        {" "}
                                                        Rule Always On{" "}
                                                    </span>{" "}
                                                    &nbsp; &nbsp;&nbsp;&nbsp;
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Grid
                                                        component="label"
                                                        container
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Grid item className="ruletime">
                                                            Off
                                                        </Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={getOnOFFValue}
                                                                value={getOnOFFValue}
                                                                onChange={handleOnOffChange}
                                                                name="checkedC"
                                                            />
                                                        </Grid>
                                                        <Grid item className="ruletime">
                                                            On
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {getOnOFFValue === false ? (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.startdatecss}>
                                                            <span
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                Select Start Date
                                                            </span>
                                                            <DatePicker
                                                                // showTimeSelect
                                                                // dateFormat="MMMM d, yyyy "
                                                                selected={startDate}
                                                                selectsStart
                                                                startDate={startDate}
                                                                minDate={new Date()}
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                                onChange={(date) => funcStartdate(date)}
                                                                className="form-control ruletime mobiledatepadd"
                                                                placeholderText="mm-day-yyyy"
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                        </div>
                                                        {startDateError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Start Date
                                                            </p>
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                                {getOnOFFValue === false ? (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className={classes.startdatecss}>
                                                            <span
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                Select End Date
                                                            </span>
                                                            <DatePicker
                                                                // showTimeSelect
                                                                // dateFormat="MMMM d, yyyy "
                                                                selected={endDate}
                                                                selectsEnd
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                                endDate={endDate}
                                                                minDate={
                                                                    startDate === "" || startDate === null
                                                                        ? new Date()
                                                                        : startDate
                                                                }
                                                                onChange={(date) => funcEnddate(date)}
                                                                className="form-control ruletime mobiledatepadd"
                                                                placeholderText="mm-day-yyyy"
                                                                customInput={<MaskedInput mask="11/11/1111" />}
                                                            />
                                                        </div>
                                                        {endDateError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter End Date
                                                            </p>
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}

                                                <Grid item xs={12}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("2", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange("panel3")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Operational Settings{" "}
                                                {getoperationalSettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${operationalSetting?.length > 0 ||
                                                                (getDayOfWeek.length > 0 &&
                                                                    endtimevalue !== "" &&
                                                                    timevalue !== "")
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={OperationalTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div className="weekday">
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime `}
                                                        >
                                                            Days Of Week :
                                                        </span>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectWeekdays}
                                                        >
                                                            <span>Select Weekdays Only</span>{" "}
                                                        </Link>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectWeekendDays}
                                                        >
                                                            <span>Select Weekend Only</span>
                                                        </Link>
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            className={myclass.dayscss}
                                                            onClick={selectAllDays}
                                                        >
                                                            <span>Select All Days</span>{" "}
                                                        </Link>
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    multiple
                                                                    value={getDayOfWeek}
                                                                    onChange={(e) => {
                                                                        funcDayofweek(e);
                                                                    }}
                                                                    input={<Input />}
                                                                    MenuProps={MenuProps}
                                                                    className={myclass.outlinedayweek}
                                                                >
                                                                    {names?.map((name, idx) => (
                                                                        <MenuItem key={idx} value={name}>
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        {dayOfWeekError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Select Days Of Week
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        Select Start Time
                                                    </span>
                                                    <input
                                                        type="time"
                                                        value={timevalue}
                                                        onChange={FuncTimeChange}
                                                        className="form-control timer"
                                                        aria-label="Select Start Time"
                                                    />
                                                    {startTimeError && (
                                                        <p style={{ color: "red" }}>
                                                            * {getStartTimeErrorText}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <span className={`ruletime  ${classes.rulelabel}`}>
                                                        Select End Time
                                                    </span>
                                                    <input
                                                        type="time"
                                                        value={endtimevalue}
                                                        onChange={FuncTimeendChange}
                                                        className="form-control timer"
                                                        aria-label="Select End Time"
                                                    />
                                                    {endTimeError && (
                                                        <p style={{ color: "red" }}>
                                                            * {getendTimeErrorText}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("3", expanded);
                                                        }}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel4"}
                                        onChange={handleChange("panel4")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Conditional Settings{" "}
                                                {getconditionTrigerSettingError ||
                                                    (getTrigerstrategy !== "TIME BASED RULE" &&
                                                        getconditionTrigerConditionSettingError) ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${conditionTrigerConditionSetting?.length > 0 ||
                                                                conditionTrigerSetting?.length > 0 ||
                                                                gettrigererror == true ||
                                                                getTrigerstrategy !== ""
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="flex-column cardbox mobilemultiselect">
                                            <Grid container spacing={2}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={triggerTooltip}
                                                    className="tooltipoverlay"
                                                >
                                                    <Button className="p-0 tooltipicon">
                                                        <HiMiniInformationCircle />
                                                    </Button>
                                                </OverlayTrigger>
                                                <Grid item xs={12} md={12}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={classes.formControl}
                                                    >
                                                        <div className="d-flex align-items-center ">
                                                            <span
                                                                id="demo-simple-select-outlined-label"
                                                                className={`ruletime  ${classes.rulelabel}`}
                                                            >
                                                                TriggerStrategy
                                                            </span>{" "}
                                                        </div>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={getTrigerstrategy}
                                                            onChange={(e) => {
                                                                handleTriggerstrategyChange(e);
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled>
                                                                Select the TriggerStrategy{" "}
                                                            </MenuItem>
                                                            <MenuItem value="ALL">All</MenuItem>
                                                            <MenuItem value="ANY">Any</MenuItem>
                                                            <MenuItem value="TIME BASED RULE">
                                                                Time Based Rule
                                                            </MenuItem>
                                                        </Select>
                                                        {trigerstrategyError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Select a Trigger Strategy
                                                            </p>
                                                        )}
                                                    </FormControl>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            className="ruleaddbtn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("4", expanded);
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    </Grid>
                                                    <hr />
                                                </Grid>
                                            </Grid>
                                            {getShowCondition &&
                                                getShowCondition !== "TIME BASED RULE" ? (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className="position-relative"
                                                >
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={conditionTooltip}
                                                        className="tooltipoverlay"
                                                    >
                                                        <Button className="p-0 tooltipicon conditiontool">
                                                            <HiMiniInformationCircle />
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <Grid item xs={12} md={6}>
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime  ${classes.rulelabel}`}
                                                        >
                                                            Park Name
                                                        </span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="parkiddemo"
                                                                    value={getparkName}
                                                                    onChange={handleparkChange}
                                                                    className={myclass.samepadd}
                                                                >
                                                                    {getparks?.map((data, idx) => (
                                                                        <MenuItem
                                                                            key={idx}
                                                                            value={data.id}
                                                                            style={getStyles(
                                                                                data.name,
                                                                                personName,
                                                                                theme
                                                                            )}
                                                                        >
                                                                            {data.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {parkNameError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select a Park Name
                                                                    </p>
                                                                )}
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <span
                                                            id="demo-simple-select-outlined-label"
                                                            className={`ruletime  ${classes.rulelabel}`}
                                                        >
                                                            Select the Device Types
                                                        </span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className={myclass.weekdayborder}>
                                                            <FormControl
                                                                variant="outlined"
                                                                style={{ width: "100%" }}
                                                                className={classes.formControl}
                                                            >
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="parkDevicetypedemo"
                                                                    value={getparkDeviceType}
                                                                    onChange={(e) => {
                                                                        handleparkType(e);
                                                                    }}
                                                                    className={myclass.samepadd}
                                                                >
                                                                    {getParktype?.map((name, idx) => (
                                                                        <MenuItem
                                                                            key={idx}
                                                                            value={name}
                                                                            style={getStyles(name, personName, theme)}
                                                                        >
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {parkDeviceTypeError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select a Device Type
                                                                    </p>
                                                                )}
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    {getparkDeviceType !== "Moisture" &&
                                                        getparkDeviceType !== "Rain" &&
                                                        getparkDeviceType !== "Flood" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={6}>
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Select the Device
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className={myclass.weekdayborder}>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        style={{ width: "100%" }}
                                                                        className={classes.formControl}
                                                                    >
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="parkDevicetypedemo"
                                                                            value={getparkDeviceId}
                                                                            onChange={(e) => {
                                                                                handleparkDevices(e);
                                                                            }}
                                                                            className={myclass.samepadd}
                                                                        >
                                                                            {getParkDevicetype?.map((data, idx) => (
                                                                                <MenuItem
                                                                                    key={idx}
                                                                                    value={
                                                                                        data.FieldID
                                                                                            ? data.FieldID
                                                                                            : data.ID
                                                                                    }
                                                                                    style={getStyles(
                                                                                        data.FieldName,
                                                                                        personName,
                                                                                        theme
                                                                                    )}
                                                                                >
                                                                                    {data.FieldName
                                                                                        ? data.FieldName
                                                                                        : data.Name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {parkDeviceIdError && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Select a Device
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Criteria
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggerCriteria}
                                                                        onChange={(e) => {
                                                                            handleTriggerCriteria(e);
                                                                        }}
                                                                        label="TriggerCriteria"
                                                                    >
                                                                        <MenuItem value="">
                                                                            Select the Condition{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="exactly">Exactly</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggerCriteriaError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select the Trigger Criteria
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger State
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggervalue}
                                                                        onChange={(e) => {
                                                                            handleTriggervalue(e);
                                                                        }}
                                                                        label="TriggerValue"
                                                                    >
                                                                        <MenuItem value="Open">
                                                                            {getparkDeviceType.toLowerCase() !==
                                                                                "court"
                                                                                ? "Opened"
                                                                                : "Unoccupied"}
                                                                        </MenuItem>
                                                                        <MenuItem value="Close">
                                                                            {getparkDeviceType.toLowerCase() !==
                                                                                "court"
                                                                                ? "Closed"
                                                                                : "Occupied"}
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggervalueError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select Trigger State
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {getparkDeviceType === "Moisture" ||
                                                        getparkDeviceType === "Rain" ||
                                                        getparkDeviceType === "Flood" ? (
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={6}>
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Select the Device
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className={myclass.weekdayborder}>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        style={{ width: "100%" }}
                                                                        className={classes.formControl}
                                                                    >
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="parkDevicetypedemo"
                                                                            value={getparkDeviceId}
                                                                            className={myclass.samepadd}
                                                                            onChange={(e) => {
                                                                                handleparkDevices(e);
                                                                            }}
                                                                        >
                                                                            {getParkDevicetype?.map((data, idx) => (
                                                                                <MenuItem
                                                                                    key={idx}
                                                                                    value={data.SensorID}
                                                                                    style={getStyles(
                                                                                        data.SensorName,
                                                                                        personName,
                                                                                        theme
                                                                                    )}
                                                                                >
                                                                                    {data.SensorName}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {parkDeviceIdError && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Select a Device
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Criteria
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={getTriggerCriteria}
                                                                        onChange={(e) => {
                                                                            handleTriggerCriteria(e);
                                                                        }}
                                                                        label="TriggerCriteria"
                                                                    >
                                                                        <MenuItem value="">
                                                                            Select the Condition{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="below">Below</MenuItem>
                                                                        <MenuItem value="above">Above</MenuItem>
                                                                        <MenuItem value="exactly">Exactly</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {triggerCriteriaError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Select the Trigger Criteria
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    style={{ width: "100%" }}
                                                                    className={classes.formControl}
                                                                >
                                                                    <div className="d-flex align-items-center ">
                                                                        <span
                                                                            id="demo-simple-select-outlined-label"
                                                                            className={`ruletime  ${classes.rulelabel}`}
                                                                        >
                                                                            Trigger Value
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type="number"
                                                                        min={"1"}
                                                                        value={getTriggervalue}
                                                                        onChange={(e) => {
                                                                            handleTriggervalue(e);
                                                                        }}
                                                                        className="form-control"
                                                                    />
                                                                </FormControl>
                                                                {triggervalueError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please Enter the Trigger Value
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <Grid item xs={12} md={6}>
                                                        <FormControl
                                                            variant="outlined"
                                                            style={{ width: "100%" }}
                                                            className={classes.formControl}
                                                        >
                                                            <div className="d-flex align-items-center ">
                                                                <span
                                                                    id="demo-simple-select-outlined-label"
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Duration (<strong> in minutes </strong> )
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                min={"1"}
                                                                value={getTolerance}
                                                                onChange={(e) => {
                                                                    funcTolerance(e);
                                                                }}
                                                                className="form-control"
                                                            />
                                                        </FormControl>
                                                        {toleranceError && (
                                                            <p style={{ color: "red" }}>
                                                                * Please Enter Duration Time
                                                            </p>
                                                        )}
                                                        {toleranceRangeError && (
                                                            <p style={{ color: "red" }}>
                                                                * The threshold duration range is between 5
                                                                minutes to 480 minutes.
                                                            </p>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Button
                                                            className="ruleaddbtn"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("5", expanded);
                                                            }}
                                                        >
                                                            {getUpdateConditionID !== "" ? "Update" : "ADD"}
                                                        </Button>
                                                        {getUpdateConditionID !== "" ? (
                                                            <Button
                                                                className="ruleaddbtncancel ml-2"
                                                                variant="contained"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    handleAdddata("canceledit");
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className="text-right">
                                                        <Button
                                                            className="ruleaddbtncancel"
                                                            variant="contained"
                                                            disabled={getUpdateConditionID}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("5", expanded, "conditionmore");
                                                            }}
                                                        >
                                                            MORE CONDITION
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel5"}
                                        onChange={handleChange("panel5")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel5bh-content"
                                            id="panel5bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Action Settings{" "}
                                                {getactionSettingError ? (
                                                    <span className="headerror"> * </span>
                                                ) : (
                                                    <span
                                                        className={`${actionSetting?.length > 0 ||
                                                                (gethandleParkId !== "" &&
                                                                    getActionpartkname !== "" &&
                                                                    gethandleOpenClosePark !== "" &&
                                                                    getActiondevicetypename !== "" &&
                                                                    gethandleParkDevice !== "" &&
                                                                    gethandleParkDeviceType !== "")
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox mobilemultiselect">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={ActionTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Park Name
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkId}
                                                            onChange={(e) => {
                                                                handleParkId(e);
                                                            }}
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getactionpark?.map((data, idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={data.id}
                                                                    park-name={data.name}
                                                                    style={getStyles(
                                                                        data.name,
                                                                        personName,
                                                                        theme
                                                                    )}
                                                                >
                                                                    {data.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActionparkNameError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Park Name
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Device Type
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkDeviceType}
                                                            onChange={(e) => {
                                                                handledeviceType(e);
                                                            }}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getActionParktype?.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    style={getStyles(name, personName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActiondeviceTypeError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Device Type
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Device
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldName"
                                                            label="Field Name"
                                                            variant="outlined"
                                                            value={gethandleParkDevice}
                                                            onChange={(e) => {
                                                                handledevice(e);
                                                            }}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Name" }}
                                                            className="ruletime"
                                                        >
                                                            {getActionParkDevicetype?.map((data, idx) => (
                                                                <MenuItem
                                                                    key={idx}
                                                                    value={data.FieldID ? data.FieldID : data.ID}
                                                                    style={getStyles(
                                                                        data.name,
                                                                        personName,
                                                                        theme
                                                                    )}
                                                                >
                                                                    {data.FieldName ? data.FieldName : data.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {ActiondeviceError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Device{" "}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        className={`m-0  ${classes.formControl}`}
                                                    >
                                                        <span className={`ruletime  ${classes.rulelabel}`}>
                                                            Action
                                                        </span>
                                                        <Select
                                                            size="small"
                                                            name="fieldStatus"
                                                            variant="outlined"
                                                            value={gethandleOpenClosePark}
                                                            onChange={(e) => {
                                                                handleOpenClosepark(e);
                                                            }}
                                                            label="Field Status"
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Field Status" }}
                                                            className="ruletime"
                                                        >
                                                            <MenuItem value="Close">Close </MenuItem>
                                                            <MenuItem value="Open">Open</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {actionError && (
                                                        <p style={{ color: "red" }}>
                                                            * Please Select Action
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Button
                                                        className="ruleaddbtn"
                                                        variant="contained"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("6", expanded);
                                                        }}
                                                    >
                                                        {getUpdateActionID !== "" ? "Update" : "ADD"}
                                                    </Button>
                                                    {getUpdateActionID !== "" ? (
                                                        <Button
                                                            className="ruleaddbtncancel ml-2"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("canceledit");
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={6} className="text-right">
                                                    <Button
                                                        className="ruleaddbtncancel "
                                                        variant="contained"
                                                        disabled={getUpdateActionID}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleAdddata("6", expanded, "actionmore");
                                                        }}
                                                    >
                                                        MORE ACTION
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel6"}
                                        onChange={handleChange("panel6")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel6bh-content"
                                            id="panel6bh-header"
                                        >
                                            <Typography className={`SameClass ${classes.heading}`}>
                                                Notification Settings{" "}
                                                {getsmsnotificationSettingError &&
                                                    getemailnotificationSettingError ? (
                                                    <span className="headerror">*</span>
                                                ) : (
                                                    <span
                                                        className={`${getGreenNotify == true ||
                                                                MobileStringList.length > 0 ||
                                                                EmailsStringList.length > 0
                                                                ? "headgreen"
                                                                : "headrequired"
                                                            }`}
                                                    >
                                                        *
                                                    </span>
                                                )}{" "}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cardbox">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={NotificationTooltip}
                                                className="tooltipoverlay"
                                            >
                                                <Button className="p-0 tooltipicon">
                                                    <HiMiniInformationCircle />
                                                </Button>
                                            </OverlayTrigger>
                                            <Grid container spacing={2} className="mt-2">
                                                <Grid item xs={12} md={12}>
                                                    <div className="stepper-row-content1">
                                                        <div className="Notificationonoff d-flex justify-content-between">
                                                            <div
                                                                className={`ruletime notifyleft  ${classes.rulelabel}`}
                                                            >
                                                                Email Notification
                                                            </div>
                                                            <div className="notifyright">
                                                                <AntSwitch
                                                                    checked={getemailOnOFFValue}
                                                                    value={getemailOnOFFValue}
                                                                    onChange={handleemailOnOffChange}
                                                                    name="checkedC"
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        {getemailOnOFFValue === true ? (
                                                            <div>
                                                                <label
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Notify to following users email
                                                                </label>
                                                                <div className="customInputBase w-100">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        aria-label="menu"
                                                                    >
                                                                        <Search />
                                                                    </IconButton>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="EmailsList"
                                                                        label="Select Emails"
                                                                        className="notifyemailsmsbox"
                                                                        options={dropEmailsList?.map(
                                                                            (option) =>
                                                                                option.FirstName +
                                                                                "  (" +
                                                                                option.EmailAddress +
                                                                                ")"
                                                                        )}
                                                                        freeSolo
                                                                        defaultValue={EmailsStringList.map(
                                                                            (option) => option
                                                                        )}
                                                                        renderTags={(value, getTagProps) =>
                                                                            value?.map((option, index) => {
                                                                                const emailRegex =
                                                                                    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
                                                                                const matches =
                                                                                    option.match(emailRegex);
                                                                                const email = matches ? matches[0] : "";
                                                                                return (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        label={email}
                                                                                        {...getTagProps({ index })}
                                                                                    />
                                                                                );
                                                                            })
                                                                        }
                                                                        onChange={(event, newValue) => {
                                                                            // console.log(newValue);
                                                                            setEmailsList(newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    prepareUserEmailList(e.target.value);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                {getRuleError === true &&
                                                                    EmailsList.length <= 0 && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please Fill Email
                                                                        </p>
                                                                    )}
                                                                <br />
                                                                <div>
                                                                    <label
                                                                        className={`ruletime  ${classes.rulelabel}`}
                                                                    >
                                                                        Email message{" "}
                                                                    </label>
                                                                    <RichTextEditor
                                                                        value={emailBody || ""}
                                                                        onChange={onChange}
                                                                    />
                                                                    <br />
                                                                </div>
                                                                {emailError && (
                                                                    <p style={{ color: "red" }}>
                                                                        * Please fill in recipients and email
                                                                        message
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="stepper-row-content1">
                                                        <div className="Notificationonoff d-flex justify-content-between">
                                                            <div
                                                                className={`ruletime  notifyleft ${classes.rulelabel}`}
                                                            >
                                                                SMS Notification
                                                            </div>
                                                            <div className="notifyright">
                                                                <AntSwitch
                                                                    checked={getsmsOnOFFValue}
                                                                    value={getsmsOnOFFValue}
                                                                    onChange={handlesmsOnOffChange}
                                                                    name="checkedC"
                                                                />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        {getsmsOnOFFValue === true ? (
                                                            <div>
                                                                <label
                                                                    className={`ruletime  ${classes.rulelabel}`}
                                                                >
                                                                    Notify to following users
                                                                </label>
                                                                <div className="customInputBase w-100">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        aria-label="menu"
                                                                    >
                                                                        <Search />
                                                                    </IconButton>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="MobileNumbersList"
                                                                        className="notifyemailsmsbox"
                                                                        options={dropMobileNumbersList?.map(
                                                                            (option) =>
                                                                                option.FirstName +
                                                                                "  (" +
                                                                                option.MobilePhone +
                                                                                ")"
                                                                        )}
                                                                        freeSolo
                                                                        defaultValue={
                                                                            MobileStringList &&
                                                                            MobileStringList.map((option) => option)
                                                                        }
                                                                        renderTags={(value, getTagProps) =>
                                                                            value?.map((option, index) => {
                                                                                const phoneRegex =
                                                                                    /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/;
                                                                                const matches =
                                                                                    option.match(phoneRegex);
                                                                                const phoneNumber = matches
                                                                                    ? `${matches[1]}-${matches[2]}-${matches[3]}`
                                                                                    : "";
                                                                                return (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        label={phoneNumber}
                                                                                        {...getTagProps({ index })}
                                                                                    />
                                                                                );
                                                                            })
                                                                        }
                                                                        onChange={(event, newValue) => {
                                                                            setMobileNumbersList(newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    prepareUserNameList(e.target.value);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <label
                                                                        className={`ruletime  ${classes.rulelabel}`}
                                                                    >
                                                                        SMS message{" "}
                                                                    </label>
                                                                    <textarea
                                                                        className="fc-width100"
                                                                        rows="3"
                                                                        value={getSmsMessage}
                                                                        onChange={(e) => {
                                                                            handleOnChangeSmsMessage(e);
                                                                        }}
                                                                    ></textarea>
                                                                    {smsError && (
                                                                        <p style={{ color: "red" }}>
                                                                            * Please fill in recipients and SMS
                                                                            message
                                                                        </p>
                                                                    )}
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {getemailOnOFFValue === true &&
                                                        getsmsOnOFFValue === false ? (
                                                        <Button
                                                            className="ruleaddbtn emailtext"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("email");
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : getsmsOnOFFValue === true &&
                                                        getemailOnOFFValue === false ? (
                                                        <Button
                                                            className="ruleaddbtn smstext"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("sms");
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : getsmsOnOFFValue === true &&
                                                        getemailOnOFFValue === true &&
                                                        getSmsMessage !== "" &&
                                                        MobileNumbersList.length > 0 &&
                                                        emailBody !== "" &&
                                                        EmailsList.length > 0 ? (
                                                        <Button
                                                            className="ruleaddbtn smsemailtext"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("smsemail");
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : getsmsOnOFFValue === true &&
                                                        getemailOnOFFValue === true ? (
                                                        <Button
                                                            className="ruleaddbtn smsoremailtext"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("smsoremail");
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="ruleaddbtn disabletext"
                                                            variant="contained"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleAdddata("disablesmsemail");
                                                            }}
                                                        >
                                                            ADD
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className="borderleftsummary"
                                    style={{ padding: "0px" }}
                                >
                                    <Grid item xs={12} md={12} className="addruleItems1">
                                        <Paper
                                            className={classes.paper}
                                            style={{
                                                boxShadow: "none",
                                                padding: "12px 2px 10px 0px",
                                            }}
                                        >
                                            <div className="d-flex align-items-center justify-content-between mobilesummarypadd">
                                                <h6 className="summaryicon">Rule Details</h6>
                                                <button
                                                    className="btn printbtn p-0 printbtn"
                                                    onClick={handlePrintClick}
                                                >
                                                    <img
                                                        src={printer}
                                                        alt="printerimg"
                                                        className="img-fluid"
                                                    />
                                                </button>
                                            </div>
                                            <div ref={summaryRef}>
                                                <Suspense
                                                    fallback={
                                                        <ClipLoader
                                                            color={color}
                                                            loading={loading}
                                                            css={override}
                                                            size={60}
                                                        />
                                                    }
                                                >
                                                    <EditNewSummary
                                                        summary={geteditdata}
                                                        ruleid={getEditID}
                                                        setExpanded={setExpanded}
                                                        addvalue={getaddValue}
                                                        editalldata={alldata}
                                                        updateRule={getSubmitrule}
                                                        Cleareditoverlay={getCleareditoverlay}
                                                        allFields={deletethisfields}
                                                        handlevisible={gethandlevisible}
                                                        blankOperationsetting={funcBlankField}
                                                        AllErrorSetting={funcAllErrorSetting}
                                                        handleActionCondition={handleActionConditionfunc}
                                                        handleCancel={handleCancelfunc}
                                                        updatedID={getUpdateActionID}
                                                        updatedConditionID={getUpdateConditionID}
                                                        addmore={getActionmore}
                                                        addconditionmore={getConditionmore}
                                                        setActionmore={setActionmore}
                                                        setConditionmore={setConditionmore}
                                                        Randomval={getRandomVal && getRandomVal}
                                                    />
                                                </Suspense>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        className="text-center btnpaddmobile"
                                    >
                                        <div className="backgroundbtn">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    UpdateRule("updaterule");
                                                }}
                                                className="savebtn"
                                                disabled={getToggleconfirm}
                                            >
                                                Update Rule
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    handleAdddata("restore");
                                                }}
                                                className="deletebtn"
                                            >
                                                Restore Rule
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </div>
            </section>
        </div>
    );
}
