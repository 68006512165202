import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { formatDisplayDate } from "../../utils/IsoDateConverter";

const useStyles = makeStyles((theme) => ({
  contentPage: {
    flexGrow: 1,
    marginTop: 65,
    minHeight: " calc(100vh - 100px )",
  },
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  export: {
    display: "flex",
    justifyContent: "end",
    // float: "right",
  },
  button: {
    marginLeft: "auto",
  },
  chartContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
  },
  tableContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
    borderCollapse: "collapse",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  header: {
    border: "1px solid #000",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  cell: {
    border: "1px solid #000",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    textAlign: "left",
  },
  rowEven: {
    backgroundColor: theme.palette.grey[200],
  },
  rowOdd: {
    backgroundColor: theme.palette.common.white,
  },
}));
const ChartTable = ({ data, sensors }) => {
  const classes = useStyles();
  return (
    <div>
      {data.Sensors.map((sensor) => {
        return (
          <div className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header}>Date</TableCell>
                    <TableCell className={classes.header}>
                      Occupancy-{sensors[sensor.SensorID]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sensor.Data.map((row, index) => {
                    if (row.value > 0) {
                      return (
                        <TableRow
                          key={index}
                          className={
                            index % 2 === 0 ? classes.rowEven : classes.rowOdd
                          }
                        >
                          <TableCell className={classes.cell}>
                            {formatDisplayDate(row.date)}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {row.value}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
};

export default ChartTable;
